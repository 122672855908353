const shared = {
  restEndpoint: process.env.REST_ENDPOINT,
  buildTime: process.env.BUILD_TIME || 1,
  debugMode: process.env.DEBUG_MODE === 'true',
  isStage: process.env.IS_STAGE === 'true',
  prodUrl: process.env.PROD_URL,
  sentryDsn: process.env.SENTRY_DSN,
  adal: {
    tenant: 'common',
    clientId: process.env.CLIENT_ID,
    clientIdNative: process.env.CLIENT_ID_NATIVE,
    extraQueryParameter: 'nux=1',
    redirectUri: process.env.REDIRECT_URI,
    disableRenewal: false,
    endpoints: {
      'https://outlook.office.com': 'https://outlook.office.com'
    },
    authorityHost: 'https://login.microsoftonline.com/common/oauth2/authorize',
    cacheLocation: 'localStorage',
  }
};

const config = {
  development: {
    ...shared,
    isDev: process.env.IS_DEV === 'true',
    sentryEnvironment: 'dev',
  },
  test: {
    ...shared,
    isDev: false,
    sentryEnvironment: 'test',
  },
  production: {
    ...shared,
    isDev: false,
    sentryEnvironment: shared.isStage ? 'stage' : 'prod',
  }
};

export default config[process.env.NODE_ENV || 'development'];
