import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import { Q } from '@nozbe/watermelondb';
import { applicationStore, countryStore, cmtLicenseForm, routing } from 'shared/stores';
import database from 'shared/stores/database/database';
import { simappConstants } from 'shared/lib/theme';
import { instruments, modules, licenseTypes, licenseDuration } from 'shared/stores/forms/cmt-license-form';
import SelectField from 'web/components/_ui/form-elements/select-field';
import InputDateTime from 'web/components/_ui/form-elements/input-date-time';
import SaveButton from 'web/components/_ui/form-elements/save-button';
import InputField from 'web/components/_ui/form-elements/input-field';
import SwitchField from 'web/components/_ui/form-elements/switch-field';
import MultiRadioField from 'web/components/_ui/form-elements/multi-radio-field';
import FileField from 'web/components/_ui/form-elements/file-field';
import LicenseInstrumentsField from './license-instruments-field';

const styles = theme => ({
  root: {
    height: '100vH',
    width: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: simappConstants.contentMarginLarge
  },
  innerRoot: {
    maxWidth: 1200,
    margin: 'auto',
  },
  title: {
    marginBottom: theme.spacing.unit * 3,
    paddingLeft: 8,
  },
  subtitle: {
    padding: 8,
  },
  username: {
    marginTop: theme.spacing.unit * -2,
    marginBottom: theme.spacing.unit,
    paddingLeft: 8,
  },
  gridContainer: {
    alignItems: 'stretch'
  },
  gridItem: {
    display: 'flex'
  },
  paper: {
    width: '100%'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.5em',
  },
  labelWrapper: {
    display: 'flex',
    position: 'relative',
    height: 32,
    width: '25%',
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '32px'
  },
  buttonArea: {
    padding: '12px 24px'
  },
  cancelButtonWrapper: {
    flex: 1,
    '& button': {
      marginLeft: 8,
      marginRight: 8
    }
  },
  resultWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: '1rem'
  },
  password: {
    paddingBottom: '1rem',
    fontSize: '1.5rem',
  },
});

const CreateLicense = observer(({ classes, match }) => {
  const { fields, onFieldChange } = cmtLicenseForm;

  const [page, setPage] = useState(1);
  const [products, setProducts] = useState([]);

  const goToList = () => {
    routing.goBack();
  };

  const goToPage1 = () => {
    setPage(1);
  };

  const goToPage2 = () => {
    if (cmtLicenseForm.validatePage1()) {
      setPage(2);
    }
  };

  const create = async () => {
    if (await cmtLicenseForm.create()) {
      routing.push('/cmt/licenselist');
    }
  };

  useEffect(() => {
    const setProductsForUser = (kryptor, fastscreen) => {
      setProducts({
        kryptor: { name: 'Kryptor SW v11 / Chat LIS', disabled: !kryptor },
        fastscreenplus: { name: 'Fast Screen PLUS', disabled: !fastscreen },
      });
    };
    const setDevModeAllowed = (groupIds) => {
      database.collections.get('userGroup').query(Q.where('b_id', Q.oneOf(groupIds)), Q.where('module', 'cmt'), Q.where('is_deactivated', false)).fetch().then((userGroups) => {
        if (!userGroups.length) {
          console.error('No CMT groups are available!');
        } else {
          // if an admin is in more than one maingroup, we check all groups for the available levels
          cmtLicenseForm.devModeAllowed = userGroups.some(uG => uG.permissionList.includes('cmt_create_dev_mode'));
        }
      });
    };
    cmtLicenseForm.setDefaultValues();
    if (match.params.userId) {
      database.collections.get('cmtUser').query(Q.where('uid', match.params.userId)).fetch().then((users) => {
        if (!users.length || users[0].unused) {
          routing.push('/cmt/license');
        } else {
          cmtLicenseForm.fields.user_id.value = users[0].bId;
          cmtLicenseForm.username = users[0].name;
          setDevModeAllowed(users[0].groupIds);
          setProductsForUser(users[0].cmtTrainedKryptor, users[0].cmtTrainedFastscreen);
        }
      });
    } else {
      // do it for current user
      setDevModeAllowed(applicationStore.appUser.get('group_ids'));
      setProductsForUser(applicationStore.appUser.get('cmt_trained_kryptor'), applicationStore.appUser.get('cmt_trained_fastscreen'));
    }
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.innerRoot}>
        <Typography variant="h5" className={classes.title}>License Request</Typography>
        { cmtLicenseForm.username && (
          <Typography className={classes.username}>New license for user: {cmtLicenseForm.username} </Typography>
        )}
        { page === 1 && (
          <Paper className={classes.paper}>
            <Typography variant="h5" className={classes.subtitle}>Installation Information</Typography>
            <InputField fields={fields} name="institution" onChange={onFieldChange} />
            <Divider light />
            <InputField fields={fields} name="city" onChange={onFieldChange} />
            <Divider light />
            <SelectField
              fields={fields}
              name="country_id"
              list={countryStore.getCountriesForSelect()}
              currentValue={countryStore.getCountryName(fields.country_id.value)}
              onChange={(id) => { onFieldChange({ target: { id: 'country_id', value: id } }); }}
            />
            <Divider light />
            <LicenseInstrumentsField fields={fields} name="instruments" onChange={cmtLicenseForm.onFieldChangeInstruments} selection={instruments} />
            <DialogActions className={classes.buttonArea}>
              <div className={classes.cancelButtonWrapper}>
                <Button variant="outlined" color="primary" className={classes.cancelButton} onClick={goToList}>Cancel</Button>
              </div>
              <Button type="submit" variant="contained" color="primary" disabled={cmtLicenseForm.pristine} onClick={goToPage2}>Next</Button>
            </DialogActions>
          </Paper>
        )}
        { page === 2 && (
          <Paper className={classes.paper}>
            <Typography variant="h5" className={classes.subtitle}>License Information</Typography>
            <MultiRadioField fields={fields} name="product" onChange={cmtLicenseForm.onFieldChangeProduct} selection={products} />
            <Divider light />
            <MultiRadioField fields={fields} name="license_type" onChange={cmtLicenseForm.onFieldChangeType} selection={licenseTypes} />
            <Divider light />
            <InputField fields={fields} name="installation_number" onChange={onFieldChange} />
            <Divider light />
            <InputDateTime
              fields={fields}
              name="order_date"
              onChange={onFieldChange}
              type="date"
              disabled={!cmtLicenseForm.devModeAllowed}
            />
            <Divider light />
            <MultiRadioField fields={fields} name="license_duration" onChange={onFieldChange} selection={licenseDuration} disabled={fields.license_type.value === 'demo'} />
            <Divider light />
            <MultiRadioField fields={fields} name="modules" onChange={onFieldChange} selection={modules} />
            <Divider light />
            <InputField fields={fields} name="purchase_order_number" onChange={onFieldChange} />
            <Divider light />
            <SwitchField fields={fields} name="price_agreement" onChange={cmtLicenseForm.onFieldChangePriceAgreement} />
            <Divider light />
            <FileField fields={fields} name="price_agreement_file" onChange={onFieldChange} accept=".doc,.docx,.pdf,.png,.jpg,.jpeg" />
            <Divider light />
            <InputField fields={fields} name="comment" onChange={onFieldChange} />
            <Divider light />
            <SwitchField fields={fields} name="invoice" onChange={onFieldChange} reversed />
            <Divider light />
            <SwitchField fields={fields} name="email_me" onChange={onFieldChange} reversed />
            <DialogActions className={classes.buttonArea}>
              <div className={classes.cancelButtonWrapper}>
                <Button variant="outlined" color="primary" className={classes.cancelButton} onClick={goToList}>Cancel</Button>
                <Button variant="outlined" color="primary" onClick={goToPage1}>Previous</Button>
              </div>
              <SaveButton form={cmtLicenseForm} onClick={create} label="Request License" />
            </DialogActions>
          </Paper>
        )}
      </div>
    </div>
  );
});

CreateLicense.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withStyles(styles)(CreateLicense);
