import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { observable, action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import Done from '@material-ui/icons/Done';
import Typography from '@material-ui/core/Typography';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { simappColors, simappConstants } from 'shared/lib/theme';
import { defaultIcon } from 'shared/stores/application-store';
import texts from 'shared/lib/resources/texts';
import SimappIcon from 'web/components/_ui/simapp-icon';
import SidebarListitem from 'web/components/_ui/sidebar-listitem';
import ListItemTextFlipped from 'web/components/_ui/listitem-text-flipped';
import BaseOverlay from 'web/components/_ui/form-elements/base-overlay';
import Sidebar from 'web/components/_ui/sidebar';
import AlertDialog from 'web/components/_misc/alert-dialog';
import pctConstants from '../../shared/lib/resources/pct-constants';

const styles = theme => ({
  root: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: simappColors.darkGrey,
    width: simappConstants.sidebarMinWidth,
    height: '100%',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: 'hidden',
    '& h3': {
      whiteSpace: 'nowrap'
    },
    '&.expanded': {
      width: simappConstants.sidebarOpenWidth
    },
  },
  toggler: {
    minWidth: simappConstants.sidebarMinWidth,
    width: simappConstants.sidebarMinWidth,
    alignSelf: 'flex-end',
    color: simappColors.steelGrey,
  },
  profilePic: {
    width: simappConstants.profilePicMin,
    height: simappConstants.profilePicMin,
    padding: 0,
    minWidth: 0,
    marginTop: '1em',
    alignSelf: 'center',
    marginBottom: 8,
    '&.expanded': {
      width: simappConstants.profilePic,
      height: simappConstants.profilePic,
      marginTop: '-1.5em',
      marginBottom: '1em',
    },
    '& img': {
      width: simappConstants.profilePicMin,
      height: simappConstants.profilePicMin,
      borderRadius: '50%',
      objectFit: 'cover'
    },
    '&.expanded img': {
      width: simappConstants.profilePic,
      height: simappConstants.profilePic,
    },
    '&:hover .listitem-icon  *': {
      color: simappColors.pumpkin,
    }
  },
  settingsIcon: {
    position: 'absolute',
    top: 0,
    right: -4,
    borderRadius: '50%',
    padding: 2,
    background: simappColors.darkGrey,
    color: simappColors.steelGrey,
    '&.minimized': {
      display: 'none'
    },
    '&.current': {
      color: simappColors.pumpkin,
    },
  },
  profileName: {
    alignSelf: 'center',
    color: simappColors.steelGrey,
    whiteSpace: 'nowrap'
  },
  topNav: {
    flex: 1,
    '& > div': {
      paddingLeft: simappConstants.sidebarPadding,
      paddingRight: simappConstants.sidebarPadding,
    },
    '& *': {
      color: simappColors.steelGrey
    },
    '& > div:hover *': {
      color: simappColors.white
    },
    '& > div:hover .listitem-icon *': {
      color: simappColors.pumpkin
    },
  },
  bottomNav: {
    '& > div': {
      paddingLeft: simappConstants.sidebarPadding,
      paddingRight: simappConstants.sidebarPadding,
      paddingTop: 11,
      paddingBottom: 11,
    },
    '& *': {
      color: simappColors.steelGrey
    },
    '& > div:hover *': {
      color: simappColors.white
    },
    '& > div:hover .listitem-icon *': {
      color: simappColors.pumpkin
    },
  },
  logout: {
    padding: '12px 8px 6px 0px',
    '& *': {
      color: simappColors.strawberry,
      whiteSpace: 'nowrap',
    }
  },
  current: {
    backgroundColor: simappColors.dark,
    '& svg *': {
      color: simappColors.pumpkin
    },
    '& span': {
      color: simappColors.white
    },
    '&:focus': {
      backgroundColor: simappColors.dark
    }
  },
  presentingIn: {
    '& h6': { color: simappColors.white },
    '& p': { textTransform: 'uppercase', fontSize: 12, whiteSpace: 'nowrap' },
    '& p, + * svg': { color: simappColors.steelGrey },
  },
  presentingInSmall: {
    padding: 0,
    '& button': {
      paddingTop: 11,
      paddingBottom: 11,
      paddingLeft: simappConstants.sidebarPadding,
      paddingRight: simappConstants.sidebarPadding,
    },
    '& h3': {
      color: simappColors.steelGrey
    }
  },
  editFavShare: {
    backgroundColor: simappColors.dark,
    paddingTop: 6,
    paddingBottom: 6,
    flexDirection: 'row-reverse',
    '&.minimized': {
      display: 'block',
      paddingLeft: 0
    }
  },
  editFavShareButton: {
    width: '50%',
    display: 'block',
    textAlign: 'center',
    '& *': {
      color: simappColors.azul,
    },
    '&:hover *': {
      color: lighten(simappColors.azul, 0.4)
    },
    '&.minimized': {
      paddingLeft: 0,
      fontSize: '1rem'
    }
  },
});

@inject('routing', 'applicationStore', 'countryStore') @observer
class DefaultSidebar extends Component {
  @observable countriesOpen = false;

  @action openCountrySelectionMenu = () => {
    this.countriesOpen = true;
  };

  @action closeCountrySelectionMenu = () => {
    this.countriesOpen = false;
  };

  render() {
    const { location, push } = this.props.routing;
    const { sidebarOpen } = this.props.applicationStore;
    const expanded = sidebarOpen ? 'expanded' : 'minimized';
    const isUS = this.props.applicationStore.presentingInName === pctConstants.CountryNameUS;

    return (
      <Fragment>
        <Button
          onClick={() => push('/settings')}
          title="Settings"
          className={`${this.props.classes.profilePic} ${expanded}`}
          disabled={this.props.applicationStore.sharingMode}
        >
          <img
            alt="profile pic"
            src={this.props.applicationStore.appUser.get('profile_pic') !== null ? this.props.applicationStore.appUser.get('profile_pic') : defaultIcon}
          />
          <SimappIcon
            icon="settings"
            className={`${this.props.classes.settingsIcon} ${expanded} ${location.pathname === '/settings' ? 'current' : ''} listitem-icon`}
          />
        </Button>
        {
          sidebarOpen
          && (
            <Typography
              variant="h5"
              gutterBottom
              className={this.props.classes.profileName}
            >{this.props.applicationStore.appUser.get('name')}
            </Typography>
          )
        }
        <List
          component="nav"
          className={this.props.classes.topNav}
        >
          <SidebarListitem
            onClick={() => push('/')}
            icon="dashboard"
            primary="Dashboard"
            className={location.pathname === '/' ? this.props.classes.current : ''}
            disabled={this.props.applicationStore.sharingMode}
          />
          { this.props.applicationStore.canAccessMaterials && (
            <SidebarListitem
              onClick={() => push('/materials')}
              icon="marketingMaterials"
              primary="All Material"
              className={location.pathname === '/materials' ? this.props.classes.current : ''}
              disabled={this.props.applicationStore.sharingMode}
            />
          )}
          {/* This will be used once we have more then Software in Service:
           { this.props.applicationStore.canAccessServices && (
            <SidebarListitem
              onClick={() => push('/services')}
              icon="services"
              primary="Services"
              className={location.pathname.includes('/services') ? this.props.classes.current : ''}
              disabled={this.props.applicationStore.sharingMode}
            />
          )}
          { !this.props.applicationStore.canAccessServices && this.props.applicationStore.canAccessSoftware && ( */}
          { this.props.applicationStore.canAccessSoftware && (
            <SidebarListitem
              onClick={() => push('/services/software')}
              icon="service"
              primary="Service Materials"
              className={location.pathname.includes('/services/software') ? this.props.classes.current : ''}
              disabled={this.props.applicationStore.sharingMode}
            />
          )}
          { this.props.applicationStore.canAccessCmt && (
            <SidebarListitem
              onClick={() => push('/services/cmt')}
              icon="cmtMenu"
              primary="CMT"
              className={location.pathname.includes('cmt') ? this.props.classes.current : ''}
              disabled={this.props.applicationStore.sharingMode}
            />
          )}
          {this.props.applicationStore.canAccessHeModels && !isUS && (
            <SidebarListitem
              onClick={() => push('/he-models')}
              icon="pctCalculator"
              primary="HE Models"
              className={location.pathname.includes('/he-models') ? this.props.classes.current : ''}
              disabled={this.props.applicationStore.sharingMode}
            />
          )}
          {(!this.props.applicationStore.canAccessHeModels || isUS) && this.props.applicationStore.canAccessPct && (
            <SidebarListitem
              onClick={() => push('/he-models/pct-calculator')}
              icon="pctCalculator"
              primary="PCT Calculator"
              className={location.pathname.includes('/he-models') ? this.props.classes.current : ''}
              disabled={this.props.applicationStore.sharingMode}
            />
          )}
          {!this.props.applicationStore.canAccessHeModels && !isUS && this.props.applicationStore.canAccessCopeptin && (
            <SidebarListitem
              onClick={() => push('/he-models/copeptin-calculator')}
              icon="pctCalculator"
              primary="Copeptin Calculator"
              className={location.pathname.includes('/he-models') ? this.props.classes.current : ''}
              disabled={this.props.applicationStore.sharingMode}
            />
          )}
          {this.props.applicationStore.canAccessKryptor && (
            <SidebarListitem
              onClick={() => push('/kryptor-calculator')}
              icon="consumablesCalculator"
              primary="KRYPTOR Calculator"
              className={location.pathname.includes('/kryptor-calculator') ? this.props.classes.current : ''}
              disabled={this.props.applicationStore.sharingMode}
            />
          )}
          {this.props.applicationStore.canAccessQcvc && (
            <SidebarListitem
              onClick={() => push('/qc-calculator')}
              icon="qC"
              primary="QC Calculator"
              className={location.pathname.includes('/qc-calculator') ? this.props.classes.current : ''}
              disabled={this.props.applicationStore.sharingMode}
            />
          )}
          {this.props.applicationStore.canAccessQcvc && (
            <SidebarListitem
              onClick={() => push('/mc-start')}
              icon="mC"
              primary="Method Comparison"
              className={location.pathname.includes('/mc') ? this.props.classes.current : ''}
              disabled={this.props.applicationStore.sharingMode}
            />
          )}
        </List>
        <List
          component="nav"
          className={this.props.classes.bottomNav}
        >
          {this.props.applicationStore.sharingMode && !this.props.applicationStore.sharingOpen
          && (
            <ListItem className={`${this.props.classes.editFavShare} ${expanded}`}>
              <Button
                className={`${this.props.classes.editFavShareButton} ${expanded}`}
                onClick={this.props.applicationStore.doneSelectMore}
              >
                { sidebarOpen
                  ? (<Typography>Done</Typography>)
                  : (<Done />)
                }
              </Button>
              <Button
                className={`${this.props.classes.editFavShareButton} ${expanded}`}
                onClick={this.props.applicationStore.cancelSelectMore}
              >
                { sidebarOpen
                  ? (<Typography>Cancel</Typography>)
                  : (<Close />)
                }
              </Button>
            </ListItem>
          )
          }
          <Divider />
          {
            sidebarOpen
            && (
              <ListItem className={this.props.classes.presentingIn}>
                <ListItemTextFlipped
                  primary={this.props.applicationStore.presentingInName}
                  secondary="presenting in"
                />
                <ListItemSecondaryAction>
                  <IconButton
                    aria-label="Region Selection"
                    aria-haspopup="true"
                    onClick={this.openCountrySelectionMenu}
                    disabled={this.props.applicationStore.sharingMode}
                  >
                    <SimappIcon icon="edit" title="Change presentation region" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            )
          }
          {
            !sidebarOpen
            && (
              <ListItem
                className={this.props.classes.presentingInSmall}
                button
                onClick={this.openCountrySelectionMenu}
                disabled={this.props.applicationStore.sharingMode}
                title="Change presentation region"
              >
                <ListItemText primary={this.props.applicationStore.presentingInIso} />
              </ListItem>
            )
          }
          <Divider />
          <SidebarListitem
            onClick={() => push('/presentation')}
            icon="presentation"
            primary="Presentation Mode"
            disabled={this.props.applicationStore.sharingMode}
          />
          <Divider />
        </List>
        <BaseOverlay
          open={this.countriesOpen}
          onClose={this.closeCountrySelectionMenu}
          onSelect={(id) => {
            this.props.applicationStore.setPresentingIn(id);
          }}
          currentSelect={this.props.applicationStore.presentingIn}
          label="Region"
          list={this.props.countryStore.getCountriesForSelect()}
        />
        <AlertDialog
          trigger={this.props.applicationStore.presentingInChanged}
          title={`${texts.countryChangedTo}${this.props.applicationStore.presentingInName}`}
          message={texts.countryChangedNote}
          close={this.props.applicationStore.cancelPresentingInChanged}
          // additionalActions={[{ label: texts.dontShowAgain, action: this.props.applicationStore.dontShowPresentingInChangedAgain }]}
          width="xs"
        />
      </Fragment>
    );
  }
}

DefaultSidebar.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  applicationStore: PropTypes.object.isRequired,
  countryStore: PropTypes.object.isRequired,
  routing: PropTypes.object.isRequired,
};

export default Sidebar(withStyles(styles)(DefaultSidebar));
