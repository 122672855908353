import { action, observable, runInAction, toJS } from 'mobx';
import { applicationStore } from 'shared/stores';
import BaseForm from './base-form';

class ChangePasswordForm extends BaseForm {
  @observable
  fields = {
    old_password: {
      value: '',
      type: 'password',
      error: null,
      vertical: false,
      label: 'Old Password',
      required: true,
      rule: 'required',
    },
    new_password_1: {
      value: '',
      type: 'password',
      error: null,
      vertical: false,
      label: 'New Password',
      required: true,
      rule: 'required',
    },
    new_password_2: {
      value: '',
      type: 'password',
      error: null,
      vertical: false,
      label: 'Repeat New Password',
      required: true,
      rule: 'required',
    },
  };

  @observable object = {};
  @observable isNew = true;
  @observable showDialog = false;

  @action close = () => {
    this.showDialog = false;
    this.resetValues();
  };

  @action new = () => {
    this.showDialog = true;
  };

  @action resetErrorStatus = () => {
    Object.keys(this.fields).forEach((key) => {
      this.fields[key].error = null;
    });
  }

  @action resetValues = () => {
    const copy = new ChangePasswordForm();
    this.fields = toJS(copy.fields);
    this.pristine = true;
  };

  @action save = () => {
    this.saveInProgess = true;
    Object.keys(this.fields).forEach((key) => {
      this.object[key] = this.fields[key].value;
    });

    return new Promise((resolve, reject) => {
      applicationStore.updatePassword(this.object)
        .then((result) => {
          if (!result.data.success) {
            this.setResponseError(result.data.msg);
          } else {
            runInAction(() => {
              this.showDialog = false;
              this.resetValues();
              applicationStore.addNotification('Updated Password.');
            });
          }
          resolve();
        })
        .catch(() => {
          this.setResponseError('unkown error');
          reject();
        }).finally(() => {
          runInAction(() => {
            this.saveInProgess = false;
          });
        });
    });
  }
}

export default new ChangePasswordForm();
