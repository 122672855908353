import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import Radio from '@material-ui/core/Radio';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.5em',
  },
  labelWrapper: {
    width: '25%',
    display: 'flex',
    position: 'relative',
    height: 32
  },
  label: {
    position: 'static',
    transform: 'none',
    alignSelf: 'center',
    whiteSpace: 'nowrap',
    width: '100%'
  },
  label2: {
    position: 'static',
    transform: 'none',
    alignSelf: 'center',
    whiteSpace: 'nowrap',
    width: '100%',
    fontWeight: 400,
    marginRight: '2rem'
  },
  errorMessage: {
    whiteSpace: 'nowrap',
    position: 'absolute',
    bottom: -6
  },
  inputField: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row'
  },
  rootVertical: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'flex-start',
    '& textarea': {
      padding: 0,
      overflowY: 'auto'
    },
    MuiInput: {
      root: { width: '100%' }
    }
  },
  inputVertical: {
    width: '100%',
    '& div': {
      overflowY: 'auto',
      height: '100%'
    },
    '& textarea': {
      minHeight: '100%'
    }
  },
  inputBlock: {
    flexDirection: 'column',
  },
  hide: {
    display: 'none',
    '& + hr': {
      display: 'none',
    }
  },
};

const MultiRadioField = observer((props) => {
  const { fields, name, onChange } = props;
  const rootClassVertical = fields[name].vertical ? props.classes.rootVertical : '';
  const inputClassVertical = fields[name].vertical ? props.classes.inputVertical : '';
  const inputClassBlock = fields[name].block ? props.classes.inputBlock : '';
  const hideClass = (fields[name].hideWhenDisabled && (fields[name].disabled || props.disabled)) ? props.classes.hide : '';

  /**
   * props.selection can be in a simple form:
   * { value: "name" }
   * and a complex form:
   * { value: { name: "name", disabled: false }}
   */

  return (
    <FormControl
      error={!!fields[name].error}
      disabled={fields[name].disabled || props.disabled}
      required={fields[name].required}
      className={`${props.classes.root} ${rootClassVertical} ${hideClass}`}
    >
      { !!fields[name].label
      && (
        <div className={props.classes.labelWrapper}>
          <InputLabel htmlFor={name} disableAnimation shrink={false} className={props.classes.label}>{fields[name].label}</InputLabel>
          <FormHelperText id={`${name}-error`} className={props.classes.errorMessage}>{fields[name].error}</FormHelperText>
        </div>
      )
      }
      <div
        className={`${props.classes.inputField} ${inputClassVertical} ${inputClassBlock} `}
      >
        {Object.entries(props.selection).map(s => (
          <div key={s[0]}>
            <Radio
              id={s[0]}
              checked={fields[name].value === s[0]}
              onChange={() => { onChange({ target: { id: name, value: s[0] } }); }}
              color="primary"
              disabled={fields[name].disabled || props.disabled || s[1].disabled === true}
            />
            <InputLabel htmlFor={s[0]} disableAnimation shrink={false} required={false} className={props.classes.label2} disabled={fields[name].disabled || props.disabled || s[1].disabled === true}>{s[1].name ? s[1].name : s[1]}</InputLabel>
          </div>
        ))}
      </div>
    </FormControl>
  );
});

MultiRadioField.propTypes = {
  classes: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selection: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

MultiRadioField.defaultProps = {
  disabled: false,
};

export default withStyles(styles)(MultiRadioField);
