import { observable, action, runInAction, computed, toJS, intercept, autorun, when } from 'mobx';
import { persist } from 'mobx-persist';
import moment from 'moment';
import { isEqual } from 'lodash';
import { synchronize } from '@nozbe/watermelondb/sync';
import rest from 'shared/lib/rest';
import config from 'shared/config';
import { objToMap, mapToNewObj } from 'shared/lib/helpers';
import { contentTypeStore, countryStore, roleStore, materialStore, languageStore, productStore, topicStore, presentationStore, calendarStore, cmtCredentialsForm, cmtLicenseForm } from 'shared/stores';
import loginForm from 'shared/stores/forms/login-form';
import settingsForm from 'shared/stores/forms/settings-form';
import Adal from 'shared/lib/adal-request';
import Oauth from 'shared/lib/oauth-request';
import highNumberFix from 'shared/helper/number-helper';
import isInIframe from 'shared/lib/is-in-iframe';
import routing from './router-store';
import createStorage from './create-storage';
import getVersion from './get-version';
import { setScreenOrientationAll, setScreenOrientationLandscape } from './screen-orientation';
import database from './database/database';

const route = '/user';
const logoutRoute = '/logout';
const healthRoute = '/health';
const versionRoute = '/version';
const resetPasswordRoute = '/reset_password';
const changePasswordRoute = '/change_password';
const cmtLicenseEmailRoute = '/cmt_sendLinceseMail';
export const defaultIcon = '/icons/mask-placeholder@3x.png';
/**
 * defines modules to load from the backend, as we need different data in the normal webapp than in Showpad
 * the selection done here will also interfere with the modules shown in the sidebar
 * available modules are: materials, services, calculators
 */
export const modules = ['materials', 'services'];

class ApplicationStore {
  constructor() {
    createStorage('applicationStore', this).then(() => {
      this.checkBackendHealth();
      this.loadData();
      this.handleNotificationLifetime();
    });

    // eslint-disable-next-line
    Object.defineProperty(String.prototype, 'highNumberFix', {
      value: function fix(precision) {
        return highNumberFix(parseFloat(this.split(' ').join('')), precision);
      },
      writable: true,
      configurable: true
    });

    // eslint-disable-next-line
    Object.defineProperty(Number.prototype, 'highNumberFix', {
      value: function fix(precision) { return highNumberFix(this, precision); }, writable: true, configurable: true
    });
  }

  loadData() {
    if (isInIframe()) return;
    runInAction(() => { this.initialLoading = false; });
    this.authModel.isAuthenticated().then(() => {
      // check: our user in appStore must be identical to the azure user
      if (this.authModel.oauthData.userName.length > 0 && typeof (this.appUser.get('email')) !== 'undefined' && this.appUser.get('email').length > 0 && this.authModel.oauthData.userName !== this.appUser.get('email')) {
        this.logout(true);
        return;
      }
      if (!this.authModel.oauthData.isAuthenticated) {
        this.unauthenticated();
        return;
      }
      this.authenticated();

      // set token for API auth
      rest.defaults.headers.common.Authorization = this.authModel.oauthData.accessToken;

      // check if the current build version is already know
      // if not, reload everything, API structure might have changed
      const forceReload = this.buildTime !== config.buildTime || this.version !== getVersion;
      /**
       * After login, get user data from backend.
       * If data is available, persist, so we can use that for the next login
       * If not, show user profile form.
       */
      // load data if needed
      if (!this.userLoaded || forceReload) {
        rest.get(route, { params: { email: this.authModel.oauthData.userName } }).then((response) => {
          if (response.data.length > 0 && response.data[0].country_id !== null) {
            // ok, we have a user
            runInAction(() => {
              objToMap(response.data[0], this.appUser);
              // profile pic is base64 data only, but we permanently want to add data:image prefix
              if (this.appUser.get('profile_pic') !== null && this.appUser.get('profile_pic').length > 0) {
                this.appUser.set('profile_pic', `data:image/png;base64,${this.appUser.get('profile_pic')}`);
              } else {
                this.appUser.set('profile_pic', defaultIcon);
              }
              this.presentingIn = response.data[0].country_id;
              this.userLoaded = true;
              this.buildTime = config.buildTime;
              this.version = getVersion;
              this.initialLoadingDone(true);
            });
          } else {
            runInAction(() => {
              objToMap(response.data[0], this.appUser);
              this.userLoaded = false;
              this.appUser.set('profile_pic', defaultIcon);
              this.appUser.set('email', this.authModel.oauthData.userName);
              this.buildTime = config.buildTime;
              this.version = getVersion;
              settingsForm.setDefaultValues();
              // countries and roles are required for the settings form
              countryStore.loadData(true);
              roleStore.loadData(true);
              if (!this.appUser.get('local_auth')) {
                // get Name from Azure API
                const meSettings = {
                  url: 'https://outlook.office.com/api/v2.0/me',
                };
                Adal.adalRequest(meSettings).then((result) => {
                  const names = typeof result.DisplayName !== 'undefined' ? result.DisplayName.split(', ') : [];
                  const name = names.length === 2 ? `${names[1]} ${names[0]}` : '';
                  settingsForm.setProperty('name', name);
                });
                this.initialLoading = true;
              }
            });
          }
        })
          .catch((error) => {
            console.log(error);
          });
        // user is already known and logged in, just update data
        // update distributor user data in case this has been modified in the backend
      } else if (this.isDistributor) {
        rest.get(route, { params: { email: this.authModel.oauthData.userName } }).then((response) => {
          if (response.data.length > 0) {
            // ok, we have a user
            runInAction(() => {
              const forceReloadDistri = !isEqual(response.data[0].country_ids, this.appUser.get('country_ids'));
              objToMap(response.data[0], this.appUser);
              // if "presenting in" is not in allowed list, set to first country
              if (!this.appUser.get('country_ids').includes(this.presentingIn)) {
                this.presentingIn = this.appUser.get('country_ids')[0];
              }
              this.initialLoadingDone(forceReloadDistri);
            });
          } else {
            // no user found - maybe has been deleted, so we log out
            this.logout(true);
          }
        });
      } else {
        this.initialLoadingDone();
      }
    }, () => {
      this.unauthenticated();
    });
  }

  newVersion = 0;
  @observable showNewVersionWarning = false;
  @observable showNewVersionLock = false;
  @action cancelNewVersionWarning = () => {
    this.showNewVersionWarning = false;
  }

  loadVersionData = () => new Promise((resolve) => {
    if (getVersion !== null) {
      rest.get(`${versionRoute}/${getVersion}`).then((response) => {
        if (response.data.expired) {
          runInAction(() => {
            this.newVersion = response.data.newestVersion;
            this.showNewVersionWarning = true;
            this.showNewVersionLock = response.data.locked;
          });
        }
        resolve();
      });
    } else resolve();
  });

  // ----- USER -----

  @persist('map') @observable appUser = new Map([['id', null], ['country_id', 0], ['role_id', 0], ['local_auth', false]]);

  @persist @observable presentingIn = null;
  @persist @observable dontShowPresentingInChanged = false;
  @observable presentingInChanged = false;

  @computed get newUser() {
    return this.userLoaded && !this.appUser.get('country_id');
  }

  @computed get userCountryName() {
    return countryStore.getCountryName(this.appUser.get('country_id'));
  }

  @computed get userCountryIso() {
    return countryStore.getCountryIso(this.appUser.get('country_id'));
  }

  @action setUserProperty = (property, value) => {
    this.appUser.set(property, value);
  }

  // eslint-disable-next-line class-methods-use-this
  async userIsInBackend() {
    const response = await rest.get(route, { params: { email: this.authModel.oauthData.userName } });
    return !!response.data.length;
  }

  get canAccessMaterials() {
    return modules.includes('materials') && (this.appUser.get('local_auth') === false || this.appUser.get('access_materials'));
  }

  get canAccessPresentations() {
    return modules.includes('materials') && (this.appUser.get('local_auth') === false || this.appUser.get('access_presentations'));
  }

  // PCT and Copeptin are always shown and don't need anything from the backend
  get canAccessHeModels() {
    return (this.appUser.get('local_auth') === false || (this.appUser.get('access_pct') && this.appUser.get('access_copeptin')));
  }

  get canAccessPct() {
    return (this.appUser.get('local_auth') === false || this.appUser.get('access_pct'));
  }

  get canAccessCopeptin() {
    return (this.appUser.get('local_auth') === false || this.appUser.get('access_copeptin'));
  }

  get canAccessKryptor() {
    return modules.includes('calculators') && (this.appUser.get('local_auth') === false || this.appUser.get('access_kryptor'));
  }

  get canAccessQcvc() {
    return modules.includes('calculators') && (this.appUser.get('local_auth') === false || this.appUser.get('access_qcvc'));
  }

  get canAccessAcc() {
    return modules.includes('calculators') && (this.appUser.get('local_auth') === false || this.appUser.get('access_acc'));
  }

  get canAccessServices() {
    return modules.includes('services') && (this.appUser.get('local_auth') === false || (this.appUser.get('access_software')));
  }

  get canAccessSoftware() {
    return modules.includes('services') && (this.appUser.get('local_auth') === false || this.appUser.get('access_software'));
  }

  get canAccessCmt() {
    return modules.includes('services') && (this.appUser.get('access_cmt'));
  }

  get permissionList() {
    return toJS(this.appUser.get('permission_list'));
  }

  eolFormatted(field) {
    return this.appUser.get(field) ? (new Date(this.appUser.get(field))).toLocaleDateString() : 'N/A';
  }

  handlePctSetPresentingInChange(navigation) {
    const location = routing.location.pathname;
    if (location !== undefined && location.includes('/pct-calculator') && !location.endsWith('/pct-calculator')) {
      this.skipPctLeavingCheck = true;
      try {
        routing.push('/he-models/pct-calculator');
      } catch {
        navigation.navigate('/he-models/pct-calculator');
      }
    }
  }

  @action setPresentingIn = (countryId, navigation) => {
    const oldValue = this.presentingIn;
    this.presentingIn = countryId;
    if (!this.dontShowPresentingInChanged && oldValue !== null && oldValue !== countryId) this.presentingInChanged = true;
    this.handlePctSetPresentingInChange(navigation);
    if (navigation !== undefined) {
      navigation.closeDrawer();
    }
  }

  @action cancelPresentingInChanged = () => {
    this.presentingInChanged = false;
  }

  @action dontShowPresentingInChangedAgain = () => {
    this.dontShowPresentingInChanged = true;
    this.presentingInChanged = false;
  }

  @computed get presentingInName() {
    return countryStore.getCountryName(this.presentingIn);
  }

  @computed get presentingInIso() {
    return countryStore.getCountryIso(this.presentingIn);
  }

  updateAppUser = (navigation, reloadNews) => {
    const data = mapToNewObj(this.appUser);
    data.profile_pic = (data.profile_pic === defaultIcon) ? '' : data.profile_pic.replace(/data:image\/[a-z]*;base64,/i, '');
    if (!this.appUser.get('id')) { // new user
      rest.post(route, data)
        .then((response) => {
          if (response.data.success) {
            runInAction(() => {
              this.appUser.set('id', response.data.id);
              this.userLoaded = true;
              this.presentingIn = this.appUser.get('country_id');
              this.initialLoadingDone(true);
              try {
                routing.push('/');
              } catch {
                navigation.navigate('/');
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      rest.put(`${route}/${this.appUser.get('id')}`, data)
        .then(() => {
          if (reloadNews) {
            // newsStore.loadData(true);
          }
          if (this.userLoaded === false) {
            this.userLoaded = true;
            this.presentingIn = this.appUser.get('country_id');
            this.initialLoadingDone(true);
            try {
              routing.push('/');
            } catch {
              navigation.navigate('/');
            }
          }
        })
        .catch((error) => { console.log(error); });
    }
  }

  updatePassword = async object => rest.put(changePasswordRoute, object)

  // ----- AUTH -----

  @observable isAuthenticated = null;
  @persist @observable userLoaded = null;
  @persist @observable buildTime = null;
  @persist @observable version = null;
  @persist @observable lastUpdated = null;

  get authModel() {
    return this.appUser.get('local_auth') ? Oauth : Adal;
  }

  get isDistributor() {
    return this.appUser.get('local_auth');
  }

  @action authenticated = () => {
    this.isAuthenticated = true;
  }

  @action unauthenticated = () => {
    this.isAuthenticated = false;
  }

  @action login = (prompt = false) => {
    const force = !this.userLoaded;
    this.isAuthenticated = null;
    Adal.login(force, prompt);
  }

  @action loginLocal = async (username, password, code = null) => {
    const status = await Oauth.login(username, password, code);
    if (status === true) {
      runInAction(() => {
        this.initialLoading = false;
        this.appUser.set('local_auth', true);
      });
      this.loadData();
    }
    return status;
  }

  @action loginLocal2FA = async (username, userId, rnd, pin, remember) => {
    const status = await Oauth.login2FA(username, userId, rnd, pin, remember);
    if (status === true) {
      runInAction(() => {
        this.initialLoading = false;
        this.appUser.set('local_auth', true);
        // save 2FA code in cookie
        const date = new Date();
        date.setMonth(date.getMonth() + 3);
        document.cookie = `mfacode=${pin}; expires=${date.toUTCString()}; path=/`;
      });
      this.loadData();
    }
    return status;
  }

  resetPassword = async (username) => {
    await rest.post(resetPasswordRoute, { email: username });
  }

  sendDeleteProfileRequest = async () => {
    const response = await rest.delete(route);
    this.addNotification(response.data.msg);
  }

  @action logout = (full = false) => {
    this.clearTimer();
    Adal.logout();
    if (full) {
      rest.post(logoutRoute);
      this.appUser.set('local_auth', false);
      // localforage.clear();
      localStorage.clear();
      this.reset();
      // window.removeEventListener("beforeunload", (e) => {
      //   e.preventDefault();
      //   applicationStore.logout(true);
      // });

      window.removeEventListener('click', this.startTimer);
      window.removeEventListener('mousemove', this.startTimer);
    }
    this.isAuthenticated = false;
  }

  @action loginNew = () => {
    this.logout(true);
    // this.isAuthenticated = null;
    // this.login();
  }

  @action reset = () => {
    this.userLoaded = null;
    this.appUser = new Map();
    this.isAuthenticated = false;
    this.presentingIn = null;
    this.dontShowPresentingInChanged = false;
    // clear content that is related to the user (e.g. presentations, favs)
    materialStore.materials = [];
    materialStore.recent = [];
    materialStore.current = [];
    countryStore.countries = [];
    this.lastUpdated = null;
  }

  @observable backendHealth = true;
  @observable networkConnection = true; // used in native; true when network is available

  checkBackendHealth = () => {
    rest.options(healthRoute)
      .then(() => {
        runInAction(() => {
          this.backendHealth = true;
        });
      })
      .catch(() => {
        runInAction(() => {
          this.backendHealth = false;
        });
      });
  }

  // ----- UI -----

  @observable sidebarOpen = false;
  @observable presentationMode = false;
  @observable initialLoading = false; // set to true only after data loading and token are available, don't render anything before
  @observable currentlyPresenting = null;
  @observable sharingMode = false;
  @observable sharingOpen = false;
  @observable sendProposalDialogOpen = false;
  location = '';
  @observable showAddressDialog = false;
  @observable showTestRequestDialog = false;
  @observable showInstrumentTypeDialog = false;
  @observable showKryptorValidationDialog = false;
  @observable kryptorValidationMsg = '';

  @action setLocation = (location = '') => {
    this.location = location;
  }

  @observable isPctLeavingAlert = false;
  @observable skipPctLeavingCheck = false;
  @observable interceptedPathname = '';

  observeRouting = intercept(routing, 'location', (newLocation) => {
    const newPathname = newLocation.newValue.pathname;
    const oldPathname = routing.location !== null ? routing.location.pathname : ''; // initially, routing.location is null

    // Close sidebar on every route change
    if (newPathname !== oldPathname) {
      runInAction(() => { this.sidebarOpen = false; });
    }

    // resetting credentials form when leaving
    if (oldPathname.includes('/cmt/credentials')) {
      cmtCredentialsForm.clearValues();
    }
    if (oldPathname.includes('/cmt/license') && !oldPathname.includes('/cmt/licenselist')) {
      cmtLicenseForm.clearValues();
    }

    if (oldPathname.includes('/login') && !newPathname.includes('/login')) {
      loginForm.resetForm();
    }

    // when we intentionally leave (after alert), skip check once
    if (this.skipPctLeavingCheck) {
      this.skipPctLeavingCheck = false;
      return newLocation;
    }

    if (oldPathname.includes('pct-calculator')
      && !oldPathname.endsWith('results')
      && !oldPathname.endsWith('pct-calculator')
      && !oldPathname.includes('pct-algorithm')
      && !oldPathname.endsWith('references')
      && (newPathname.endsWith('pct-calculator') || !newPathname.includes('pct-calculator'))) {
      this.isPctLeavingAlert = true;
      this.interceptedPathname = newLocation.newValue.pathname;
      newLocation.newValue.pathname = routing.location.pathname; // force current path
    }
    return newLocation;
  });

  watermelonDisposer = autorun(() => {
    if (routing.location === null) return;
    const location = routing.location.pathname;

    // wait for loading data into Mobx until sync with backend is done
    when(
      () => this.initialLoading,
      async () => {
        // Dashboard
        if (location === '/' || location.includes('materials') || (location.includes('presentation') && materialStore.isInMaterials)) {
          if (materialStore.isInSoftware) materialStore.materials = [];
          if (materialStore.materials.length === 0) {
            materialStore.switchToMaterials();
            contentTypeStore.setContentTypesForMaterials();
            materialStore.current.replace([]);
            const keywords = await database.collections.get('keyword').query().fetch();
            const keywordList = {};
            keywords.forEach((k) => {
              keywordList[k.uid] = k.name;
            });
            const materials = (await database.collections.get('material').query().fetch()).filter(m => m.availableInMaterials);
            materialStore.materials = materials.map((m) => {
              // eslint-disable-next-line no-underscore-dangle
              const material = { ...m._raw };
              material.country_ids = material.country_ids.length > 0 ? JSON.parse(material.country_ids) : [];
              material.product_ids = material.product_ids.length > 0 ? JSON.parse(material.product_ids) : [];
              material.topic_ids = material.topic_ids.length > 0 ? JSON.parse(material.topic_ids) : [];
              material.recommended_country_ids = material.recommended_country_ids !== null && material.recommended_country_ids.length > 0 ? JSON.parse(material.recommended_country_ids) : [];
              material.presentation_items = material.presentation_items !== null && material.presentation_items.length > 0 ? JSON.parse(material.presentation_items) : [];
              material.isRecommended = () => material.recommended_country_ids.includes(this.presentingIn);
              const contentType = contentTypeStore.classicalContentTypes.find(cT => cT.id === material.content_type_id);
              material.content_type = typeof contentType !== 'undefined' ? contentType.name : '';
              material.favorite = observable.box(material.favorite);
              material.id = material.b_id;
              material.keywords = [];
              if (material.material_type !== materialStore.materialTypes.presentation) {
                const keywordIds = JSON.parse(material.keyword_ids);
                if (Array.isArray(keywordIds)) {
                  keywordIds.forEach((k) => {
                    material.keywords.push(keywordList[k]);
                  });
                }
              }
              return material;
            });
            materialStore.resetAllFilters();
            materialStore.materialsUpdated = Date.now();
            console.log('loaded materials into MobX');
          }
        } else if (location.includes('software') || (location.includes('presentation') && materialStore.isInSoftware)) {
          if (materialStore.isInMaterials) materialStore.materials = [];
          if (materialStore.materials.length === 0) {
            materialStore.switchToSoftware();
            contentTypeStore.setContentTypesForSoftware();
            materialStore.current.replace([]);
            const keywords = await database.collections.get('keyword').query().fetch();
            const keywordList = {};
            keywords.forEach((k) => {
              keywordList[k.uid] = k.name;
            });
            const materials = (await database.collections.get('material').query().fetch()).filter(m => m.availableInSoftware);
            materialStore.materials = materials.map((m) => {
              // eslint-disable-next-line no-underscore-dangle
              const material = { ...m._raw };
              material.country_ids = material.country_ids.length > 0 ? JSON.parse(material.country_ids) : [];
              material.product_ids = material.product_ids.length > 0 ? JSON.parse(material.product_ids) : [];
              material.topic_ids = material.topic_ids.length > 0 ? JSON.parse(material.topic_ids) : [];
              material.recommended_country_ids = material.recommended_country_ids !== null && material.recommended_country_ids.length > 0 ? JSON.parse(material.recommended_country_ids) : [];
              material.presentation_items = material.presentation_items !== null && material.presentation_items.length > 0 ? JSON.parse(material.presentation_items) : [];
              material.isRecommended = () => material.recommended_country_ids.includes(this.presentingIn);
              const contentType = contentTypeStore.classicalContentTypes.find(cT => cT.id === material.content_type_id);
              material.content_type = typeof contentType !== 'undefined' ? contentType.name : '';
              material.favorite = observable.box(material.favorite);
              material.id = material.b_id;
              material.keywords = [];
              if (material.material_type !== materialStore.materialTypes.presentation) {
                const keywordIds = JSON.parse(material.keyword_ids);
                if (Array.isArray(keywordIds)) {
                  keywordIds.forEach((k) => {
                    material.keywords.push(keywordList[k]);
                  });
                }
              }
              return material;
            });
            materialStore.resetAllFilters();
            materialStore.materialsUpdated = Date.now();
          }
        }
      }
    );
  }, { onError: (e) => { console.log(e); } });

  @action toggleTestRequestDialog = () => {
    this.showTestRequestDialog = !this.showTestRequestDialog;
  }

  @action toggleShareDialog = () => {
    this.sharingOpen = !this.sharingOpen;
  }

  @action toggleSendProposalDialog = () => {
    this.sendProposalDialogOpen = !this.sendProposalDialogOpen;
  }

  @action toggleKryptorValidationDialog = () => {
    this.showKryptorValidationDialog = !this.showKryptorValidationDialog;
  }

  @action setKryptorValidationMsg = (message) => {
    this.kryptorValidationMsg = message;
  }

  @action toggleInstrumentTypeDialog = () => {
    this.showInstrumentTypeDialog = !this.showInstrumentTypeDialog;
  }

  @action disablePctLeavingAlert = (skip) => {
    this.isPctLeavingAlert = false;
    this.skipPctLeavingCheck = skip === undefined ? true : skip;
  }

  @action toggleSidebar = () => {
    this.sidebarOpen = !this.sidebarOpen;
  }

  @action setPresentationMode = (on = true) => {
    if (!on) this.setCurrentlyPresenting(null);
    this.presentationMode = on;
  }

  timer = null;

  clearTimer = () => {
    if (this.timer) clearTimeout(this.timer);
  }

  startTimer = () => {
    this.clearTimer();
    this.timer = setTimeout(() => {
      this.logout(true);
    }, 300000); // 5 min
  }

  monitorLogoutTrigger() {
    if (config.isDev) return;
    this.startTimer();

    window.addEventListener('beforeunload', (e) => {
      e.preventDefault();
      if (this.isAuthenticated) {
        this.logout(true);
      }
    });

    window.addEventListener('click', this.startTimer);
    window.addEventListener('mousemove', this.startTimer);
  }

  async initialLoadingDone(forceReload = false) {
    this.monitorLogoutTrigger();
    runInAction(() => { this.initialLoading = false; });
    let lastUpdated = null;
    if (this.lastUpdated === null) {
      // eslint-disable-next-line no-param-reassign
      forceReload = true;
    } else {
      lastUpdated = moment.utc(this.lastUpdated, 'x').format('YYYY-MM-DDTHH:00:00');
      if (lastUpdated === 'Invalid date') {
        lastUpdated = null;
        // eslint-disable-next-line no-param-reassign
        forceReload = true;
      }
    }
    await this.loadVersionData();
    await topicStore.loadData(forceReload, lastUpdated);
    await contentTypeStore.loadData(forceReload, lastUpdated);
    if (!this.isDistributor) calendarStore.loadData(forceReload, lastUpdated);
    await languageStore.loadData(forceReload, lastUpdated);
    await countryStore.loadData(forceReload, lastUpdated);
    await productStore.loadData(forceReload, lastUpdated);
    await roleStore.loadData(forceReload, lastUpdated);
    await presentationStore.loadData(forceReload, lastUpdated);
    if (forceReload) {
      database.action(async () => {
        const downloadCollection = database.collections.get('materialDownload');
        const materialDownloads = await downloadCollection.query().fetch();
        const dbActions = [];
        materialDownloads.forEach((materialDownload) => {
          dbActions.push(
            downloadCollection.prepareCreate((newEntry) => {
              // eslint-disable-next-line no-underscore-dangle
              newEntry._raw = materialDownload._raw;
            })
          );
        });
        await database.unsafeResetDatabase();
        // eslint-disable-next-line no-underscore-dangle
        database._resetCount = 0;
        await database.batch(...dbActions);
      });
    }
    await this.updateWatermelonDatabase(forceReload);
    console.log('Finished loading data.');
    runInAction(() => {
      this.initialLoading = true;
      this.lastUpdated = Date.now();
    });
  }

  async updateWatermelonDatabase(forceReload = false) {
    try {
      await synchronize({
        database,
        pullChanges: async ({ lastPulledAt }) => {
          const since = (!forceReload && lastPulledAt !== null) ? `&since=${lastPulledAt}` : '';
          const response = await rest.get(`${config.restEndpoint}/full?modules=${modules.join('')}${since}`);
          return response.data;
        },
        pushChanges: () => {}
      });
    } catch (error) {
      console.log(error);
    }
  }

  @action setCurrentlyPresenting = (material) => {
    if (material !== null) {
      materialStore.addCurrent(material.uid);
      setScreenOrientationAll();
    } else {
      setScreenOrientationLandscape();
    }
    this.currentlyPresenting = material;
  }

  @action startSharing = (materialUid) => {
    this.sharingMode = true;
    this.sharingOpen = true;
    if (materialStore.sharingMaterials.indexOf(materialUid) === -1) {
      materialStore.sharingMaterials.push(materialUid);
    }
    materialStore.sharingMaterialsCache = materialStore.sharingMaterials.slice(0);
  }

  @action cancelSharing = () => {
    this.sharingMode = false;
    this.sharingOpen = false;
    materialStore.sharingMaterials = [];
    materialStore.sharingMaterialsCache = [];
  }

  @action startSelectMore = () => {
    materialStore.sharingMaterialsCache = materialStore.sharingMaterials.slice(0);
    this.sharingMode = true;
    this.sharingOpen = false;
  }

  @action cancelSelectMore = () => {
    materialStore.sharingMaterialsCache = materialStore.sharingMaterials.slice(0);
    this.sharingOpen = true;
    materialStore.updateCheckedTiles();
  }

  @action doneSelectMore = () => {
    materialStore.sharingMaterials = materialStore.sharingMaterialsCache.slice(0);
    this.sharingOpen = true;
  }

  @observable notifications = [];
  @action addNotification = (text) => {
    if (text.length) this.notifications.push({ message: text, created: Date.now() });
  }

  handleNotificationLifetime = () => {
    window.setInterval(() => {
      const now = Date.now();
      const list = toJS(this.notifications);
      list.map((n, key) => {
        if (n.created + 3000 < now) {
          runInAction(() => {
            this.notifications.remove(this.notifications[key]);
          });
        }
      });
    }, 300);
  }

  sendMail = (mailto, subject, message) => new Promise((resolve, reject) => {
    const toAddresses = [];
    let content = message.replace(/(?:\r\n|\r|\n)/g, '<br>');
    if (this.appUser.get('signature') !== null) {
      content += `<p>&nbsp;</p>
      <p>--<br />&nbsp;<br />${this.appUser.get('signature').replace(/(?:\r\n|\r|\n)/g, '<br>')}</p>`;
    }
    mailto.split(/[,;]+/).forEach((mail) => {
      if (mail.length > 0) {
        toAddresses.push({
          EmailAddress: {
            Address: mail.trim()
          }
        });
      }
    });
    const settings = {
      url: 'https://outlook.office.com/api/v2.0/me/sendmail',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      data: JSON.stringify({
        Message: {
          Subject: subject,
          Body: {
            ContentType: 'HTML',
            Content: content
          },
          ToRecipients: toAddresses,
        }
      })
    };
    Adal.adalRequest(settings)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        console.log('Mail Error', error);
        reject(error);
      });
  })

  sendPdfMailBase64 = (mailto, subject, msg, fileName, attachment) => new Promise((resolve, reject) => {
    const toAddresses = [
      {
        EmailAddress: {
          Address: mailto
        }
      }
    ];
    // the base64 pdf content contains a prefix which has to be removed to be valid for the outlook rest API
    const base64PdfData = attachment.replace('data:application/pdf;base64,', '');
    const attachmentData = [{
      '@odata.type': '#Microsoft.OutlookServices.FileAttachment',
      Name: fileName,
      ContentBytes: base64PdfData
    }];
    const messageContent = {
      url: 'https://outlook.office.com/api/v2.0/me/sendmail',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      data: JSON.stringify({
        Message: {
          Subject: subject,
          Body: {
            ContentType: 'Text',
            Content: msg
          },
          ToRecipients: toAddresses,
          Attachments: attachmentData
        },
      }),
      saveToSentItems: 'true'
    };

    Adal.adalRequest(messageContent)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        console.log('Mail Error', error);
        reject(error);
      });
  })

  sendPdfMailBlob = (mailto, subject, msg, fileName, attachment) => new Promise((resolve, reject) => {
    const toAddresses = [
      {
        EmailAddress: {
          Address: mailto
        }
      }
    ];
    const reader = new FileReader();
    reader.onloadend = function loaded() {
      // the base64 pdf content contains a prefix which has to be removed to be valid for the outlook rest API
      const base64PdfData = reader.result.replace('data:application/pdf;base64,', '');
      const attachmentData = [{
        '@odata.type': '#Microsoft.OutlookServices.FileAttachment',
        Name: fileName,
        ContentBytes: base64PdfData
      }];
      const messageContent = {
        url: 'https://outlook.office.com/api/v2.0/me/sendmail',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        data: JSON.stringify({
          Message: {
            Subject: subject,
            Body: {
              ContentType: 'Text',
              Content: msg
            },
            ToRecipients: toAddresses,
            Attachments: attachmentData
          },
        }),
        saveToSentItems: 'true'
      };

      Adal.adalRequest(messageContent)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          console.log('Mail Error', error);
          reject(error);
        });
    };
    reader.readAsDataURL(attachment);
  })

  // ----- DASHBOARD -----

  dashboardFullOptions = {
    news: 1,
    calendar: 2,
    materials: 3
  }

  // save which dashboard segment is in full view
  @observable dashboardFullView = null;

  @action setDashboardFullView = (newView = null) => {
    this.dashboardFullView = (newView === null || !Object.values(this.dashboardFullOptions).includes(newView)) ? null : newView;
  }

  @computed get dashboardAllOrNews() {
    return this.dashboardFullView === null || this.dashboardFullView === this.dashboardFullOptions.news;
  }

  @computed get dashboardAllOrCalendar() {
    return this.dashboardFullView === null || this.dashboardFullView === this.dashboardFullOptions.calendar;
  }

  @computed get dashboardAllOrMaterials() {
    return this.dashboardFullView === null || this.dashboardFullView === this.dashboardFullOptions.materials;
  }

  // ----- DASHBOARD -----
  triggerLicenseByMail = (licenseId) => {
    rest.post(cmtLicenseEmailRoute, { id: licenseId }).then((result) => {
      this.addNotification(result.data.msg);
    });
  }
}

export default new ApplicationStore();
