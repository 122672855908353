import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Q } from '@nozbe/watermelondb';
import { toJS } from 'mobx';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import database from 'shared/stores/database/database';
import { countryStore } from 'shared/stores';
import { simappConstants } from 'shared/lib/theme';
import simappColors from 'shared/lib/theme/simapp-colors';
import { hasIntersection, escapeRegExpInput } from 'shared/lib/helpers';
import EnhancedTable from './enhanced-table';

const styles = theme => ({
  root: {
    height: '100vH',
    width: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: simappConstants.contentMarginLarge
  },
  title: {
    marginBottom: theme.spacing.unit * 3,
  },
  searchField: {
    margin: '0.5rem 1rem 1rem 1rem',
    borderBottom: `1px solid ${simappColors.paleGrey}`
  }
});

const columns = [
  // { id: 'id', disablePadding: false, label: 'ID' },
  { id: 'name', disablePadding: false, label: 'User\'s Name' },
  { id: 'email', disablePadding: false, label: 'Email' },
  { id: 'mobilePhone', disablePadding: false, label: 'Phone Number' },
  { id: 'userGroup', disablePadding: false, label: 'Subgroup' },
  { id: 'country', disablePadding: false, label: 'Country' },
];

const UserList = (props) => {
  const { classes } = props;

  const [users, setUsers] = useState([]);
  const [searchString, setsearchString] = useState('');
  const [countryIds, setCountryIds] = useState([]);
  const r = new RegExp(escapeRegExpInput(searchString), 'i');
  useEffect(() => {
    const allCountryIds = toJS(countryStore.countries.map(c => c.id));
    // Grab groups and add info to every user if can create licenses or credentials, don't show action button if not
    database.collections.get('userGroup').query(Q.where('is_deactivated', false)).fetch().then((userGroups) => {
      database.collections.get('cmtUser').query().fetch().then((userList) => {
        setUsers(userList.map(u => ({
          ...u.getFlat(),
          countryIds: u.localAuth ? u.countryIds : allCountryIds,
          alert: u.unused,
          can_create_credentials: !u.unused && userGroups.filter(uG => u.groupIds.includes(uG.bId)).some(uG => uG.permissionList.includes('cmt_create_credentials')),
          can_create_license: !u.unused && userGroups.filter(uG => u.groupIds.includes(uG.bId)).some(uG => uG.permissionList.includes('cmt_create_license')),
        })));
      });
    });
  }, []);

  const onChange = (e) => {
    setsearchString(e.target.value);
    setCountryIds(countryStore.search(e.target.value));
  };

  return (
    <div className={classes.root}>
      <Typography variant="h5" id="tableTitle" className={classes.title}>User List</Typography>
      <Paper>
        <Input
          id="search"
          value={searchString}
          onChange={onChange}
          disableUnderline
          autoComplete="off"
          placeholder="Search..."
          className={classes.searchField}
          inputProps={{ maxLength: '200' }}
        />
        <EnhancedTable
          showUserActions
          columns={columns}
          data={!searchString.length ? users : users.filter(u => (
            hasIntersection(u.countryIds, countryIds)
            || r.test(u.id)
            || r.test(u.name)
            || r.test(u.email)
            || r.test(u.mobilePhone)
            || r.test(u.userGroup)
          ))}
        />
      </Paper>
    </div>
  );
};

UserList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(UserList);
