import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import CssBaseline from '@material-ui/core/CssBaseline';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import PropTypes from 'prop-types';
import MainRouter from 'web/components/main-router';
import LoginRouter from 'web/components/login-router';
import Sharing from 'web/components/sharing/sharing';
import Blank from 'web/components/_misc/blank';
import NoBackend from 'web/components/_misc/no-backend';
import Notifications from 'web/components/_misc/notifications';
import simappTheme from 'web/theme/simapp-theme';

@inject('applicationStore') @observer
class App extends Component {
  render() {
    if (!this.props.applicationStore.backendHealth) {
      return (
        <MuiThemeProvider theme={simappTheme}>
          <CssBaseline />
          <NoBackend />
        </MuiThemeProvider>
      );
    }
    if (this.props.applicationStore.isAuthenticated === false) {
      return (
        <MuiThemeProvider theme={simappTheme}>
          <CssBaseline />
          <Notifications />
          <LoginRouter />
        </MuiThemeProvider>
      );
    } if (this.props.applicationStore.isAuthenticated === true && (this.props.applicationStore.initialLoading || this.props.applicationStore.newUser)) {
      return (
        <MuiThemeProvider theme={simappTheme}>
          <CssBaseline />
          <Notifications />
          <Sharing />
          <MainRouter />
        </MuiThemeProvider>
      );
    }
    return (
      <MuiThemeProvider theme={simappTheme}>
        <CssBaseline />
        <Blank />
      </MuiThemeProvider>
    );
  }
}

App.wrappedComponent.propTypes = {
  applicationStore: PropTypes.object.isRequired,
};

export default App;
