import heConstants from './he-constants';

const copeptinConstants = {
  DefaultLanguageCode: 'en',
  LanguageCodeEnglish: 'en',
  LanguageCodeGerman: 'de',
  LanguageCodeFrench: 'fr',
  LanguageCodeSpanish: 'es',
  LanguageCodeUSA: 'us',
  CopeptinTableRowCurrency: heConstants.HeTableRowCurrency,
  CopeptinTableRowPercentage: heConstants.HeTableRowPercentage,
  CopeptinTableRowTime: heConstants.HeTableRowTime,
  CopeptinPopulationField: 'firstMenuField',
  CopeptinStaffTimesDoctorValue: 'CopeptinStaffTimesDoctorValue',
  CopeptinStaffTimesNursingValue: 'CopeptinStaffTimesNursingValue',
  CopeptinMedicalCheckCostsCostsValue: 'CopeptinMedicalCheckCostsCostsValue',
  CopeptinMedicationCostsCopeptinValue: 'CopeptinMedicationCostsCopeptinValue',
  CopeptinMedicationCostsDefaultValue: 'CopeptinMedicationCostsDefaultValue',
  CopeptinPatientProportionCopeptinValue: 'CopeptinPatientProportionCopeptinValue',
  CopeptinPatientProportionDefaultValue: 'CopeptinPatientProportionDefaultValue',
  CopeptinTotalNumberPatientsTotalValue: 'CopeptinTotalNumberPatientsTotalValue',
  CopeptinTotalNumberPatientsPercentageValue: 'CopeptinTotalNumberPatientsPercentageValue'
};

export default copeptinConstants;
