import { action, observable, toJS } from 'mobx';
import { applicationStore } from 'shared/stores';
import moment from 'moment';
import rest from 'shared/lib/rest';
import BaseForm from './base-form';

class CmtCredentialsForm extends BaseForm {
  @observable devModeAllowed = false;
  @observable cmtUsername = '';
  @observable password;
  @observable barcode;
  lastUsedParameters = {};
  saveButtonUsesPristine = true;

  @observable
  fields = {
    user_id: {
      value: '',
      error: null,
      disabled: true,
      label: 'User',
      rule: 'required'
    },
    level_id: {
      value: '',
      error: null,
      rule: 'required',
      required: true,
      label: 'Access Level',
      placeholder: 'Select a level',
      unavailableInfo: 'Select a product first'
    },
    date: {
      value: '',
      error: null,
      rule: 'required',
      required: true,
      label: 'Date',
      placeholder: ''
    },
    product: {
      value: '',
      error: null,
      rule: 'required',
      required: true,
      label: 'Product',
      placeholder: 'Select a product'
    },
    send_sms: {
      value: false,
      error: null,
      rule: 'boolean',
      label: 'Send generated password to my mobile phone',
    },
  }

  @action setDefaultValues = () => {
    if (this.fields.user_id.value.length === 0) {
      this.fields.user_id.value = applicationStore.appUser.get('id');
      this.cmtUsername = applicationStore.appUser.get('cmt_username');
    }
    this.password = null;
    this.barcode = null;
    this.lastUsedParameters = {};
    this.fields.product.value = null;
    this.fields.level_id.value = null;
    this.fields.send_sms.value = false;
    this.fields.date.value = moment().format(moment.HTML5_FMT.DATE);
    this.onFieldChange({
      target: {
        id: 'date',
        value: this.fields.date.value,
      }
    });
    this.pristine = true;
  }

  @action clearValues = () => {
    const copy = new CmtCredentialsForm();
    this.fields = toJS(copy.fields);
    this.password = null;
    this.barcode = null;
    this.lastUsedParameters = {};
    this.cmtUsername = '';
    this.pristine = true;
  }

  @action create = () => {
    // eslint-disable-next-line guard-for-in
    for (const field in this.fields) {
      this.onFieldChange({
        target: {
          id: field,
          value: this.fields[field].value,
        } });
    }
    if (!this.isValid) return;
    this.saveInProgess = true;
    const data = {
      product: this.fields.product.value,
      level_id: this.fields.level_id.value,
      send_sms: this.fields.send_sms.value
    };
    if (this.devModeAllowed) {
      data.date = this.fields.date.value;
    }
    if (this.fields.user_id.value !== applicationStore.appUser.get('id')) {
      data.user_id = this.fields.user_id.value;
    }
    rest.post('/cmt_credentials', data).then((result) => {
      applicationStore.addNotification(result.data.msg);
      if (result.data.success) {
        this.lastUsedParameters = {
          product: this.fields.product.value,
          level_id: this.fields.level_id.value,
          date: moment(this.fields.date.value).toDate(),
        };
        this.password = result.data.credentials;
        this.barcode = result.data.barcode;
        // update eol of credentials, but these are only returned when credentials are for the current user
        if (result.data.eol) {
          applicationStore.appUser.set(data.product === 'kryptor' ? 'eol_cmt_credentials_kryptor' : 'eol_cmt_credentials_fastscreen', result.data.eol);
        }
        // this.setDefaultValues();
      }
    }).catch((e) => {
      applicationStore.addNotification(e.response.data.msg);
      console.log(e.response, e.request, e.message, e.config);
    }).finally(() => {
      this.saveInProgess = false;
    });
  }
}

export default new CmtCredentialsForm();
