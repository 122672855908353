import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { simappColors } from 'shared/lib/theme';
import { routing } from 'shared/stores';
import ButtonBase from '@material-ui/core/ButtonBase';

const styles = {
  paper: {
    position: 'relative',
    display: 'block',
    width: 500,
    margin: 20,
    marginBotton: 20,
    overflow: 'hidden',
    border: `1px solid ${simappColors.paleGrey}`,
    padding: '15px',
    backgroundColor: '#fff',
    borderRadius: 4
  },
  headRow: {
    display: 'flex',
    flexShrink: 0,
    marginTop: '10px',
    marginBottom: '12px',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '28px',
    lineHeight: '40px'
  },
  button: {
    marginLeft: '5px',
    marginRight: '5px',
    marginBottom: '5px',
    color: '#fff',
    backgroundColor: simappColors.coolGrey,
    height: 56,
    lineHeight: 3,
    fontSize: 17,
    borderRadius: 4,
  }
};

const ServiceTile = (props) => {
  const navigate = () => {
    routing.push(props.path);
  };

  const { classes } = props;
  return (
    <ButtonBase className={classes.paper} onClick={navigate}>
      <div className={classes.headRow}>
        {props.children}
      </div>
      <Typography className={classes.button}>{props.title}</Typography>
    </ButtonBase>
  );
};

ServiceTile.propTypes = {
  classes: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node
};

ServiceTile.defaultProps = {
  children: null
};

export default withStyles(styles)(ServiceTile);
