import config from 'shared/config';

export const objToMap = (obj, map) => {
  Object.keys(obj).forEach((key) => {
    map.set(key, obj[key]);
  });
};

export const mapToObj = (map, obj) => {
  map.forEach((value, key) => {
    obj[key] = value;
  });
};

export const mapToNewObj = (map) => {
  const obj = {};
  mapToObj(map, obj);
  return obj;
};

export const capitalize = (string) => {
  if (typeof string !== 'string') return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 *
 * @param {@observable array} target
 * @param {array} data to be merged into target
 * @param {string} sortBy
 * @param {ASC|DESC} sortDir
 */
export const merge = (target, data, mergeBy = 'id', sortBy = null, sortDir = 'ASC') => {
  if (target.length === 0) {
    // just make sure that there is nothing deleted
    data.forEach((entry, index) => {
      if (entry.deleted) {
        delete data[index];
      }
    });
    target.replace(data);
  } else {
    data.forEach((entry) => {
      const index = target.findIndex(t => t[mergeBy] === entry[mergeBy]);
      // found; update or delete
      if (index >= 0) {
        if (entry.deleted) {
          target.splice(index, 1);
        } else {
          target[index] = entry;
        }
      // not found; append
      } else if (!entry.deleted) {
        target.push(entry);
      }
    });
  }

  // remove all falsy entries
  // no idea why this should happen, but it did at least once
  target.replace(target.filter(item => item));

  if (sortBy !== null) {
    const sort1 = sortDir === 'ASC' ? -1 : 1;
    const sort2 = -1 * sort1;
    target.replace(target.slice().sort((a, b) => {
      if (a[sortBy] < b[sortBy]) { return sort1; }
      if (a[sortBy] > b[sortBy]) { return sort2; }
      return 0;
    }));
  }
};

export const sort = (target, sortBy, sortDir = 'ASC') => {
  const sort1 = sortDir === 'ASC' ? -1 : 1;
  const sort2 = -1 * sort1;
  return target.slice().sort((a, b) => {
    if (a[sortBy] < b[sortBy]) { return sort1; }
    if (a[sortBy] > b[sortBy]) { return sort2; }
    return 0;
  });
};

export const sortInArray = (target, sortBy, sortDir = 'ASC') => {
  const sort1 = sortDir === 'ASC' ? -1 : 1;
  const sort2 = -1 * sort1;
  target.sort((a, b) => {
    if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) { return sort1; }
    if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) { return sort2; }
    return 0;
  });
};

// just removes the "app" part from the backend API, since we need the browser part in some areas
export const getApiBase = () => config.restEndpoint.replace(/\/app$/, '');

// checks if two array have at least one overlapping item
export const hasIntersection = (arr1, arr2) => arr1.some(a1 => arr2.includes(a1));

export const escapeRegExpInput = (input) => {
  const source = typeof input === 'string' || input instanceof String ? input : '';
  return source.replace(/[-[/\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};
