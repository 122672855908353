import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Q } from '@nozbe/watermelondb';
import { pick } from 'lodash';
import database from 'shared/stores/database/database';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import ResetIcon from '@material-ui/icons/RotateLeft';
import { applicationStore, routing, countryStore } from 'shared/stores';
import { products } from 'shared/stores/forms/cmt-license-form';
import { simappConstants } from 'shared/lib/theme';
import simappColors from 'shared/lib/theme/simapp-colors';
import { escapeRegExpInput } from 'shared/lib/helpers';
import EnhancedTable from './enhanced-table';

const styles = theme => ({
  root: {
    height: '100vH',
    width: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: simappConstants.contentMarginLarge
  },
  title: {
    marginBottom: theme.spacing.unit * 3,
  },
  searchArea: {
    display: 'flex',
    marginBottom: '2rem'
  },
  searchAreaLeft: {
    maxWidth: 850,
    paddingTop: '2.4rem'
  },
  searchAreaMiddle: {
    margin: '1rem 1rem 0 1rem'
  },
  searchAreaRight: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginRight: '1rem',
    paddingTop: '2.8rem',
    maxWidth: 200,
    '& button': {
      marginBottom: '1.5rem',
    }
  },
  searchField: {
  },
  formControl: {
    margin: '0.5rem 1rem 1rem 1rem',
    width: 250,
  },
  selectLabel: {
    transform: 'none',
    top: 8,
    left: 8,
    fontWeight: 400,
    color: simappColors.placeholderGrey
  },
  select: {
    margin: '0 !important',
  },
  dateTitle: {
    fontSize: '1rem'
  },
  dateWrapper: {
    margin: '0.5rem 1rem 1.7rem 0rem',
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }
});

const columns = [
  { id: 'id', disablePadding: false, label: 'ID' },
  { id: 'institution', disablePadding: false, label: 'Institution Name' },
  { id: 'city', disablePadding: false, label: 'City' },
  { id: 'country', disablePadding: false, label: 'Country' },
  { id: 'groupName', disablePadding: false, label: 'Company' },
  { id: 'serialNumbers', disablePadding: false, label: 'Instrument(s) Serial Number(s)' },
  { id: 'productName', disablePadding: false, label: 'Product' },
  { id: 'licenseType', disablePadding: false, label: 'License Type' },
  { id: 'expiresAtF', disablePadding: false, label: 'Expiration Date' },
];

const licenseTypes = { Demo: 'Demo', Final: 'Final' };

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      border: `1px solid ${simappColors.placeholderGrey}`
    },
  },
};

const LicenseList = (props) => {
  const { classes } = props;

  const [allowedProducts, setAllowedProducts] = useState([]);
  const [licenses, setLicenses] = useState([]);
  const [searchInstitution, setSearchInstitution] = useState('');
  const [searchCity, setSearchCity] = useState('');
  const [searchGroup, setSearchGroup] = useState('');
  const [searchSerialNumber, setSearchSerialNumber] = useState('');
  const [searchProducts, setSearchProducts] = useState([]);
  const [searchTypes, setSearchTypes] = useState([]);
  const [searchCountries, setSearchCountries] = useState([]);
  const [countries, setCountries] = useState({});
  const [searchFrom, setSearchFrom] = useState('');
  const [searchTo, setSearchTo] = useState('');
  const [searchFromObj, setSearchFromObj] = useState('');
  const [searchToObj, setSearchToObj] = useState('');
  const institutionRegex = new RegExp(escapeRegExpInput(searchInstitution), 'i');
  const cityRegex = new RegExp(escapeRegExpInput(searchCity), 'i');
  const groupRegex = new RegExp(escapeRegExpInput(searchGroup), 'i');
  const serialNumberRegex = new RegExp(escapeRegExpInput(searchSerialNumber), 'i');

  useEffect(() => {
    database.collections.get('cmtLicense').query().fetch().then((licenseList) => {
      database.collections.get('userGroup').query(Q.where('is_deactivated', false)).fetch().then((uG) => {
        const userGroups = {};
        for (const i of uG) {
          userGroups[i.bId] = i.name;
        }
        const now = new Date();
        setLicenses(licenseList.map((l) => {
          const row = l.getFlat();
          row.licenseType = row.final ? 'Final' : 'Demo';
          row.productName = products[row.product];
          row.alert = row.expiresAt && row.expiresAt < now;
          row.expiresAtF = row.expiresAt ? row.expiresAt.toLocaleDateString() : '';
          row.serialNumbers = [row.kryptorCompactPlus, row.kryptorGold].filter(n => n.length > 0).join(',');
          row.groupName = userGroups[row.userGroupId];
          return row;
        }));
      });
    });
    setCountries(Object.assign({}, ...(countryStore.countries.map(c => ({ [c.id]: c.name })))));
    const tmpProducts = [];
    if (applicationStore.appUser.get('cmt_trained_kryptor')) {
      tmpProducts.push('kryptor');
    }
    if (applicationStore.appUser.get('cmt_trained_fastscreen')) {
      tmpProducts.push('fastscreenplus');
    }
    setAllowedProducts(tmpProducts);
  }, []);

  const resetSearch = () => {
    setSearchInstitution('');
    setSearchCity('');
    setSearchGroup('');
    setSearchProducts([]);
    setSearchSerialNumber('');
    setSearchTypes([]);
    setSearchCountries([]);
    setCountries({});
    setSearchFrom('');
    setSearchTo('');
    setSearchFromObj('');
    setSearchToObj('');
  };

  return (
    <div className={classes.root}>
      <Typography variant="h5" id="tableTitle" className={classes.title}>License List</Typography>
      <Paper>
        <div className={classes.searchArea}>
          <div className={classes.searchAreaLeft}>
            <FormControl className={classes.formControl}>
              <Input
                id="searchInstitution"
                value={searchInstitution}
                onChange={e => setSearchInstitution(e.target.value)}
                autoComplete="off"
                placeholder="Institution Name"
                className={classes.searchField}
                inputProps={{ maxLength: '100' }}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <Input
                id="searchCity"
                value={searchCity}
                onChange={e => setSearchCity(e.target.value)}
                autoComplete="off"
                placeholder="City"
                className={classes.searchField}
                inputProps={{ maxLength: '25' }}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              {!searchCountries.length && (
                <InputLabel className={classes.selectLabel}>Country</InputLabel>
              )}
              <Select
                className={classes.select}
                multiple
                value={searchCountries}
                onChange={e => setSearchCountries(e.target.value)}
                input={<Input />}
                renderValue={selected => Object.values(pick(countries, selected)).join(', ')}
                MenuProps={MenuProps}
              >
                {Object.entries(countries).map(p => (
                  <MenuItem key={p[0]} value={p[0]}>
                    <Checkbox color="primary" checked={searchCountries.includes(p[0])} />
                    <ListItemText primary={p[1]} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <Input
                id="searchSerialNumber"
                value={searchSerialNumber}
                onChange={e => setSearchSerialNumber(e.target.value)}
                autoComplete="off"
                placeholder="Instrument Serial Number"
                className={classes.searchField}
                inputProps={{ maxLength: '6' }}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <Input
                id="searchGroup"
                value={searchGroup}
                onChange={e => setSearchGroup(e.target.value)}
                autoComplete="off"
                placeholder="Company"
                className={classes.searchField}
                inputProps={{ maxLength: '100' }}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              {!searchProducts.length && (
                <InputLabel className={classes.selectLabel}>Product</InputLabel>
              )}
              <Select
                className={classes.select}
                multiple
                value={searchProducts}
                onChange={e => setSearchProducts(e.target.value)}
                input={<Input />}
                renderValue={selected => Object.values(pick(products, selected)).join(', ')}
                MenuProps={MenuProps}
              >
                {Object.entries(products).map(p => (
                  <MenuItem key={p[0]} value={p[0]}>
                    <Checkbox color="primary" checked={searchProducts.includes(p[0])} />
                    <ListItemText primary={p[1]} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              {!searchTypes.length && (
                <InputLabel className={classes.selectLabel}>License Type</InputLabel>
              )}
              <Select
                className={classes.select}
                multiple
                value={searchTypes}
                onChange={e => setSearchTypes(e.target.value)}
                input={<Input />}
                renderValue={selected => Object.values(pick(licenseTypes, selected)).join(', ')}
                MenuProps={MenuProps}
              >
                {Object.entries(licenseTypes).map(p => (
                  <MenuItem key={p[0]} value={p[0]}>
                    <Checkbox color="primary" checked={searchTypes.includes(p[0])} />
                    <ListItemText primary={p[1]} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className={classes.searchAreaMiddle}>
            <Typography className={classes.dateTitle}>Expiration Date</Typography>
            <div className={classes.dateWrapper}>
              <Typography>From</Typography>
              <Input
                type="date"
                value={searchFrom}
                onChange={(e) => { setSearchFrom(e.target.value); setSearchFromObj(new Date(e.target.value)); }}
                placeholder="From"
              />
            </div>
            <div className={classes.dateWrapper}>
              <Typography>To</Typography>
              <Input
                type="date"
                value={searchTo}
                onChange={(e) => { setSearchTo(e.target.value); setSearchToObj(new Date(e.target.value)); }}
                placeholder="To"
              />
            </div>
          </div>
          <div className={classes.searchAreaRight}>
            { allowedProducts.length > 0 && (
              <Button variant="contained" color="primary" onClick={() => routing.push('/cmt/license')}>New License</Button>
            )}
            <Button variant="contained" color="default" onClick={resetSearch}>
              Reset
              <ResetIcon />
            </Button>
          </div>
        </div>
        <EnhancedTable
          columns={columns}
          data={licenses.filter(l => institutionRegex.test(l.institution)
            && cityRegex.test(l.city)
            && groupRegex.test(l.groupName)
            && serialNumberRegex.test(l.serialNumbers)
            && (!searchProducts.length || searchProducts.includes(l.product))
            && (!searchTypes.length || searchTypes.includes(l.licenseType))
            && (!searchCountries.length || searchCountries.includes(`${l.countryId}`))
            && (!searchFromObj || Number.isNaN(searchFromObj) || searchFromObj <= l.expiresAt)
            && (!searchToObj || Number.isNaN(searchToObj) || searchToObj >= l.expiresAt))}
          showLicenseActions
          allowedProducts={allowedProducts}
        />
      </Paper>
    </div>
  );
};

LicenseList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LicenseList);
