import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Q } from '@nozbe/watermelondb';
import { applicationStore, routing, cmtCredentialsForm } from 'shared/stores';
import database from 'shared/stores/database/database';
import { simappConstants } from 'shared/lib/theme';
import InputDateTime from 'web/components/_ui/form-elements/input-date-time';
import SelectField from 'web/components/_ui/form-elements/select-field';
import SwitchField from 'web/components/_ui/form-elements/switch-field';
import CancelButton from 'web/components/_ui/form-elements/cancel-button';
import SaveButton from 'web/components/_ui/form-elements/save-button';

const styles = theme => ({
  root: {
    height: '100vH',
    width: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: simappConstants.contentMarginLarge
  },
  title: {
    marginBottom: theme.spacing.unit * 3,
  },
  gridContainer: {
    alignItems: 'stretch'
  },
  gridItem: {
    display: 'flex'
  },
  paper: {
    width: '100%'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.5em',
  },
  labelWrapper: {
    display: 'flex',
    position: 'relative',
    height: 32,
    width: '25%',
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '32px'
  },
  username: {
    flex: 1,
    fontSize: '1rem',
    padding: '6px 6px 7px'
  },
  buttonArea: {
    padding: '12px 24px'
  },
  resultWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: '1rem'
  },
  resultRow: {
    fontSize: '1.25rem'
  }
});

const CreateCredentials = observer((props) => {
  const { classes, match } = props;
  const { fields, onFieldChange } = cmtCredentialsForm;

  const [products, setProducts] = useState([]);
  const [levels, setLevels] = useState({ kryptor: [], fastscreenplus: [] });

  useEffect(() => {
    const setProductsForUser = (kryptor, fastscreen) => {
      const tmpProducts = [];
      if (kryptor) {
        tmpProducts.push({ id: 'kryptor', value: 'Kryptor SW v11 / Chat LIS' });
      }
      if (fastscreen) {
        tmpProducts.push({ id: 'fastscreenplus', value: 'Fast Screen PLUS' });
      }
      setProducts(tmpProducts);
    };
    const setLevelsForUser = (groupIds) => {
      database.collections.get('userGroup').query(Q.where('b_id', Q.oneOf(groupIds)), Q.where('module', 'cmt'), Q.where('is_deactivated', false)).fetch().then((userGroups) => {
        if (!userGroups.length) {
          console.error('No CMT groups are available!');
        } else {
          // if an admin is in more than one maingroup, we check all groups for the available levels
          cmtCredentialsForm.devModeAllowed = userGroups.some(uG => uG.permissionList.includes('cmt_create_dev_mode'));
          database.collections.get('cmtCredentialLevel').query().fetch().then((ls) => {
            setLevels({
              kryptor: ls.map(r => r.getFlat()).filter(r => userGroups.some(uG => uG.config.credential_levels.kryptor.includes(r.id))),
              fastscreenplus: ls.map(r => r.getFlat()).filter(r => userGroups.some(uG => uG.config.credential_levels.fastscreenplus.includes(r.id))),
            });
          });
        }
      });
    };
    cmtCredentialsForm.setDefaultValues();
    // if userId param is set, try to find the user and set in the form
    // if not found, redirect to base URL
    if (match.params.userId) {
      database.collections.get('cmtUser').query(Q.where('uid', match.params.userId)).fetch().then((users) => {
        if (!users.length || users[0].unused) {
          routing.push('/cmt/credentials');
        } else {
          cmtCredentialsForm.fields.user_id.value = users[0].bId;
          cmtCredentialsForm.cmtUsername = users[0].cmtUsername;
          setProductsForUser(users[0].cmtTrainedKryptor, users[0].cmtTrainedFastscreen);
          setLevelsForUser(users[0].groupIds);
        }
      });
    } else {
      // do it for current user
      setProductsForUser(applicationStore.appUser.get('cmt_trained_kryptor'), applicationStore.appUser.get('cmt_trained_fastscreen'));
      setLevelsForUser(applicationStore.appUser.get('group_ids'));
    }
  }, []);

  return (
    <div className={classes.root}>
      <Typography variant="h5" className={classes.title}>Credentials</Typography>
      <Grid container spacing={24} className={classes.gridContainer}>
        <Grid item xs={12} lg={6} className={classes.gridItem}>
          <Paper className={classes.paper}>
            <div className={classes.row}>
              <Typography className={classes.labelWrapper}>Login:</Typography>
              <Typography className={classes.username}>{cmtCredentialsForm.cmtUsername}</Typography>
            </div>
            <Divider light />
            <InputDateTime
              fields={fields}
              name="date"
              onChange={onFieldChange}
              type="date"
              disabled={!cmtCredentialsForm.devModeAllowed}
            />
            <Divider light />
            <SelectField
              fields={fields}
              name="product"
              list={products}
              currentValue={fields.product.value ? products.find(row => row.id === fields.product.value).value : ''}
              onChange={(id) => { onFieldChange({ target: { id: 'product', value: id } }); if (fields.level_id.value) onFieldChange({ target: { id: 'level_id', value: null } }); }}
            />
            <Divider light />
            <SelectField
              fields={fields}
              valueField="name"
              name="level_id"
              list={fields.product.value ? levels[fields.product.value] : []}
              currentValue={fields.level_id.value && fields.product.value ? levels[fields.product.value].find(row => row.id === fields.level_id.value).name : ''}
              onChange={(id) => { onFieldChange({ target: { id: 'level_id', value: id } }); }}
            />
            <Divider light />
            <SwitchField fields={fields} name="send_sms" onChange={onFieldChange} reversed />
            <DialogActions className={classes.buttonArea}>
              <CancelButton form={cmtCredentialsForm} onClick={cmtCredentialsForm.setDefaultValues} label="Clear" />
              <SaveButton form={cmtCredentialsForm} onClick={cmtCredentialsForm.create} label="Generate" />
            </DialogActions>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6} className={classes.gridItem}>
          <Paper className={`${classes.paper} ${classes.resultWrapper}`}>
            { cmtCredentialsForm.password && (
              <React.Fragment>
                <Typography variant="h4" gutterBottom>Selected parameters</Typography>
                <Typography className={classes.resultRow}><b>Login:</b> {cmtCredentialsForm.cmtUsername}</Typography>
                <Typography className={classes.resultRow}><b>Date:</b> {cmtCredentialsForm.lastUsedParameters.date.toLocaleDateString()}</Typography>
                <Typography className={classes.resultRow}><b>Product:</b> {products.find(row => row.id === cmtCredentialsForm.lastUsedParameters.product).value}</Typography>
                <Typography className={classes.resultRow}><b>Access Level:</b> {levels[cmtCredentialsForm.lastUsedParameters.product].find(row => row.id === cmtCredentialsForm.lastUsedParameters.level_id).name}</Typography>
                <Typography className={classes.resultRow} gutterBottom><b>Password:</b> {cmtCredentialsForm.password}</Typography>
                <div dangerouslySetInnerHTML={{ __html: cmtCredentialsForm.barcode }} />
              </React.Fragment>
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
});

CreateCredentials.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withStyles(styles)(CreateCredentials);
