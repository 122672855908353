import { action, reaction, observable, runInAction } from 'mobx';
import materialStore from 'shared/stores/material-store';
import Validator from 'validatorjs';
import BaseForm from './base-form';
//
class ShareForm extends BaseForm {
  @observable
  fields = {
    mailto: {
      value: '',
      error: null,
      label: 'Email',
      rule: ['required', 'regex:/^((([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))+[,;][ ]?)*(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$/'], // regex allows multiple email addresses, separated by comma or semicolon
      required: true
    },
    subject: {
      value: '',
      error: null,
      rule: 'required',
      required: true,
      label: 'Subject',
    },
    message: {
      value: '',
      error: null,
      label: 'Message',
      rule: 'string',
      multiline: true,
      required: true,
      vertical: true,
    },
    materialCount: {
      value: 0,
      rule: 'min:1',
      error: null,
    }
  }

  errors = {
    NO_MAIL_PERMISSION: 'NO_MAIL_PERMISSION',
    MAIL_SEND_ERROR: 'MAIL_SEND_ERROR'
  }

  @action setDefaultValues = () => {
    Object.keys(this.fields).forEach((key) => {
      if (!this.fields[key].value) {
        this.fields[key].value = '';
      }
    });
  }

  @action save = (e) => {
    e.preventDefault();
    this.saveInProgess = true;
    const data = {};
    Object.keys(this.fields).forEach((key) => {
      data[key] = this.fields[key].value;
    });
    return new Promise((resolve, reject) => {
      materialStore.sendSharingMail(data)
        .then(() => {
          this.setDefaultValues();
          resolve();
        })
        .catch((error) => {
          // happens only for old backend user with fewer permissions
          if (typeof error === 'object' && typeof error.error !== 'undefined' && error.error.code === 'ErrorAccessDenied') {
            this.setResponseError(this.errors.NO_MAIL_PERMISSION);
          } else {
            this.setResponseError(this.errors.MAIL_SEND_ERROR);
          }
          reject();
        }).finally(() => {
          runInAction(() => {
            this.saveInProgess = false;
            Object.keys(this.fields).forEach((key) => {
              this.fields[key].value = '';
            });
          });
        });
    });
  }

  @action onNativeFieldChange = (e) => {
    const field = e.target.id;
    this.fields[field].value = e.target.value.value;

    const validation = new Validator(
      this.getFlattenedValues('value'),
      this.getFlattenedValues('rule')
    );

    this.isValid = validation.passes();
  }

  // observe amount of selected materials
  // if it's 0 form must be invalid
  checkSharingMaterialCount = reaction(
    () => materialStore.sharingMaterialsCache.length,
    (data) => {
      this.fields.materialCount.value = data;
      this.onFieldChange({ target: { id: 'materialCount', value: data } });
    }
  );
}

export default new ShareForm();
