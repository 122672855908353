import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.5em',
  },
  gridContainer: {
  },
  innerGrid: {
    display: 'flex',
    flexDirection: 'column'
  },
  formControl: {
    padding: '0.5em'
  },
  labelWrapper: {
    width: '25%',
    display: 'flex',
    position: 'relative',
    height: 32
  },
  label: {
    position: 'static',
    transform: 'none',
    alignSelf: 'center',
    whiteSpace: 'nowrap',
    width: '100%'
  },
  label2: {
    position: 'static',
    transform: 'none',
    alignSelf: 'center',
    whiteSpace: 'nowrap',
    width: '100%',
    fontWeight: 400
  },
  errorMessage: {
    whiteSpace: 'nowrap',
    position: 'absolute',
    bottom: -6
  },
  inputField: {
    flex: 1,
    width: '80%'
  },
  inputErrorMessage: {
    color: '#f44336'
  }
};

const InputField = observer(({ fields, name, value, snId, onChange, classes }) => (
  <div style={{ position: 'relative', height: 48 }}>
    <Input
      id={`${snId}-input`}
      name={snId}
      value={fields[snId].value}
      onChange={(e) => { onChange({ target: { id: snId, value: e.target.value } }); }}
      placeholder={fields[snId].placeholder}
      className={classes.inputField}
      disabled={!fields[name].value.includes(value)}
    />
    <FormHelperText id={`${snId}-error`} className={classes.inputErrorMessage}>{fields[snId].error}</FormHelperText>
  </div>
));

const InnerRow = observer(({ fields, name, value, label, onChange, classes }) => (
  <div style={{ display: 'flex', flexDirection: 'row' }}>
    <Checkbox
      id={value}
      checked={fields[name].value.includes(value)}
      onChange={() => { onChange({ target: { id: name, value }, is_array: true }); }}
      color="primary"
    />
    <InputLabel htmlFor={value} disableAnimation shrink={false} required={false} className={classes.label2}>{label}</InputLabel>
  </div>
));

const LicenseInstrumentsField = observer((props) => {
  const { fields, name, onChange, classes } = props;
  return (
    <Fragment>
      <Grid container spacing={24} className={classes.gridContainer}>
        <Grid item xs={6} className={classes.innerGrid}>
          <FormControl
            error={!!fields[name].error}
            required={fields[name].required}
            className={classes.formControl}
          >
            <div className={props.classes.labelWrapper}>
              <InputLabel htmlFor={name} disableAnimation shrink={false} className={props.classes.label}>{fields[name].label}</InputLabel>
              <FormHelperText id={`${name}-error`} className={props.classes.errorMessage}>{fields[name].error}</FormHelperText>
            </div>
            {Object.entries(props.selection).map(s => (
              <InnerRow key={s[0]} fields={fields} name={name} value={s[0]} label={s[1].label} snId={s[0]} classes={classes} onChange={onChange} />
            ))}
          </FormControl>
        </Grid>
        <Grid item xs={6} className={classes.innerGrid}>
          <div className={classes.formControl}>
            <div className={props.classes.labelWrapper}>
              <InputLabel htmlFor={name} disableAnimation shrink={false} className={props.classes.label}>Instrument(s) Serial Number(s)</InputLabel>
              {/* <FormHelperText id={`${name}-error`} className={props.classes.errorMessage}>{fields[name].error}</FormHelperText> */}
            </div>
            {Object.entries(props.selection).map(s => (
              <InputField key={s[0]} fields={fields} name={name} value={s[0]} label={s[1].label} snId={s[0]} classes={classes} onChange={onChange} />
            ))}
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
});

LicenseInstrumentsField.propTypes = {
  classes: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  selection: PropTypes.object.isRequired,
};

LicenseInstrumentsField.defaultProps = {
  name: 'instruments',
};

export default withStyles(styles)(LicenseInstrumentsField);
