import { tableSchema } from '@nozbe/watermelondb';
import { field, date, json } from '@nozbe/watermelondb/decorators';
import Model from './simapp-model';

export const cmtUserSchema = tableSchema({
  name: 'cmtUser',
  columns: [
    { name: 'b_id', type: 'number', isIndexed: true },
    { name: 'uid', type: 'string', isIndexed: true },
    { name: 'name', type: 'string' },
    { name: 'cmt_username', type: 'string' },
    { name: 'email', type: 'string' },
    { name: 'mobile_phone', type: 'string' },
    { name: 'group_ids', type: 'string' },
    { name: 'country_ids', type: 'string' },
    { name: 'user_group', type: 'string' },
    { name: 'cmt_trained_kryptor', type: 'boolean' },
    { name: 'cmt_trained_fastscreen', type: 'boolean' },
    { name: 'local_auth', type: 'boolean' },
    { name: 'unused', type: 'boolean' },
    { name: 'b_created_at', type: 'number' },
    { name: 'b_updated_at', type: 'number' },
    { name: 'created_at', type: 'number' },
    { name: 'updated_at', type: 'number' }
  ]
});

export default class CmtUser extends Model {
  static table = 'cmtUser'

  @field('b_id') bId
  @field('uid') uid
  @field('name') name
  @field('cmt_username') cmtUsername
  @field('email') email
  @field('mobile_phone') mobilePhone
  @json('group_ids', jsonData => jsonData) groupIds
  @json('country_ids', jsonData => jsonData) countryIds
  @field('user_group') userGroup
  @field('cmt_trained_kryptor') cmtTrainedKryptor
  @field('cmt_trained_fastscreen') cmtTrainedFastscreen
  @field('local_auth') localAuth
  @field('unused') unused
  @date('b_created_at') bCreatedAt
  @date('b_updated_at') bUpdatedAt
  @date('created_at') createdAt
  @date('updated_at') updatedAt
}
