import { tableSchema } from '@nozbe/watermelondb';
import { field, date, json } from '@nozbe/watermelondb/decorators';
import Model from './simapp-model';

export const cmtLicenseSchema = tableSchema({
  name: 'cmtLicense',
  columns: [
    { name: 'b_id', type: 'number', isIndexed: true },
    { name: 'uid', type: 'string', isIndexed: true },
    { name: 'institution', type: 'string' },
    { name: 'city', type: 'string' },
    { name: 'country_id', type: 'number' },
    { name: 'kryptor_compact_plus', type: 'string' },
    { name: 'kryptor_gold', type: 'string' },
    { name: 'duration', type: 'number' },
    { name: 'installation_number', type: 'string' },
    { name: 'modules', type: 'string' },
    { name: 'purchase_order_number', type: 'string' },
    { name: 'price_agreement_filename', type: 'string' },
    { name: 'comment', type: 'string' },
    { name: 'user_id', type: 'number' },
    { name: 'user_group_id', type: 'number' },
    { name: 'product', type: 'string' },
    { name: 'license', type: 'string' },
    { name: 'license_filename', type: 'string' },
    { name: 'final', type: 'boolean' },
    { name: 'expires_at', type: 'number', isOptional: true },
    { name: 'b_created_at', type: 'number' },
    { name: 'b_updated_at', type: 'number' },
    { name: 'created_at', type: 'number' },
    { name: 'updated_at', type: 'number' }
  ]
});

export default class CmtLicense extends Model {
  static table = 'cmtLicense'

  @field('b_id') bId
  @field('uid') uid
  @field('institution') institution
  @field('city') city
  @field('country_id') countryId
  @field('kryptor_compact_plus') kryptorCompactPlus
  @field('kryptor_gold') kryptorGold
  @field('duration') duration
  @field('installation_number') installationNumber
  @json('modules', jsonData => jsonData) modules
  @field('purchase_order_number') purchaseOrderNumber
  @field('price_agreement_filename') priceAgreementFilename
  @field('comment') comment
  @field('user_id') userId
  @field('user_group_id') userGroupId
  @field('product') product
  @field('license') license
  @field('license_filename') licenseFilename
  @field('final') final
  @date('expires_at') expiresAt
  @date('b_created_at') bCreatedAt
  @date('b_updated_at') bUpdatedAt
  @date('created_at') createdAt
  @date('updated_at') updatedAt
}
