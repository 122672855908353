import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { simappColors } from 'shared/lib/theme';
import SimAppButton from 'web/components/_ui/simapp-button';
import { routing } from 'shared/stores';

const styles = {
  paper: {
    position: 'relative',
    width: 500,
    margin: 20,
    marginBotton: 20,
    overflow: 'hidden',
    border: `1px solid ${simappColors.paleGrey}`,
    padding: '15px'
  },
  headRow: {
    display: 'flex',
    flexShrink: 0,
    marginTop: '10px',
    marginBottom: '12px',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '28px',
    lineHeight: '40px'
  },
  image: {
    display: 'block',
    maxHeight: '120px',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  button: {
    marginLeft: '5px',
    marginRight: '5px',
    marginBottom: '5px'
  }
};

const ServiceTile = (props) => {
  const navigate = () => {
    routing.push(props.path);
  };
  const { classes } = props;
  const imagePath = `/images/${props.image}`;
  return (
    <Paper className={classes.paper} key={props.image}>
      <div className={classes.headRow}>
        { props.image.length > 0 && (
          <img src={imagePath} className={classes.image} alt={props.title} />
        )}
      </div>
      <SimAppButton className={classes.button} onClick={navigate} isNavigationButton inline>{props.title}</SimAppButton>
    </Paper>
  );
};

ServiceTile.propTypes = {
  classes: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string
};

ServiceTile.defaultProps = {
  image: ''
};

export default withStyles(styles)(ServiceTile);
