import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMore from '@material-ui/icons/ExpandMore';
import calendarDay from 'shared/helper/calendar-day';
import { simappColors } from 'shared/lib/theme';
import { newsStore } from 'shared/stores';

const styles = {
  newsHeading: {
    fontWeight: 500,
    marginBottom: '0.3em',
  },
  newsSecondaryHeading: {
    color: simappColors.steelGrey
  },
  newsContent: {
    whiteSpace: 'pre-wrap'
  },
  unreadIcon: {
    width: '2em',
    marginLeft: '-0.5em',
    marginRight: '0.5em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& span': {
      fontSize: '120%',
      color: simappColors.azul
    },
  },
};

const NewsItem = ({ news, currentId, classes }) => {
  const isExpanded = currentId === news.uid;
  return (
    <ExpansionPanel
      key={news.id}
      expanded={isExpanded}
      onChange={() => newsStore.updateExpandedNewsId(news.uid)}
    >
      <ExpansionPanelSummary expandIcon={<ExpandMore />}>
        <div className={classes.unreadIcon}>
          {!news.read && (
            <span>•</span>
          )}
        </div>
        <div className={classes.newsSummaryContent}>
          <Typography className={classes.newsHeading}>{news.title}</Typography>
          <Typography className={classes.newsSecondaryHeading}>{calendarDay(news.bCreatedAt, false)} &#183; {newsStore.getNewsType(news.type)}</Typography>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Typography className={classes.newsHeading}>{news.subtitle === 'null' ? '' : news.subtitle}</Typography>
        <Typography className={classes.newsContent}>{news.content}</Typography>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

NewsItem.propTypes = {
  classes: PropTypes.object.isRequired,
  news: PropTypes.object.isRequired,
  currentId: PropTypes.string
};

NewsItem.defaultProps = {
  currentId: null
};

export default withStyles(styles)(NewsItem);
