import { tableSchema } from '@nozbe/watermelondb';
import { field, date, json } from '@nozbe/watermelondb/decorators';
import Model from './simapp-model';

export const materialSchema = tableSchema({
  name: 'material',
  columns: [
    { name: 'b_id', type: 'number', isIndexed: true },
    { name: 'uid', type: 'string', isIndexed: true },
    { name: 'country_ids', type: 'string' },
    { name: 'content_type_id', type: 'string', isOptional: true },
    { name: 'description', type: 'string' },
    { name: 'original_filename', type: 'string' },
    { name: 'language_id', type: 'number' },
    { name: 'mastercontrol_id', type: 'string' },
    { name: 'product_ids', type: 'string' },
    { name: 'topic_ids', type: 'string' },
    { name: 'shareable', type: 'boolean' },
    { name: 'title', type: 'string' },
    { name: 'usage', type: 'string' },
    { name: 'recommended_country_ids', type: 'string', isOptional: true },
    { name: 'content_type', type: 'string' },
    { name: 'material_type', type: 'number' },
    { name: 'mimetype', type: 'string' },
    { name: 'favorite', type: 'boolean' },
    { name: 'updated_timestamp', type: 'number', isOptional: true },
    { name: 'thumbnail', type: 'string' },
    { name: 'document', type: 'string' },
    { name: 'keyword_ids', type: 'string' },
    { name: 'presentation_items', type: 'string', isOptional: true },
    { name: 'article_number', type: 'string', isOptional: true },
    { name: 'bibliography_number', type: 'string', isOptional: true },
    { name: 'author', type: 'string', isOptional: true },
    { name: 'year', type: 'number', isOptional: true },
    { name: 'published_in', type: 'string', isOptional: true },
    { name: 'publication_detail', type: 'string', isOptional: true },
    { name: 'open_access', type: 'boolean', isOptional: true },
    { name: 'reprint', type: 'boolean', isOptional: true },
    { name: 'filename', type: 'string', isOptional: true },
    { name: 'available_in_materials', type: 'boolean' },
    { name: 'available_in_software', type: 'boolean' },
    // { name: 'content_loaded', type: 'number', isOptional: true },
    { name: 'b_created_at', type: 'number' },
    { name: 'b_updated_at', type: 'number' },
    { name: 'created_at', type: 'number' },
    { name: 'updated_at', type: 'number' }
  ]
});

export default class Material extends Model {
  static table = 'material'

  @field('b_id') bId
  @field('uid') uid
  @json('country_ids', jsonData => jsonData) countryIds
  @field('content_type_id') contentTypeId
  @field('description') description
  @field('original_filename') originalFilename
  @field('language_id') languageId
  @field('mastercontrol_id') mastercontrolId
  @json('product_ids', jsonData => jsonData) productIds
  @json('topic_ids', jsonData => jsonData) topicIds
  @field('shareable') shareable
  @field('title') title
  @field('usage') usage
  @json('recommended_country_ids', jsonData => jsonData) recommendedCountryIds
  @field('content_type') contentType
  @field('material_type') materialType
  @field('mimetype') mimetype
  @field('favorite') favorite
  @date('updated_timestamp') updatedTimestamp
  @field('thumbnail') thumbnail
  @field('document') document
  @json('keyword_ids', jsonData => jsonData) keywordIds
  @field('presentation_items') presentationItems
  @field('article_number') articleNumber
  @field('bibliography_number') bibliographyNumber
  @field('author') author
  @field('year') year
  @field('published_in') publishedIn
  @field('publication_detail') publicationDetail
  @field('open_access') openAccess
  @field('reprint') reprint
  @field('filename') filename
  @field('available_in_materials') availableInMaterials
  @field('available_in_software') availableInSoftware
  // @date('content_loaded') contentLoaded
  @date('b_created_at') bCreatedAt
  @date('b_updated_at') bUpdatedAt
  @date('created_at') createdAt
  @date('updated_at') updatedAt
}
