import { observable, action, intercept } from 'mobx';
import { persist } from 'mobx-persist';
import copeptinConstants from 'shared/lib/resources/copeptin-constants';
import routing from './router-store';
import createStorage from './create-storage';

let jsonResourcesData = require('shared/lib/resources/copeptin-resources.json');

class CopeptinStore {
  constructor() {
    createStorage('copeptinStore', this).then(() => {
      const jsonResource = require('shared/lib/resources/copeptin-resources.json'); // eslint-disable-line global-require
      jsonResourcesData = jsonResource === undefined ? jsonResourcesData : jsonResource;
      this.jsonResources = jsonResourcesData;
    });
  }

  // ----- Copeptin Data -------

  @persist('object') jsonResources = jsonResourcesData;
  @persist('object') @observable jsonCalculatorData = {};
  @persist('object') @observable userCalculatorData = {};
  @persist('list') @observable staffTimesDoctor = [];
  @persist('list') @observable staffTimesNursing = [];
  @persist('list') @observable staffTimesTotal = [];
  @persist('list') @observable acuteSettingCosts = [];
  @persist('list') @observable acuteSettingCostsAllPatients = [];
  @persist @observable language = '';
  @observable logoName = 'BRAHMS_Copeptin.png';
  @observable diagramImageName = '';
  @observable infoImage = '';
  @observable infoTitle = '';
  @observable infoPageReturnLocation = '';
  referencesDiagramImageNameDE = 'References_Screen_Diagram_Image_DE.png';
  referencesDiagramImageNameEN = 'References_Screen_Diagram_Image_EN.png';
  referencesDiagramImageNameES = 'References_Screen_Diagram_Image_ES.png';
  referencesDiagramImageNameFR = 'References_Screen_Diagram_Image_FR.png';
  algorithmImageNameDE = 'Algorithm-DE.png';
  algorithmImageNameEN = 'Algorithm-EN.png';
  algorithmImageNameES = 'Algorithm-ES.png';
  algorithmImageNameFR = 'Algorithm-FR.png';

  onboardingImageName = 'BRAHMS_Copeptin.png';

  @action setCalculatorInformation = (infoImage, returnLocation) => {
    this.infoImage = infoImage;
    this.infoPageReturnLocation = returnLocation;
  }

  @action setStaffTimesDoctor = (object) => {
    this.staffTimesDoctor = object;
  }

  @action getInfoImageName = () => this.infoImage;

  @action setStaffTimesNursing = (object) => {
    this.staffTimesNursing = object;
  }

  @action setStaffTimesTotal = (object) => {
    this.staffTimesTotal = object;
  }

  @action setAcuteSettingCosts = (object) => {
    this.acuteSettingCosts = object;
  }

  @action setAcuteSettingCostsAllPatients = (object) => {
    this.acuteSettingCostsAllPatients = object;
  }

  @action setLanguage = (key) => {
    this.language = key;
  }

  @action readBIC8aResources = () => {
    this.jsonCalculatorData = this.jsonResources;
  }

  @action resetData = () => {
    this.jsonCalculatorData = {};
    this.userCalculatorData = {};
    this.staffTimesDoctor = [];
    this.staffTimesNursing = [];
    this.staffTimesTotal = [];
    this.acuteSettingCosts = [];
    this.acuteSettingCostsAllPatients = [];
    this.language = '';
    this.disableCopeptinLeavingAlert();
  }

  @action getLocalizedReferenceImage(language = this.language) {
    switch (language) {
      case 'de':
        return `/images/${this.referencesDiagramImageNameDE}`;
      case 'en':
        return `/images/${this.referencesDiagramImageNameEN}`;
      case 'es':
        return `/images/${this.referencesDiagramImageNameES}`;
      case 'fr':
        return `/images/${this.referencesDiagramImageNameFR}`;
      default:
        return `/images/${this.referencesDiagramImageNameEN}`;
    }
  }

  @action getLocalizedAlgorithmFileName() {
    switch (this.language) {
      case 'de':
        return `${this.algorithmImageNameDE}`;
      case 'en':
        return `${this.algorithmImageNameEN}`;
      case 'es':
        return `${this.algorithmImageNameES}`;
      case 'fr':
        return `${this.algorithmImageNameFR}`;
      default:
        return `${this.algorithmImageNameEN}`;
    }
  }

  @action getStaffTimesDoctorValue = (index) => {
    if (this.objectExists('staffTimes', index, 'doctor_value', false)) {
      return this.userCalculatorData.staffTimes[index].doctor_value;
    }
    if (this.objectExists('staffTimes', index, 'doctor_value', true)) {
      return this.jsonCalculatorData.staffTimes[index].doctor_value;
    }
    return undefined;
  }

  @action getStaffTimesNursingValue = (index) => {
    if (this.objectExists('staffTimes', index, 'nursing_value', false)) {
      return this.userCalculatorData.staffTimes[index].nursing_value;
    }
    if (this.objectExists('staffTimes', index, 'nursing_value', true)) {
      return this.jsonCalculatorData.staffTimes[index].nursing_value;
    }
    return undefined;
  }

  @action getMedicalCheckCostsCostsValue = (index) => {
    if (this.objectExists('medicalCheckCosts', index, 'costs_value', false)) {
      return this.userCalculatorData.medicalCheckCosts[index].costs_value;
    }
    if (this.objectExists('medicalCheckCosts', index, 'costs_value', true)) {
      return this.jsonCalculatorData.medicalCheckCosts[index].costs_value;
    }
    return undefined;
  }

  @action getMedicationCostsCostsValue = (index) => {
    if (this.objectExists('medicationCosts', index, 'costs_value', false)) {
      return this.userCalculatorData.medicationCosts[index].costs_value;
    }
    if (this.objectExists('medicationCosts', index, 'costs_value', true)) {
      return this.jsonCalculatorData.medicationCosts[index].costs_value;
    }
    return undefined;
  }

  @action getPatientProportionCopeptinValue = (index) => {
    if (this.objectExists('patientProportion', index, 'copeptin_value', false)) {
      return this.userCalculatorData.patientProportion[index].copeptin_value;
    }
    if (this.objectExists('patientProportion', index, 'copeptin_value', true)) {
      return this.jsonCalculatorData.patientProportion[index].copeptin_value;
    }
    return undefined;
  }

  @action getPatientProportionDefaultValue = (index) => {
    if (this.objectExists('patientProportion', index, 'default_value', false)) {
      return this.userCalculatorData.patientProportion[index].default_value;
    }
    if (this.objectExists('patientProportion', index, 'default_value', true)) {
      return this.jsonCalculatorData.patientProportion[index].default_value;
    }
    return undefined;
  }

  @action getTotalNumberPatientsTotalValue = (index) => {
    if (this.objectExists('totalNumberPatients', index, 'total_value', false)) {
      return this.userCalculatorData.totalNumberPatients[index].total_value;
    }
    if (this.objectExists('totalNumberPatients', index, 'total_value', true)) {
      return this.jsonCalculatorData.totalNumberPatients[index].total_value;
    }
    return undefined;
  }

  @action getTotalNumberPatientsPercentageValue = (index) => {
    if (this.objectExists('totalNumberPatients', index, 'percentage_value', false)) {
      return this.userCalculatorData.totalNumberPatients[index].percentage_value;
    }
    if (this.objectExists('totalNumberPatients', index, 'percentage_value', true)) {
      return this.jsonCalculatorData.totalNumberPatients[index].percentage_value;
    }
    return undefined;
  }

  objectExists = (key, index, itemKey, isDefault) => {
    const data = isDefault ? this.jsonCalculatorData : this.userCalculatorData;
    return data[key] !== undefined
      && data[key].length >= index + 1
      && data[key][index][itemKey] !== undefined;
  }

  @action setStaffTimesDoctorValue = (index, value) => {
    this.setValue('staffTimes', index, 'doctor_value', value);
  }

  @action setStaffTimesNursingValue = (index, value) => {
    this.setValue('staffTimes', index, 'nursing_value', value);
  }

  @action setMedicalCheckCostsCostsValue = (index, value) => {
    this.setValue('medicalCheckCosts', index, 'costs_value', value);
  }

  @action setMedicationCostsCostsValue = (index, value) => {
    this.setValue('medicationCosts', index, 'costs_value', value);
  }

  @action setPatientProportionCopeptinValue = (index, value) => {
    this.setValue('patientProportion', index, 'copeptin_value', value);
  }

  @action setPatientProportionDefaultValue = (index, value) => {
    this.setValue('patientProportion', index, 'default_value', value);
  }

  @action setTotalNumberPatientsTotalValue = (index, value) => {
    this.setValue('totalNumberPatients', index, 'total_value', value);
  }

  @action setTotalNumberPatientsPercentageValue = (index, value) => {
    this.setValue('totalNumberPatients', index, 'percentage_value', value);
  }

  @action setValue = (key, index, itemKey, value) => {
    if (this.userCalculatorData[key] === undefined) {
      this.userCalculatorData[key] = [];
    }
    while (this.userCalculatorData[key].length <= index) {
      this.userCalculatorData[key].push({});
    }
    this.userCalculatorData[key][index][itemKey] = value;
  }

  @action isUserStaffTimesDoctorValue = (index) => {
    if (!this.objectExists('staffTimes', index, 'doctor_value', false)) {
      return false;
    }
    if (this.userCalculatorData.staffTimes[index].doctor_value === this.jsonCalculatorData.staffTimes[index].doctor_value) {
      return false;
    }
    return true;
  }

  @action isUserStaffTimesNursingValue = (index) => {
    if (!this.objectExists('staffTimes', index, 'nursing_value', false)) {
      return false;
    }
    if (this.userCalculatorData.staffTimes[index].nursing_value === this.jsonCalculatorData.staffTimes[index].nursing_value) {
      return false;
    }
    return true;
  }

  @action isUserMedicalCheckCostsCostsValue = (index) => {
    if (!this.objectExists('medicalCheckCosts', index, 'costs_value', false)) {
      return false;
    }
    if (this.userCalculatorData.medicalCheckCosts[index].costs_value === this.jsonCalculatorData.medicalCheckCosts[index].costs_value) {
      return false;
    }
    return true;
  }

  @action isUserMedicationCostsCostsValue = (index) => {
    if (!this.objectExists('medicationCosts', index, 'costs_value', false)) {
      return false;
    }
    if (this.userCalculatorData.medicationCosts[index].costs_value === this.jsonCalculatorData.medicationCosts[index].costs_value) {
      return false;
    }
    return true;
  }

  @action isUserPatientProportionCopeptinValue = (index) => {
    if (!this.objectExists('patientProportion', index, 'copeptin_value', false)) {
      return false;
    }
    if (this.userCalculatorData.patientProportion[index].copeptin_value === this.jsonCalculatorData.patientProportion[index].copeptin_value) {
      return false;
    }
    return true;
  }

  @action isUserPatientProportionDefaultValue = (index) => {
    if (!this.objectExists('patientProportion', index, 'default_value', false)) {
      return false;
    }
    if (this.userCalculatorData.patientProportion[index].default_value === this.jsonCalculatorData.patientProportion[index].default_value) {
      return false;
    }
    return true;
  }

  @action isUserTotalNumberPatientsTotalValue = (index) => {
    if (!this.objectExists('totalNumberPatients', index, 'total_value', false)) {
      return false;
    }
    if (this.userCalculatorData.totalNumberPatients[index].total_value === this.jsonCalculatorData.totalNumberPatients[index].total_value) {
      return false;
    }
    return true;
  }

  @action isUserTotalNumberPatientsPercentageValue = (index) => {
    if (!this.objectExists('totalNumberPatients', index, 'percentage_value', false)) {
      return false;
    }
    if (this.userCalculatorData.totalNumberPatients[index].percentage_value === this.jsonCalculatorData.totalNumberPatients[index].percentage_value) {
      return false;
    }
    return true;
  }

  // ----- Copeptin Resources -----

  @action getLocalizedText = (key) => {
    let value = this.jsonResources.localized[key];
    if (value === undefined) {
      return value;
    }
    value = this.jsonResources.localized[key][this.language];
    if (value === undefined) {
      return this.jsonResources.localized[key][copeptinConstants.DefaultLanguageCode];
    }
    return value;
  }

  @action getText = (key) => {
    const value = this.jsonResources.unlocalized[key];
    if (value === undefined) {
      return this.getLocalizedText(key);
    }
    return value;
  }

  // ----- Copeptin Routing -----

  @observable isCopeptinLeavingAlert = false;
  @observable skipCopeptinLeavingCheck = false;
  @observable interceptedPathname = '';
  location = '';
  refreshScreenFunc = undefined;

  @action disableCopeptinLeavingAlert = (skip) => {
    this.isCopeptinLeavingAlert = false;
    this.skipCopeptinLeavingCheck = skip === undefined ? true : skip;
  }

  @action setLocation = (location = '') => {
    this.location = location;
  }

  handleCopeptinSetPresentingInChange(navigation) {
    const location = routing.location.pathname;
    if (location !== undefined && location.includes('/copeptin-calculator') && !location.endsWith('/copeptin-calculator')) {
      this.skipCopeptinLeavingCheck = true;
      try {
        routing.push('/he-models/copeptin-calculator');
      } catch {
        navigation.navigate('/he-models/copeptin-calculator');
      }
    } else if (location === '/he-models/copeptin-calculator' && this.refreshScreenFunc !== undefined) {
      this.refreshScreenFunc(location);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  isNavigatingBackToCopeptinStart(oldPathName, newPathName) {
    return oldPathName.includes('copeptin-calculator') && newPathName.endsWith('copeptin-calculator');
  }

  // eslint-disable-next-line class-methods-use-this
  isLeavingCopeptinFromCopeptinInputPage(oldPathName, newPathName) {
    return oldPathName.includes('copeptin-calculator')
    && !oldPathName.endsWith('copeptin-calculator') // we dont want a warning when leaving copeptin start
    && !newPathName.includes('copeptin-calculator');
  }

  // eslint-disable-next-line class-methods-use-this
  isLeavingCopeptinStartPage(oldPathName, newPathName) {
    return oldPathName.endsWith('copeptin-calculator')
    && !newPathName.includes('copeptin-calculator');
  }

  // eslint-disable-next-line class-methods-use-this
  isNavigatingToInfoPage(newPathName) {
    return newPathName.endsWith('copeptin-references') || newPathName.endsWith('algorithm-copeptin');
  }

  // eslint-disable-next-line class-methods-use-this
  isNavigatingFromInfoPageToCopeptinPage(oldPathName, newPathName) {
    return (oldPathName.endsWith('copeptin-references') && newPathName.includes('copeptin-calculator'))
    || (oldPathName.endsWith('copeptin-algorithm') && newPathName.includes('copeptin-calculator'));
  }

  observeRouting = intercept(routing, 'location', (newLocation) => {
    const newPathName = newLocation.newValue.pathname;
    const oldPathName = routing.location !== null ? routing.location.pathname : ''; // initially, routing.location is null

    // when we intentionally leave (after alert), skip check once
    if (this.skipCopeptinLeavingCheck) {
      this.skipCopeptinLeavingCheck = false;
      return newLocation;
    }

    if (this.isNavigatingToInfoPage(newPathName)) {
      this.infoPageReturnLocation = oldPathName;
    }

    // navigation from algorithm and references pages back to copeptin pages should be
    // handled without warning dialog
    if (this.isNavigatingFromInfoPageToCopeptinPage(oldPathName, newPathName)) {
      return newLocation;
    }

    if (this.isNavigatingBackToCopeptinStart(oldPathName, newPathName)
    || this.isLeavingCopeptinFromCopeptinInputPage(oldPathName, newPathName)
    ) {
      this.isCopeptinLeavingAlert = true;
      this.interceptedPathname = newLocation.newValue.pathname;
      newLocation.newValue.pathname = routing.location.pathname; // force current path
      return newLocation;
    }

    if (!newPathName.includes('copeptin-calculator')) {
      this.resetData();
    }

    return newLocation;
  });
}

export default new CopeptinStore();
