import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import { simappColors } from 'shared/lib/theme';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import copeptinConstants from 'shared/lib/resources/copeptin-constants';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HeInputField from 'web/components/he-model/he-inputfield';

const styles = {
  tableCell: {
    textAlign: 'center',
    width: '205px',
    padding: '0 5px'
  },
  tableHeaderRow: {
    height: '29px'
  },
  expansionHead: {
    paddingLeft: '0',
    marginTop: '-5px',
    paddingRight: '0'
  },
  expansionPanel: {
    '&::before': { content: 'none' },
    marginBottom: '2px',
    marginRight: '50px',
    boxShadow: 'unset'
  },
  borderlessExpansionPanel: {
    '&::before': { content: 'none' },
    marginBottom: '2px',
    boxShadow: 'unset'
  },
  expansionContent: {
    paddingLeft: '20px',
    paddingRight: '0px',
  },
  mediumText: {
    fontWeight: '500'
  },
  animationlessPanelSummary: {
    minHeight: '48px !important',
    lineHeight: '48px',
    '& div:last-child': {
      padding: '0'
    },
    '& div:first-child': {
      marginTop: '-5px',
      marginBottom: '-5px',
      margin: '0'
    }
  },
  cursorless: {
    cursor: 'text !important'
  },
  mainItem: {
    backgroundColor: simappColors.paleGrey
  },
  table: {
    marginTop: '-10px',
    marginBottom: '-25px',
    paddingLeft: '10px',
    paddingRight: '0px'
  },
  totalTable: {
    paddingLeft: '10px',
    paddingRight: '0px',
    marginRight: '50px',
    marginTop: '-1px',
    marginBottom: '-25px'
  },
  borderlessCell: {
    border: 'none'
  },
  rowLabelWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end'
  },
  rowLabelNumber: {
    fontSize: '1.03rem'
  }
};

@inject('copeptinStore')
@observer
class CopeptinTableResults extends Component {
  getTableHead() {
    const { classes } = this.props;
    return (
      <TableHead>
        <TableRow className={classes.tableHeaderRow}>
          <TableCell className={classes.borderlessCell} />
          <TableCell className={`${classes.tableCell} ${classes.borderlessCell}`}>{this.props.headerSecondColumn}</TableCell>
          <TableCell className={`${classes.tableCell} ${classes.borderlessCell}`}>{this.props.headerThirdColumn}</TableCell>
          <TableCell className={`${classes.tableCell} ${classes.borderlessCell}`}>{this.props.headerFourthColumn}</TableCell>
          {this.props.hasPercentageColumn && <TableCell className={`${classes.tableCell} ${classes.borderlessCell}`}>{this.props.headerPercentageColumn}</TableCell>}
        </TableRow>
      </TableHead>
    );
  }

  getRowLabel = (label) => {
    const { classes } = this.props;
    if (label.charAt(0) >= '0' && label.charAt(0) <= '9') {
      return (
        <div className={classes.rowLabelWrapper}>
          <Typography variant="body1" className={classes.rowLabelNumber}>{label.charAt(0)}</Typography>
          <Typography variant="body1">{label.substr(1)}</Typography>
        </div>
      );
    }
    return (
      <Typography variant="body1">{label}</Typography>
    );
  }

  getTableRow(item, index, isLastRow) {
    const { classes, copeptinStore } = this.props;
    const maxValue = this.props.dataType === copeptinConstants.CopeptinTableRowPercentage ? 1 : -1;
    return (
      <TableRow key={index}>
        <TableCell className={`${isLastRow ? classes.borderlessCell : ''}`}>
          {this.getRowLabel(copeptinStore.getLocalizedText(item.name))}
        </TableCell>
        <TableCell className={`${classes.tableCell} ${isLastRow ? classes.borderlessCell : ''}`}>
          <HeInputField
            fieldIndex={index}
            type={this.props.dataType}
            maxValue={maxValue}
            value={item.copeptin_value}
            displayType="text"
            isUSNumberInput={this.props.isUSNumberInput}
          />
        </TableCell>
        <TableCell className={`${classes.tableCell} ${isLastRow ? classes.borderlessCell : ''}`}>
          <HeInputField
            fieldIndex={index}
            type={this.props.dataType}
            maxValue={maxValue}
            value={item.default_value}
            displayType="text"
            isUSNumberInput={this.props.isUSNumberInput}
          />
        </TableCell>
        <TableCell className={`${classes.tableCell} ${isLastRow ? classes.borderlessCell : ''}`}>
          <HeInputField
            fieldIndex={index}
            type={this.props.dataType}
            maxValue={maxValue}
            value={item.diff_value}
            allowNegative
            displayType="text"
            isUSNumberInput={this.props.isUSNumberInput}
            positiveColorCode={false}
          />
        </TableCell>
        {this.props.hasPercentageColumn && (
          <TableCell className={`${classes.tableCell} ${isLastRow ? classes.borderlessCell : ''}`}>
            <HeInputField
              fieldIndex={index}
              type="percentage"
              value={item.percentage_value}
              allowNegative
              displayType="text"
              isUSNumberInput={this.props.isUSNumberInput}
              positiveColorCode
            />
          </TableCell>
        )}
      </TableRow>
    );
  }

  getTable(source) {
    const { classes } = this.props;
    const lastIndex = source.length - 1;
    return (
      <div>
        <ExpansionPanel expanded={(source.length === 1 || !this.props.isExpandable) ? true : undefined} CollapseProps={{ timeout: 0 }} className={classes.expansionPanel}>
          <ExpansionPanelSummary
            expandIcon={(source.length === 1 || !this.props.isExpandable) ? null : <ExpandMoreIcon />}
            className={`${classes.expansionHead} ${classes.animationlessPanelSummary} ${(source.length === 1 || !this.props.isExpandable) && classes.cursorless}`}
          >
            <Table>
              {this.getTableHead()}
            </Table>
          </ExpansionPanelSummary>
          {this.props.isExpandable && source.length > 1 && (
            <ExpansionPanelDetails className={classes.expansionContent}>
              <Table className={classes.table}>
                <TableBody>
                  {source.map((item, index) => (index !== lastIndex && this.getTableRow(item, index, index === lastIndex)))}
                </TableBody>
              </Table>
            </ExpansionPanelDetails>
          )}
        </ExpansionPanel>
        <div className={classes.totalTable}>
          <Table>
            <TableBody>
              {this.getTableRow(source[lastIndex], lastIndex, true)}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }

  render() {
    const { source, classes } = this.props;

    return (
      <ExpansionPanel key={0} CollapseProps={{ timeout: 0 }} className={classes.borderlessExpansionPanel}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={`${classes.mainItem} ${classes.animationlessPanelSummary}`}>
          <Typography variant="body1" className={classes.mediumText}>{this.props.headerFirstColumn}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.expansionContent}>
          {this.getTable(source)}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

CopeptinTableResults.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  copeptinStore: PropTypes.object.isRequired,
  source: PropTypes.array.isRequired,
  headerFirstColumn: PropTypes.string,
  headerSecondColumn: PropTypes.string,
  headerThirdColumn: PropTypes.string,
  headerFourthColumn: PropTypes.string,
  headerPercentageColumn: PropTypes.string,
  hasPercentageColumn: PropTypes.bool,
  dataType: PropTypes.string,
  isUSNumberInput: PropTypes.bool,
  isExpandable: PropTypes.bool
};

CopeptinTableResults.defaultProps = {
  headerFirstColumn: '',
  headerSecondColumn: '',
  headerThirdColumn: '',
  headerFourthColumn: '',
  headerPercentageColumn: '',
  hasPercentageColumn: false,
  dataType: copeptinConstants.CopeptinTableRowTime,
  isUSNumberInput: false,
  isExpandable: true
};

export default withStyles(styles)(CopeptinTableResults);
