import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import KeyIcon from '@material-ui/icons/VpnKeyOutlined';
import ShieldIcon from '@material-ui/icons/SecurityOutlined';
import DistributorIcon from '@material-ui/icons/Person';
import UserGroupIcon from '@material-ui/icons/GroupWork';
import { applicationStore } from 'shared/stores';
import { simappConstants, simappColors } from 'shared/lib/theme';
import CmtTile from './cmt-tile';

const styles = {
  root: {
    width: '100%',
    height: '100vH',
    display: 'flex',
    flexDirection: 'column',
    padding: simappConstants.contentMarginLarge,
    overflowX: 'hidden',
    fontFamily: 'helvetica-neue-lt, sans-serif',
    fontWeight: 'normal'
  },
  headRow: {
    display: 'flex',
    flexShrink: 0,
    height: 46,
    marginBottom: '24px',
    alignItems: 'left'
  },
  title: {
    margin: '0',
    display: 'flex',
    flexDirection: 'row',
    fontSize: '28px',
    lineHeight: '40px'
  },
  textContainer: {
    marginTop: '20px',
    marginBottom: '20px',
    paddingRight: '10px',
  },
  textBlock: {
    marginBottom: '20px',
    lineHeight: '20px',
    '&.marginBottom': {
      marginBottom: '35px'
    },
    '&.marginTop': {
      marginTop: '35px'
    },
    color: 'black'
  },
  grid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    flexWrap: 'wrap'
  },
  icon: {
    fontSize: '5rem',
    color: simappColors.coolGrey,
  }
};

const CmtScreen = (props) => {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <div className={classes.headRow}>
        <Typography
          variant="h1"
          className={classes.title}
        >
          {/* {calculatorStore.getText('title')} */}
        </Typography>
      </div>
      <div className={classes.textContainer}>
        {/* <Typography variant="body1" className={classes.textBlock}>{calculatorStore.getText('introParagraphOne')}</Typography>
          <Typography variant="body1" className={classes.textBlock}>{calculatorStore.getText('introParagraphTwo')}</Typography> */}
      </div>
      <div className={classes.grid}>
        {/* <CmtTile store={pctStore} routing={routing} path="/he-models/pct-calculator" /> */}
        { applicationStore.permissionList.includes('cmt_read_user_management') && (
          <Fragment>
            <CmtTile title="User List" path="/cmt/userlist"><DistributorIcon className={classes.icon} /></CmtTile>
            <CmtTile title="Group List" path="/cmt/grouplist"><UserGroupIcon className={classes.icon} /></CmtTile>
          </Fragment>
        )}
        { applicationStore.permissionList.includes('cmt_create_credentials') && (
          <CmtTile title="Credentials" path="/cmt/credentials"><KeyIcon className={classes.icon} /></CmtTile>
        )}
        {(
          (applicationStore.permissionList.includes('cmt_create_chatlis_license') && applicationStore.appUser.get('cmt_trained_kryptor'))
          || (applicationStore.permissionList.includes('cmt_create_fastscreen_license') && applicationStore.appUser.get('cmt_trained_fastscreen'))
        ) && (
          <CmtTile title="Licenses" path="/cmt/licenselist"><ShieldIcon className={classes.icon} /></CmtTile>
        )}
      </div>
    </div>
  );
};

CmtScreen.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CmtScreen);
