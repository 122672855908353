import routing from './router-store';
import applicationStore from './application-store';
import countryStore from './country-store';
import productStore from './product-store';
import roleStore from './role-store';
import contentTypeStore from './content-type-store';
import languageStore from './language-store';
import topicStore from './topic-store';
import contentPieceStore from './content-piece-store';
import materialStore from './material-store';
import newsStore from './news-store';
import settingsForm from './forms/settings-form';
import cmtCredentialsForm from './forms/cmt-credentials-form';
import cmtLicenseForm from './forms/cmt-license-form';
import eventForm from './forms/event-form';
import changePasswordForm from './forms/change-password-form';
import shareForm from './forms/share-form';
import calendarStore from './calendar-store';
import presentationStore from './presentation-store';
import logStore from './log-store';
import pctStore from './pct-store';
import copeptinStore from './copeptin-store';
import calculatorStore from './calculator-store';

/**
 * WARNING: This is a legacy construct that might be removed in the future.
 * Would require to move all imports like
 * `import { materialStore } from 'shared/stores'` to
 * `import materialStore from stores/material-store`
 */

export { default as routing } from './router-store';
export { default as contentTypeStore } from './content-type-store';
export { default as languageStore } from './language-store';
export { default as topicStore } from './topic-store';
export { default as calendarStore } from './calendar-store';
export { default as contentPieceStore } from './content-piece-store';
export { default as countryStore } from './country-store';
export { default as productStore } from './product-store';
export { default as roleStore } from './role-store';
export { default as materialStore } from './material-store';
export { default as newsStore } from './news-store';
export { default as applicationStore } from './application-store';
export { default as presentationStore } from './presentation-store';
export { default as logStore } from './log-store';
export { default as pctStore } from './pct-store';
export { default as copeptinStore } from './copeptin-store';
export { default as calculatorStore } from './calculator-store';
export { default as eventForm } from './forms/event-form';
export { default as cmtCredentialsForm } from './forms/cmt-credentials-form';
export { default as cmtLicenseForm } from './forms/cmt-license-form';
export { default as changePasswordForm } from './forms/change-password-form';
export { default as shareForm } from './forms/share-form';

export default {
  contentTypeStore,
  calendarStore,
  languageStore,
  topicStore,
  contentPieceStore,
  applicationStore,
  countryStore,
  productStore,
  roleStore,
  materialStore,
  newsStore,
  routing,
  settingsForm,
  cmtCredentialsForm,
  cmtLicenseForm,
  eventForm,
  changePasswordForm,
  shareForm,
  presentationStore,
  logStore,
  pctStore,
  copeptinStore,
  calculatorStore,
};
