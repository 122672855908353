import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import DatabaseProvider from '@nozbe/watermelondb/DatabaseProvider';
import * as Sentry from '@sentry/react';
import config from 'shared/config';
import Adal from 'shared/lib/adal-request';
import stores from 'shared/stores';
import database from 'shared/stores/database/database';
import App from './app';

Sentry.init({
  dsn: config.sentryDsn,
  environment: config.sentryEnvironment,
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const Main = () => (
  <Provider {...stores}>
    <DatabaseProvider database={database}>
      <App />
    </DatabaseProvider>
  </Provider>
);

// initialize Adal for Azure oAuth
Adal.processAdalCallback();
if (window === window.parent) {
  ReactDOM.render(<Main />, document.getElementById('app'));
}
