import { observable, runInAction, action, reaction, computed, intercept, when } from 'mobx';
import { persist } from 'mobx-persist';
import { flatten } from 'lodash';
import { Q } from '@nozbe/watermelondb';
import rest from 'shared/lib/rest';
import { merge } from 'shared/lib/helpers';
import createStorage from './create-storage';
import database from './database/database';
import { contentPieceStore, applicationStore, productStore, topicStore, languageStore, materialStore, contentTypeStore } from '.';
import routing from './router-store';

/**
 * This store does not hold the presentations, those are in the materialsStore.
 * Here, we save everything that is needed during the process of creating presentations.
 */

class Characteristic {
  @persist @observable id;
  @persist @observable name;
}

class Speciality {
  @persist @observable id;
  @persist @observable name;
}

class PresentationStore {
  constructor() {
    createStorage('presentationStore', this).then(() => {
    });
  }

  api = {
    characteristic: '/characteristic',
    speciality: '/speciality',
    save: '/presentation'
  };

  specialityIcons = {
    1: 'clinical',
    2: 'technical',
    3: 'economical',
    4: 'emotional'
  };

  @persist('list', Characteristic) @observable characteristics = [];
  @persist('list', Speciality) @observable specialities = [];

  @observable selectedProductGroupIds = [];
  @observable selectedTopicIds = [];
  @observable selectedLanguageId = null;
  @observable contentPieces = [];
  @observable title = '';
  @observable id = null;

  @observable matrix = {};

  @observable previewPresentation = false;
  @observable previewStartIndex = 0;

  @observable pieceSelection = false;
  @observable selectedCharacteristicId = null;
  @observable selectedSpecialityId = null;
  @observable piecesForSelection = [];

  steps = {
    productGroupSelection: 0,
    topicSelection: 1,
    languageSelection: 2,
    contentSelection: 3,
  };

  @observable step = this.steps.productGroupSelection;

  loadData(forceReload = false, lastUpdated = null) {
    const startTime = new Date().getTime();
    return new Promise((resolve) => {
      let params = '?';
      if (this.hasDataLoaded && !forceReload && lastUpdated !== null) {
        params += `since=${lastUpdated}`;
      } else if (forceReload) {
        runInAction(() => {
          this.characteristics = [];
          this.specialities = [];
        });
      }
      Promise
        .all([
          rest.get(this.api.characteristic + params),
          rest.get(this.api.speciality + params)
        ])
        .then((response) => {
          runInAction(() => {
            merge(this.characteristics, response[0].data, 'id', 'id');
            merge(this.specialities, response[1].data, 'id', 'id');
            console.log(`${new Date().getTime() - startTime}ms - ${this.constructor.name}`);
            resolve(true);
          });
        }).catch((error) => {
          console.log('Could not load characteristics/specialities');
          console.log(error);
          resolve(true);
        });
    });
  }

  get hasDataLoaded() {
    return !!this.characteristics.length && !!this.specialities.length;
  }

  @action reset() {
    this.id = null;
    this.selectedProductGroupIds = [];
    this.selectedTopicIds = [];
    this.selectedLanguageId = null;
    this.contentPieces = [];
    this.previewPresentation = false;
    this.previewStartIndex = 0;
    this.pieceSelection = false;
    this.selectedCharacteristicId = null;
    this.selectedSpecialityId = null;
    this.piecesForSelection = [];
    this.matrix = {};
    this.step = this.steps.productGroupSelection;
  }

  @observable showLeaveWarning = false;
  @observable leaveConfirmed = false;
  @observable leaveFunction = '';
  @observable interceptedPathname = '';

  @action confirmLeave = () => {
    this.showLeaveWarning = false;
    this.leaveConfirmed = true;
    if (this.leaveFunction.length > 0) {
      this[this.leaveFunction]();
    }
    this.leaveFunction = '';
  }

  @action resetLanguage() {
    if (!this.leaveConfirmed) {
      this.showLeaveWarning = true;
      this.leaveFunction = 'resetLanguage';
      return;
    }
    this.leaveConfirmed = false;
    // this.selectedLanguageId = null;
    this.contentPieces = [];
    this.previewPresentation = false;
    this.previewStartIndex = 0;
    this.pieceSelection = false;
    this.selectedCharacteristicId = null;
    this.selectedSpecialityId = null;
    this.piecesForSelection = [];
    // this.matrix = {};
    this.step = this.steps.languageSelection;
  }

  @action cancelLeave = () => {
    this.showLeaveWarning = false;
    this.leaveConfirmed = false;
  }

  interceptRoute = intercept(routing, 'location', (newLocation) => {
    const newPathname = newLocation.newValue.pathname;
    const oldPathname = routing.location !== null ? routing.location.pathname : ''; // initially, routing.location is null
    if (oldPathname.startsWith('/create-presentation') && !newPathname.startsWith('/create-presentation') && this.step === this.steps.contentSelection) {
      if (this.leaveConfirmed) {
        this.leaveConfirmed = false;
      } else {
        this.showLeaveWarning = true;
        this.interceptedPathname = newLocation.newValue.pathname;
        this.leaveFunction = 'cancel';
        newLocation.newValue.pathname = routing.location.pathname; // force current path
      }
    }
    return newLocation;
  });

  @action cancel = () => {
    if (!this.leaveConfirmed) {
      this.showLeaveWarning = true;
      this.leaveFunction = 'cancel';
      this.interceptedPathname = '/materials';
      return;
    }
    this.leaveConfirmed = false;
    this.reset();
    try {
      routing.push(this.interceptedPathname);
    // eslint-disable-next-line no-empty
    } catch {}
  }

  @action loadPresentationForEdit = (uid) => {
    this.reset();
    when(
      () => materialStore.materialsUpdated !== null,
      () => {
        const presentation = materialStore.materials.find(m => (m.uid === uid));
        this.id = presentation.id;
        this.title = presentation.title;
        this.selectedLanguageId = presentation.language_id;
        this.selectedTopicIds = presentation.topic_ids;
        const productIds = [...new Set(flatten(topicStore.topics.filter(t => (this.selectedTopicIds.includes(t.id))).map(t => t.product_ids)))];
        this.selectedProductGroupIds = [...new Set(flatten(productStore.products.filter(p => (productIds.includes(p.id))).map(p => p.product_group_id)))];
        presentation.presentation_items.forEach((pi) => {
          const item = contentPieceStore.contentPieces.find(cp => (cp.id === `${pi.content_piece_id}`));
          if (typeof item !== 'undefined') {
            this.contentPieces.push(
              { item: contentPieceStore.contentPieces.find(cp => (cp.id === `${pi.content_piece_id}`)), characteristic_id: pi.characteristic_id, speciality_id: pi.speciality_id }
            );
          }
        });
        this.step = this.steps.contentSelection;
      }
    );
  }

  @action save = title => (
    new Promise((resolve) => {
      const data = {
        user_id: applicationStore.appUser.get('id'),
        title: title.trim(),
        topic_ids: this.selectedTopicIds,
        country_ids: [applicationStore.presentingIn],
        language_id: this.selectedLanguageId,
        presentation_items: this.contentPieces.map(cp => ({ content_piece_id: cp.item.id, characteristic_id: cp.characteristic_id, speciality_id: cp.speciality_id }))
      };
      this.title = data.title;
      let url = this.api.save;
      let method = rest.post;
      if (this.id !== null) {
        url += `/${this.id}`;
        method = rest.put;
      }
      method(url, data)
        .then((response) => {
          if (response.data.success) {
            const object = response.data.object;
            // save in DB and MobX
            if (this.id === null) {
              this.id = response.data.id;
              // new entry
              database.action(async () => {
                const uid = `p:${this.id}`;
                const newEntry = await database.collections.get('material').create((p) => {
                  p.title = object.title;
                  p.description = object.description;
                  p.languageId = object.language_id;
                  p.shareable = object.shareable;
                  p.favorite = object.favorite;
                  p.bId = object.b_id;
                  p.uid = object.uid;
                  p.thumbnail = object.thumbnail;
                  p.bCreatedAt = object.b_created_at;
                  p.bUpdatedAt = object.b_updated_at;
                  p.materialType = object.material_type;
                  p.contentTypeId = object.content_type_id;
                  p.topicIds = object.topic_ids;
                  p.countryIds = object.country_ids;
                  p.languageId = object.language_id;
                  p.availableInMaterials = object.available_in_materials;
                  p.availableInSoftware = object.available_in_software;
                  p.presentationItems = JSON.stringify(object.presentation_items);
                  // eslint-disable-next-line no-underscore-dangle
                  p._raw.id = uid;
                });
                // DRY!!!
                // eslint-disable-next-line no-underscore-dangle
                const material = { ...newEntry._raw };
                material.country_ids = material.country_ids.length > 0 ? JSON.parse(material.country_ids) : [];
                material.product_ids = material.product_ids.length > 0 ? JSON.parse(material.product_ids) : [];
                material.topic_ids = material.topic_ids.length > 0 ? JSON.parse(material.topic_ids) : [];
                material.recommended_country_ids = material.recommended_country_ids !== null && material.recommended_country_ids.length > 0 ? JSON.parse(material.recommended_country_ids) : [];
                material.keywords = [];
                material.presentation_items = material.presentation_items !== null && material.presentation_items.length > 0 ? JSON.parse(material.presentation_items) : [];
                material.isRecommended = () => material.recommended_country_ids.includes(applicationStore.presentingIn);
                const contentType = contentTypeStore.classicalContentTypes.find(cT => cT.id === material.content_type_id);
                material.content_type = typeof contentType !== 'undefined' ? contentType.name : '';
                material.favorite = observable.box(material.favorite);
                material.id = material.b_id;
                materialStore.materials.push(material);
                materialStore.materialsUpdated = Date.now();
                applicationStore.addNotification(`Saved Presentation "${title}".`);
                resolve();
              });
            } else {
              // update
              const uid = `p:${this.id}`;
              database.action(async () => {
                const result = await database.collections.get('material').query(Q.where('uid', uid)).fetch();
                await result[0].update((p) => {
                  p.title = data.title;
                  p.presentationItems = JSON.stringify(data.presentation_items);
                });
              });
              const index = materialStore.materials.findIndex(t => t.uid === uid);
              materialStore.materials[index] = { ...materialStore.materials[index], ...data };
              materialStore.materialsUpdated = Date.now();
              applicationStore.addNotification(`Saved Presentation "${title}".`);
              resolve();
            }
          } else {
            applicationStore.addNotification(`Could not save Presentation "${title}".`);
          }
        });
    })
  );

  @action delete = (id, title) => (
    new Promise((resolve) => {
      rest.delete(`${this.api.save}/${id}/user/${applicationStore.appUser.get('id')}`)
        .then(() => {
          resolve();
          materialStore.removePresentationFromCache(id);
          applicationStore.addNotification(`Deleted Presentation "${title}".`);
        });
    })
  );

  buildMatrix = reaction(
    () => {
      if (this.selectedLanguageId === null || this.selectedTopicIds.length === 0) return null;
      const matrix = {};
      this.characteristics.forEach((characteristic) => {
        matrix[characteristic.id] = {};
        this.specialities.forEach((speciality) => {
          matrix[characteristic.id][speciality.id] = false;
        });
      });

      contentPieceStore.contentPieces
        .filter(cp => (
          cp.language_id === this.selectedLanguageId
          && cp.content_type_id === contentPieceStore.contentTypes.content
          && cp.country_ids.includes(applicationStore.presentingIn)
          && this.selectedTopicIds.some(topicId => cp.topic_ids.includes(topicId))
        ))
        .forEach((cp) => {
          cp.characteristic_ids.forEach((characteristicId) => {
            cp.speciality_ids.forEach((specialityId) => {
              matrix[characteristicId][specialityId] = true;
            });
          });
        });
      return matrix;
    },
    (data) => {
      this.matrix = data;
    }
  );

  // eslint-disable-next-line class-methods-use-this
  get productGroupsForSelection() {
    // to find the available product groups, we must first filter the content pieces by country
    // then find matching topics, products, productgroups
    const availableTopicIds = [...new Set(flatten(contentPieceStore.contentPieces.filter(cp => (cp.content_type_id === contentPieceStore.contentTypes.content && cp.country_ids.includes(applicationStore.presentingIn))).map(cp => (cp.topic_ids))))];
    const availableProductIds = [...new Set(flatten(topicStore.topics.filter(t => (availableTopicIds.includes(t.id))).map(t => (t.product_ids))))];
    const availableProductGroupIds = [...new Set(productStore.products.filter(p => (availableProductIds.includes(p.id))).map(p => (p.product_group_id)))];

    return productStore.productGroups.filter(pg => (availableProductGroupIds.includes(pg.id)));
  }

  get topicsForSelection() {
    // filter available topics
    // show only when content pieces are available in the current country and matching the selected product group
    const availableTopicIds = [...new Set(flatten(contentPieceStore.contentPieces
      .filter(cp => (cp.content_type_id === contentPieceStore.contentTypes.content && cp.country_ids.includes(applicationStore.presentingIn)))
      .map(cp => (cp.topic_ids))))];

    const availableProductIds = productStore.products.filter(p => (this.selectedProductGroupIds.includes(p.product_group_id))).map(p => (p.id));
    return topicStore.topics
      .filter(topic => (topic.product_ids.some(pId => (availableProductIds.includes(pId)))))
      .filter(topic => (availableTopicIds.includes(topic.id)));
  }

  get languagesForSelection() {
    const languageIds = contentPieceStore.contentPieces.filter(cp => (cp.content_type_id === contentPieceStore.contentTypes.content && this.selectedTopicIds.some(topicId => cp.topic_ids.includes(topicId)))).map(cp => (cp.language_id));
    return languageStore.languages.filter(l => (languageIds.includes(l.id)));
  }

  get currentCharacteristicName() {
    const characteristic = this.characteristics.find(c => (c.id === this.selectedCharacteristicId));
    return typeof characteristic !== 'undefined' ? characteristic.name : '';
  }

  get currentSpecialityName() {
    const speciality = this.specialities.find(c => (c.id === this.selectedSpecialityId));
    return typeof speciality !== 'undefined' ? speciality.name : '';
  }

  // getFrontSlide = observe(computed(() => ({ lanuguageId: this.selectedLanguageId, step: this.step })), (newValues) => {
  getFronSlide = reaction(
    () => ({ lanuguageId: this.selectedLanguageId, step: this.step }),
    (newValues) => {
      if (newValues.languageId === null || this.id !== null) return;
      this.contentPieces = [];
      const coverPage = contentPieceStore.contentPieces.find(cp => (
        cp.content_type_id === contentPieceStore.contentTypes.cover
        && cp.language_id === this.selectedLanguageId
        && cp.country_ids.includes(applicationStore.presentingIn)
        && this.selectedTopicIds.every(topicId => cp.topic_ids.includes(topicId))
      ));
      if (typeof coverPage !== 'undefined') {
        this.contentPieces.push({ item: coverPage, characteristic_id: null, speciality_id: null });
      }

      const disclaimerPage = contentPieceStore.contentPieces.find(cp => (
        cp.content_type_id === contentPieceStore.contentTypes.disclaimer
        && cp.language_id === this.selectedLanguageId
        && cp.country_ids.includes(applicationStore.presentingIn)
        && this.selectedTopicIds.every(topicId => cp.topic_ids.includes(topicId))
      ));
      if (typeof disclaimerPage !== 'undefined') {
        this.contentPieces.push({ item: disclaimerPage, characteristic_id: null, speciality_id: null });
      }
    }
  );

  @action startPreviewPresentation = (index) => {
    this.previewPresentation = true;
    this.previewStartIndex = index;
  }

  @action stopPreviewPresentation = () => {
    this.previewPresentation = false;
    this.previewStartIndex = null;
  }

  @action clearStartIndex = () => {
    this.previewStartIndex = null;
  }

  @action startPieceSelection = (characteristicId, specialityId) => {
    this.pieceSelection = true;
    this.selectedCharacteristicId = characteristicId;
    this.selectedSpecialityId = specialityId;

    this.piecesForSelection = contentPieceStore.contentPieces
      .filter(cp => (
        cp.country_ids.includes(applicationStore.presentingIn)
        && this.selectedTopicIds.some(topicId => cp.topic_ids.includes(topicId))
        && cp.characteristic_ids.includes(characteristicId)
        && cp.speciality_ids.includes(specialityId)
        && cp.language_id === this.selectedLanguageId
        && cp.content_type_id === contentPieceStore.contentTypes.content
      ));
  }

  @action stopPieceSelection = () => {
    this.pieceSelection = false;
    this.piecesForSelection = [];
  }

  @action addAllPieces = () => {
    contentPieceStore.contentPieces
      .filter(cp => (
        cp.country_ids.includes(applicationStore.presentingIn)
        && this.selectedTopicIds.some(topicId => cp.topic_ids.includes(topicId))
        && cp.characteristic_ids.includes(this.selectedCharacteristicId)
        && cp.speciality_ids.includes(this.selectedSpecialityId)
        && cp.language_id === this.selectedLanguageId
        && cp.content_type_id === contentPieceStore.contentTypes.content
      )).forEach((cp) => {
        this.contentPieces.splice(this.contentPieces.length - 1, 0, { item: cp, characteristic_id: this.selectedCharacteristicId, speciality_id: this.selectedSpecialityId });
      });
  }

  @action addPiece = (piece) => {
    this.contentPieces.splice(this.contentPieces.length - 1, 0, { item: piece, characteristic_id: this.selectedCharacteristicId, speciality_id: this.selectedSpecialityId });
  }

  @action handleDragDrop = (e) => {
    if (e.destination === null || e.destination.index === 0 || e.destination.droppableId === 'selection-list') return;
    if (e.source.droppableId === 'selection-list') {
      if (e.destination.index === this.contentPieces.length) return;
      // add new
      const contentPiece = contentPieceStore.contentPieces.find(cp => (cp.id === e.draggableId));
      if (typeof contentPiece !== 'undefined') {
        this.contentPieces.splice(e.destination.index, 0, { item: contentPiece, characteristic_id: this.selectedCharacteristicId, speciality_id: this.selectedSpecialityId });
      }
    } else {
      if (e.destination.index === this.contentPieces.length - 1) return;
      // move
      const removed = this.contentPieces.splice(e.source.index, 1);
      this.contentPieces.splice(e.destination.index, 0, removed[0]);
    }
  }

  checkContentPiecesAvailablesforTopics = (topicIds) => {
    const preFiltered = contentPieceStore.contentPieces.filter(cp => cp.country_ids.includes(applicationStore.presentingIn) && topicIds.every(topicId => cp.topic_ids.includes(topicId)));

    return (
      preFiltered.some(cp => cp.content_type_id === contentPieceStore.contentTypes.cover)
      && preFiltered.some(cp => cp.content_type_id === contentPieceStore.contentTypes.disclaimer)
      && preFiltered.some(cp => cp.content_type_id === contentPieceStore.contentTypes.content)
    );
  }

  @computed get firstProductGroupId() {
    return this.selectedProductGroupIds.length > 0 ? this.selectedProductGroupIds[0] : null;
  }

  @computed get firstTopicId() {
    return this.selectedTopicIds.length > 0 ? this.selectedTopicIds[0] : null;
  }

  /** extra native helper */

  @observable draggedId = null;
  @observable releaseId = null; // is set to the draggedId after tap end, for animation
  @observable draggedPosition = { x: 0, y: 0 };
  @observable draggedInitialPosition = { x: 0, y: 0 };
  @observable previewSrcollOffest = 0;
  @observable sidebarIsScrolling = false;

  @computed get draggedX() {
    return this.draggedPosition.x;
  }

  @computed get draggedY() {
    return this.draggedPosition.y;
  }

  @action handleDragDropNative = (index) => {
    // eslint-disable-next-line no-param-reassign
    if (index >= this.contentPieces.length) index = this.contentPieces.length - 1;
    // eslint-disable-next-line no-param-reassign
    if (index === 0) index = 1;
    // add new
    const contentPiece = contentPieceStore.contentPieces.find(cp => (cp.id === this.draggedId));
    if (typeof contentPiece !== 'undefined') {
      this.contentPieces.splice(index, 0, { item: contentPiece, characteristic_id: this.selectedCharacteristicId, speciality_id: this.selectedSpecialityId });
    }
  }

  @action sidebarScrollBegin = () => {
    this.sidebarIsScrolling = true;
  }

  @action sidebarScrollEnd = () => {
    this.sidebarIsScrolling = false;
  }
}

export default new PresentationStore();
