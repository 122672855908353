import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import InputField from 'web/components/_ui/form-elements/input-field';
import SaveButton from 'web/components/_ui/form-elements/save-button';
import CancelButton from 'web/components/_ui/form-elements/cancel-button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import simappColors from 'shared/lib/theme/simapp-colors';
import ModalHeader from '../_ui/modal-header';

const styles = {
  dialog: {
    width: 600
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  error: {
    color: simappColors.strawberry,
    padding: '0.5em'
  },
};

@inject('changePasswordForm') @observer
class EventFormDialog extends Component {
  render() {
    const { changePasswordForm, classes } = this.props;
    const { fields, onFieldChange } = changePasswordForm;
    return (
      <Dialog
        open={changePasswordForm.showDialog}
        onClose={() => changePasswordForm.close()}
        classes={{
          paper: classes.dialog
        }}
      >
        <ModalHeader title="Change Password" />
        <DialogContent className={classes.content}>

          <form>
            <InputField
              fields={fields}
              name="old_password"
              onChange={onFieldChange}
              type="password"
              autoComplete={false}
              autoFocus
              labelWidth="35%"
            />
            <Divider light />
            <InputField
              fields={fields}
              name="new_password_1"
              onChange={onFieldChange}
              autoComplete={false}
              labelWidth="35%"
            />
            <Divider light />
            <InputField
              fields={fields}
              name="new_password_2"
              onChange={onFieldChange}
              autoComplete={false}
              labelWidth="35%"
            />
          </form>
          <Typography className={classes.error}>{changePasswordForm.responseError}</Typography>
        </DialogContent>
        <DialogActions className={classes.flex}>
          <CancelButton
            form={changePasswordForm}
            onClick={() => changePasswordForm.close()}
            label="Cancel"
          />
          <div className={classes.flex}>
            <CancelButton
              form={changePasswordForm}
              onClick={() => changePasswordForm.resetValues()}
              label="Reset"
            />
            <SaveButton
              form={changePasswordForm}
              onClick={() => changePasswordForm.save()}
            />
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

EventFormDialog.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  changePasswordForm: PropTypes.object.isRequired,
};

export default withStyles(styles)(EventFormDialog);
