import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Q } from '@nozbe/watermelondb';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import ResetIcon from '@material-ui/icons/RotateLeft';
import database from 'shared/stores/database/database';
import { simappConstants } from 'shared/lib/theme';
import { escapeRegExpInput } from 'shared/lib/helpers';
import EnhancedTable from './enhanced-table';

const styles = theme => ({
  root: {
    height: '100vH',
    width: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: simappConstants.contentMarginLarge
  },
  title: {
    marginBottom: theme.spacing.unit * 3,
  },
  searchArea: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: '1rem',
    paddingBottom: '1rem'
  },
  formControl: {
    margin: '0.5rem 1rem 1rem 1rem',
    width: 250,
  },
  checkboxFormControl: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& label': {
      position: 'static',
      transform: 'none',
      fontWeight: 400,
    }
  },
  resetButton: {
    margin: '1rem',
    height: 36,
    width: 200
  }
});

const columns = [
  { id: 'id', disablePadding: false, label: 'ID' },
  { id: 'name', disablePadding: false, label: 'Company Name' },
  { id: 'contactName', disablePadding: false, label: 'Contact Name' },
  { id: 'contactEmail', disablePadding: false, label: 'Contact Email' },
  { id: 'maingroupName', disablePadding: false, label: 'Main Group' },
  { id: 'credentials', disablePadding: false, label: 'Credentials', checkbox: true },
  { id: 'license', disablePadding: false, label: 'License', checkbox: true },
  { id: 'devmode', disablePadding: false, label: 'Dev Mode', checkbox: true },
];

const UserGroupList = (props) => {
  const { classes } = props;

  const [groups, setGroups] = useState([]);
  const [searchGroupName, setSearchGroupName] = useState('');
  const [searchContactName, setSearchContactName] = useState('');
  const [searchContactEmail, setSearchContactEmail] = useState('');
  const [showDeactivated, setShowDeactivated] = useState(false);
  const groupNameRegex = new RegExp(escapeRegExpInput(searchGroupName), 'i');
  const contactNameRegex = new RegExp(escapeRegExpInput(searchContactName), 'i');
  const contactEmailRegex = new RegExp(escapeRegExpInput(searchContactEmail), 'i');

  const resetSearch = () => {
    setSearchGroupName('');
    setSearchContactName('');
    setSearchContactEmail('');
    setShowDeactivated(false);
  };

  useEffect(() => {
    database.collections.get('userGroup').query(Q.where('is_maingroup', false)).fetch().then((userGroups) => {
      setGroups(userGroups.map((uG) => {
        const result = uG.getFlat();
        result.credentials = uG.permissionList.includes('cmt_create_credentials');
        result.license = uG.permissionList.includes('cmt_create_license');
        result.devmode = uG.permissionList.includes('cmt_create_dev_mode');
        return result;
      }));
    });
  }, []);

  return (
    <div className={classes.root}>
      <Typography variant="h5" id="tableTitle" className={classes.title}>Group List</Typography>
      <Paper>
        <div className={classes.searchArea}>
          <FormControl className={classes.formControl}>
            <Input
              value={searchGroupName}
              onChange={e => setSearchGroupName(e.target.value)}
              autoComplete="off"
              placeholder="Company"
              className={classes.searchField}
              inputProps={{ maxLength: '50' }}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <Input
              value={searchContactName}
              onChange={e => setSearchContactName(e.target.value)}
              autoComplete="off"
              placeholder="Contact Name"
              className={classes.searchField}
              inputProps={{ maxLength: '50' }}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <Input
              value={searchContactEmail}
              onChange={e => setSearchContactEmail(e.target.value)}
              autoComplete="off"
              placeholder="Contact Email"
              className={classes.searchField}
              inputProps={{ maxLength: '100' }}
            />
          </FormControl>
          <FormControl className={`${classes.formControl} ${classes.checkboxFormControl}`}>
            <Checkbox
              id="show_deactivated"
              checked={showDeactivated}
              onClick={() => setShowDeactivated(!showDeactivated)}
              color="primary"
            />
            <InputLabel
              htmlFor="show_deactivated"
              disableAnimation
              shrink={false}
            >Show deactivated groups
            </InputLabel>
          </FormControl>
          <Button variant="contained" color="default" onClick={resetSearch} className={classes.resetButton}>
            Reset
            <ResetIcon />
          </Button>
        </div>
        <EnhancedTable
          columns={columns}
          data={groups.filter(g => groupNameRegex.test(g.name)
            && contactNameRegex.test(g.contactName)
            && contactEmailRegex.test(g.contactEmail)
            && (showDeactivated || !g.isDeactivated))}
        />
      </Paper>
    </div>
  );
};

UserGroupList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(UserGroupList);
