import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import simappColors from 'shared/lib/theme/simapp-colors';
import texts from 'shared/lib/resources/texts';
import { presentationStore, materialStore } from 'shared/stores';
import SimappIcon from 'web/components/_ui/simapp-icon';
import Selector from './selector';
import SidebarList from './sidebar-list';
import PresentationPreview from './presentation-preview';
import ContentPieceSelection from './content-piece-selection';
import TitleOverlay from './title-overlay';
import NextStepOverlay from './next-step-overlay';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  header: {
    width: '100%',
    backgroundColor: simappColors.white,
    borderBottom: `1px solid ${simappColors.paleGrey}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  backButton: {
    marginRight: '-4rem'
  },
  title: {
    padding: '1rem',
    textAlign: 'center',
    flex: 1,
  },
  matrixTitle: {
    paddingRight: '20vw'
  },
  selectorWrapper: {
    paddingTop: '10vh'
  },
  matrixWrapper: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative'
  },
  innerMatrixWrapper: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  matrix: {
    borderCollapse: 'collapse',
    margin: '0 auto',
    width: '80vh',
    maxWidth: 700,
    '& td': {
      width: '24%',
      paddingBottom: '24%',
      position: 'relative',
      textAlign: 'center',
      border: `1px solid ${simappColors.paleGrey}`,
      '& > *': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
      }
    },
    '& th': {
      padding: '5px'
    },
    '& tr th:first-child p': {
      writingMode: 'vertical-rl',
      transform: 'rotate(180deg)'
    }
  },
  specialityIcon: {
    display: 'block',
    width: '33%',
    margin: 'auto',
    color: simappColors.paleGrey
  },
  specialityIconAvailable: {
    color: simappColors.azul
  },
  fieldAvailable: {
    backgroundColor: simappColors.white
  },
  fieldUnavailable: {
    display: 'flex'
  },
  // this is big overlay of a single content piece
  previewPieceArea: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',

  },
  // this is the sidebar with the list of items
  previewArea: {
    width: '20vw',
    height: '100%',
    backgroundColor: simappColors.paleGrey,
    borderLeft: `1px solid ${simappColors.steelGrey}`,
    display: 'flex',
    flexDirection: 'column'
  },
  previewAreaDroppable: {
    overflowY: 'auto',
    flex: 1
  },
  previewAreaInner: {
    width: '80%',
    margin: '1em auto',
  },
  hintArea: {
    width: '100%',
    paddingBottom: 3,
    backgroundColor: simappColors.paleGrey,
    borderBottom: `1px solid ${simappColors.steelGrey}`,
  },
  hint: {
    fontSize: '80%',
    textAlign: 'center',
    marginTop: 5,
    color: simappColors.darkGrey
  },
  buildArea: {
    display: 'flex',
    width: '100%',
    height: 'calc(100% - 60px)' // full height minus top bar
  },
  buttonArea: {
    justifyContent: 'space-between',
    backgroundColor: simappColors.palerGrey,
    borderTop: `1px solid ${simappColors.steelGrey}`,
    margin: 0,
    padding: '1rem'
  },
  buttonWrapper: {
    position: 'relative',
    marginLeft: 8,
    marginRight: 8
  }
};

@observer
class NewPresentation extends Component {
  @observable titleModalOpen = false;
  @observable nextStepModalOpen = false;
  @observable draggedIndex = null;
  selectedProductGroupIdsBuffer = [];
  selectedTopicIdsBuffer = [];
  selectedLanguageIdBuffer = null;

  constructor(props) {
    super(props);

    presentationStore.reset();

    if (typeof props.match.params.uid !== 'undefined') {
      presentationStore.loadPresentationForEdit(props.match.params.uid);
    }
  }

  @action handleProductGroupSelection = (id) => {
    if (id !== presentationStore.firstProductGroupId) {
      presentationStore.selectedTopicIds = [];
      presentationStore.selectedLanguageId = null;
    }
    presentationStore.selectedProductGroupIds = [id];
    presentationStore.step++;
  }

  @action handleTopicSelection = (id) => {
    if (id !== presentationStore.firstTopicId) {
      presentationStore.selectedLanguageId = null;
    }
    presentationStore.selectedTopicIds = [id];
    presentationStore.step++;
  }

  @action handleLanguageSelection = (id) => {
    presentationStore.selectedLanguageId = id;
    presentationStore.step++;
  }

  @action backToProductGroups = () => {
    presentationStore.step = presentationStore.steps.productGroupSelection;
  }

  @action backToTopics = () => {
    presentationStore.step = presentationStore.steps.topicSelection;
  }

  @action backToLanguages = () => {
    // presentationStore.step = presentationStore.steps.languageSelection;
    presentationStore.resetLanguage();
  }

  buildMatrixField = (characteristicId, specialityId) => {
    if (presentationStore.matrix[characteristicId] && presentationStore.matrix[characteristicId][specialityId]) {
      return (
        <td key={characteristicId} className={this.props.classes.fieldAvailable}>
          <ButtonBase onClick={() => { presentationStore.startPieceSelection(characteristicId, specialityId); }}>
            <SimappIcon icon={presentationStore.specialityIcons[specialityId]} className={`${this.props.classes.specialityIcon} ${this.props.classes.specialityIconAvailable}`} />
          </ButtonBase>
        </td>
      );
    }
    return (
      <td key={characteristicId}>
        <div className={this.props.classes.fieldUnavailable}>
          <SimappIcon icon={presentationStore.specialityIcons[specialityId]} className={this.props.classes.specialityIcon} />
        </div>
      </td>
    );
  };

  @action openTitleModal = () => {
    this.titleModalOpen = true;
  }

  @action closeTitleModal = () => {
    this.titleModalOpen = false;
  }

  @action showNextStep = () => {
    this.titleModalOpen = false;
    this.nextStepModalOpen = true;
  }

  @action closeNextStep = () => {
    this.nextStepModalOpen = false;
  }

  @action dragStart = (e) => {
    if (e.source.droppableId === 'selection-list') {
      this.draggedIndex = e.source.index;
    } else {
      this.draggedIndex = null;
    }
  }

  @action dragEnd = (e) => {
    this.draggedIndex = null;
    presentationStore.handleDragDrop(e);
  }

  render() {
    const { classes } = this.props;
    // eslint-disable-next-line no-unused-expressions
    materialStore.materialsUpdated; // trigger re-render if opened directly and mobx is not yet ready
    if (presentationStore.step < presentationStore.steps.contentSelection) {
      return (
        <Fragment>
          {presentationStore.step === presentationStore.steps.productGroupSelection && (
            <div className={classes.root}>
              <div className={classes.header}>
                <Typography variant="h5" className={classes.title}>New Presentation</Typography>
              </div>
              <div className={classes.selectorWrapper}>
                <Selector
                  title="Select a Product Group"
                  list={presentationStore.productGroupsForSelection}
                  selected={presentationStore.firstProductGroupId}
                  selectAction={this.handleProductGroupSelection}
                />
              </div>
            </div>
          )}

          {presentationStore.step === presentationStore.steps.topicSelection && (
            <div className={classes.root}>
              <div className={classes.header}>
                <Button color="primary" onClick={this.backToProductGroups} className={classes.backButton}><ArrowBack /></Button>
                <Typography variant="h5" className={classes.title}>New Presentation</Typography>
              </div>
              <div className={classes.selectorWrapper}>
                <Selector
                  title="Select a Topic"
                  list={presentationStore.topicsForSelection}
                  selected={presentationStore.firstTopicId}
                  selectAction={this.handleTopicSelection}
                />
              </div>
            </div>
          )}

          {presentationStore.step === presentationStore.steps.languageSelection && (
            <div className={classes.root}>
              <div className={classes.header}>
                <Button color="primary" onClick={this.backToTopics} className={classes.backButton}><ArrowBack /></Button>
                <Typography variant="h5" className={classes.title}>New Presentation</Typography>
              </div>
              <div className={classes.selectorWrapper}>
                <Selector
                  title="Select a Language"
                  list={presentationStore.languagesForSelection}
                  selected={presentationStore.selectedLanguageId}
                  selectAction={this.handleLanguageSelection}
                />
              </div>
            </div>
          )}
          <Dialog
            open={presentationStore.showLeaveWarning}
            onClose={presentationStore.cancelLeave}
            maxWidth="xs"
          >
            <DialogTitle>{presentationStore.id === null ? texts.presentationNotSavedTitle : texts.presentationNotUpdatedTitle}</DialogTitle>
            <DialogContent>
              <DialogContentText>{texts.presentationNotSavedText}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={presentationStore.cancelLeave} variant="outlined" color="primary">No</Button>
              <Button onClick={presentationStore.confirmLeave} variant="contained" color="primary">Yes</Button>
            </DialogActions>
          </Dialog>
        </Fragment>
      );
    }

    // speciality/characteristics matrix
    return (
      <div className={classes.root}>
        <div className={classes.header}>
          { presentationStore.id === null && (
            <Button color="primary" onClick={this.backToLanguages} className={classes.backButton}><ArrowBack /></Button>
          )}
          <Typography variant="h5" className={`${classes.title} ${classes.matrixTitle}`}>
            { presentationStore.id === null ? 'New Presentation' : `Edit Presentation: ${presentationStore.title}`}
          </Typography>
        </div>

        <div className={classes.buildArea}>
          <DragDropContext
            onDragStart={this.dragStart}
            onDragEnd={this.dragEnd}
          >
            <div className={classes.matrixWrapper}>
              <div className={classes.hintArea}>
                <Typography variant="body2" className={classes.hint}>{ presentationStore.pieceSelection ? 'Click item to preview, drag & drop to the right to add' : 'Click on a box to view & select content'}</Typography>
              </div>
              <div className={classes.innerMatrixWrapper}>
                <table className={classes.matrix}>
                  <tbody>
                    <tr>
                      <th>&nbsp;</th>
                      { presentationStore.characteristics.map(c => (
                        <th key={c.id}>
                          <Typography variant="body2">{c.name}</Typography>
                        </th>
                      ))}
                    </tr>
                    { presentationStore.specialities.map(s => (
                      <tr key={s.id}>
                        <th>
                          <Typography variant="body2">{s.name}</Typography>
                        </th>
                        { presentationStore.characteristics.map(c => (
                          this.buildMatrixField(c.id, s.id)
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <ContentPieceSelection draggedIndex={this.draggedIndex} />
              <PresentationPreview />
            </div>
            <div className={classes.previewArea}>
              <div className={classes.previewAreaDroppable}>
                <Typography variant="body2" className={classes.hint}>Click to preview, drag &amp; drop to move</Typography>
                <Droppable droppableId="content-piece-list">
                  {provided => (
                    <div ref={provided.innerRef} className={classes.previewAreaInner}>
                      <SidebarList />
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
              <DialogActions className={this.props.classes.buttonArea}>
                <div className={classes.buttonWrapper}>
                  <Button disabled={presentationStore.pieceSelection} variant="outlined" color="primary" onClick={presentationStore.cancel}>Cancel</Button>
                </div>
                <div className={classes.buttonWrapper}>
                  <Button disabled={presentationStore.pieceSelection} variant="contained" color="primary" onClick={this.openTitleModal}>Save</Button>
                </div>
              </DialogActions>
            </div>
          </DragDropContext>
        </div>
        <TitleOverlay
          open={this.titleModalOpen}
          onCancel={this.closeTitleModal}
          onSave={this.showNextStep}
        />
        <NextStepOverlay
          open={this.nextStepModalOpen}
          onClose={this.closeNextStep}
        />
        {/* Not using AlertDialog here, because Esc or clicking the background should not lead to leave, but that's our main action here */}
        <Dialog
          open={presentationStore.showLeaveWarning}
          onClose={presentationStore.cancelLeave}
          maxWidth="xs"
        >
          <DialogTitle>{presentationStore.id === null ? texts.presentationNotSavedTitle : texts.presentationNotUpdatedTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>{texts.presentationNotSavedText}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={presentationStore.cancelLeave} variant="outlined" color="primary">No</Button>
            <Button onClick={presentationStore.confirmLeave} variant="contained" color="primary">Yes</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

NewPresentation.propTypes = {
  match: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(NewPresentation);
