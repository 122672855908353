import { observable, action } from 'mobx';
import pctConstants from 'shared/lib/resources/pct-constants';
import { persist } from 'mobx-persist';
import { PctClinicalEffectCalculation } from 'shared/helper/pct-calculationhelper';
import { applicationStore } from '.';
import createStorage from './create-storage';

let jsonData = require('shared/lib/resources/pct-resources.json');

class PctStore {
  refreshScreenFunc = undefined;

  constructor() {
    createStorage('pctStore', this).then(() => {
      const data = require('shared/lib/resources/pct-resources.json'); // eslint-disable-line global-require
      jsonData = data === undefined ? jsonData : data;
      this.jsonResources = jsonData;
    });
  }

  // ----- Pct Data -------
  @observable dialogCheckboxState = false;
  @action changeDialogCheckboxState = (resetState) => {
    if (resetState === true) {
      this.dialogCheckboxState = false;
      return;
    }
    this.dialogCheckboxState = !this.dialogCheckboxState;
  }

  @observable image = '';
  @observable title = '';

  @action setCalculatorInformation = (image, title) => {
    this.image = image;
    this.title = title;
  }

  @action getImageName = () => this.image;

  @action getInfoTitle = () => this.title;

  @persist('object') jsonResources = jsonData;
  @persist('object') @observable pctValueChangeDialogPopUpDisabled = true;
  @persist('object') @observable pctNavigateDialogPopUp = {};

  @persist('object') @observable jsonCalculatorData = {};
  @persist('object') @observable userCalculatorData = {};
  @persist @observable dataSet = '';
  @persist @observable language = '';

  onboardingImageName = 'BRAHMS_PCT.png';
  /* iosImage = {
    src: require('dist/images/BRAHMS_PCT.png'), // eslint-disable-line
    width: 414,
    height: 260
  }; */

  @action setValueChangeDialogSetting = (state) => {
    this.pctValueChangeDialogPopUpDisabled = state;
  }

  @action setNavigationDialogSetting = (location, state) => {
    this.pctNavigateDialogPopUp[location] = state;
  }

  @action setDataSet = (key) => {
    this.dataSet = key;
  }

  @action setLanguage = (key) => {
    this.language = key;
  }

  @action initializeCalculatedUSFields() {
    const lrtiCalculation = new PctClinicalEffectCalculation(this, true, 'lrti');
    const sepsisCalculation = new PctClinicalEffectCalculation(this, true, 'sepsis');

    const fieldIndex = 12;
    lrtiCalculation.getAbrBaseline(pctConstants.ClinicalEffectLrti, fieldIndex);
    sepsisCalculation.getAbrBaseline(pctConstants.ClinicalEffectSepsis, fieldIndex);
  }

  @action readJSONData = (isUS) => {
    let country = this.dataSet;
    if (country === undefined || !country.length) {
      country = !isUS ? pctConstants.CountryCodeNone : pctConstants.CountryCodeUS;
    }

    if (country === pctConstants.CountryCodeDE) {
      this.jsonCalculatorData = this.jsonResources.de;
    } else if (country === pctConstants.CountryCodeNL) {
      this.jsonCalculatorData = this.jsonResources.nl;
    } else if (country === pctConstants.CountryCodeUK) {
      this.jsonCalculatorData = this.jsonResources.uk;
    } else if (country === pctConstants.CountryCodeUS) {
      this.jsonCalculatorData = this.jsonResources.us;
      this.pctValueChangeDialogPopUpDisabled = false;
      this.userCalculatorData = {};
      this.initializeCalculatedUSFields();
      return;
    } else {
      this.jsonCalculatorData = this.jsonResources.none;
    }
    this.userCalculatorData = {};
  }

  @action resetData = () => {
    this.jsonCalculatorData = {};
    this.userCalculatorData = {};
    this.dataSet = '';
    this.language = '';
    this.pctValueChangeDialogPopUpDisabled = true;
    this.dialogCheckboxState = false;
    this.pctNavigateDialogPopUp = {};
    this.hasShownDialogStates = {};
    applicationStore.disablePctLeavingAlert();
  }

  @action getPctValue = (key, index) => {
    if (this.objectExists(key, index, false, true)) {
      return this.userCalculatorData[key][index].pct_value;
    }
    if (this.objectExists(key, index, true, true)) {
      return this.jsonCalculatorData[key][index].pct_value;
    }
    return undefined;
  }

  @action getNoPctValue = (key, index) => {
    if (this.objectExists(key, index, false, false)) {
      return this.userCalculatorData[key][index].no_pct_value;
    }
    if (this.objectExists(key, index, true, false)) {
      return this.jsonCalculatorData[key][index].no_pct_value;
    }
    return undefined;
  }

  objectExists = (key, index, isDefault, isPct) => {
    const data = isDefault ? this.jsonCalculatorData : this.userCalculatorData;
    return data[key] !== undefined
      && data[key].length >= index + 1
      && (isPct
        ? data[key][index].pct_value !== undefined
        : data[key][index].no_pct_value !== undefined);
  }

  @action setPctValue = (key, index, value) => {
    this.setValue('pct_value', key, index, value);
  }

  @action setNoPctValue = (key, index, value) => {
    this.setValue('no_pct_value', key, index, value);
  }

  @action setValue = (type, key, index, value) => {
    if (this.userCalculatorData[key] === undefined) {
      this.userCalculatorData[key] = [];
    }
    while (this.userCalculatorData[key].length <= index) {
      this.userCalculatorData[key].push({});
    }
    this.userCalculatorData[key][index][type] = value;
  }

  shiftLeftAndRound = (value, digits) => (digits ? Math.round(value * (10 ** digits)) : value)

  // floating point imprecision problem: preset values seems to be double and read values floats
  compareNumbers = (value1, value2, roundDigits) => this.shiftLeftAndRound(value1, roundDigits) === this.shiftLeftAndRound(value2, roundDigits)

  @action isUserPctValue = (key, index, roundDigits) => {
    if (!this.objectExists(key, index, false, true)) {
      return false;
    }
    if (this.compareNumbers(this.userCalculatorData[key][index].pct_value, this.jsonCalculatorData[key][index].pct_value, roundDigits)) {
      this.setPctValue(key, index, undefined);
      return false;
    }
    return true;
  }

  @action isUserNoPctValue = (key, index, roundDigits) => {
    if (!this.objectExists(key, index, false, false)) {
      return false;
    }
    if (this.compareNumbers(this.userCalculatorData[key][index].no_pct_value, this.jsonCalculatorData[key][index].no_pct_value, roundDigits)) {
      this.setNoPctValue(key, index, undefined);
      return false;
    }
    return true;
  }

  // ----- Pct Resources -----

  @action getLocalizedText = (key) => {
    let value = this.jsonResources.localized[key];
    if (value === undefined) {
      return value;
    }
    value = this.jsonResources.localized[key][this.language];
    if (value === undefined) {
      return this.jsonResources.localized[key][pctConstants.DefaultLanguageCode];
    }
    return value;
  }

  @action getText = (key) => {
    const value = this.jsonResources.unlocalized[key];
    if (value === undefined) {
      return this.getLocalizedText(key);
    }
    return value;
  }

  // ----- Pct Info Field Dialog States -----

  @persist('object') @observable hasShownDialogStates = {};

  @action hasShownDialog(fieldName, index, dataType, isUS) {
    if (this.pctValueChangeDialogPopUpDisabled) {
      return true;
    }
    try {
      return this.hasShownDialogStates[fieldName][index][dataType];
    } catch {
      this.setHasShownDialog(fieldName, index, dataType, !isUS);
      return !isUS;
    }
  }

  @action setHasShownDialog(fieldName, index, dataType, value) {
    if (this.hasShownDialogStates[fieldName] === undefined) {
      this.hasShownDialogStates[fieldName] = {};
    }
    if (this.hasShownDialogStates[fieldName][index] === undefined) {
      this.hasShownDialogStates[fieldName][index] = {};
    }
    this.hasShownDialogStates[fieldName][index][dataType] = value;
  }
}

export default new PctStore();
