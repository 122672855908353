import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';
import { simappColors } from 'shared/lib/theme';
import SimappIcon from 'web/components/_ui/simapp-icon';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.5em',
  },
  labelWrapper: {
    width: '25%',
    display: 'flex',
    position: 'relative',
    height: 32
  },
  label: {
    position: 'static',
    transform: 'none',
    alignSelf: 'center',
    whiteSpace: 'nowrap',
    width: '100%'
  },
  errorMessage: {
    whiteSpace: 'nowrap',
    position: 'absolute',
    bottom: -6
  },
  inputField: {
    flex: 1,
  },
  rootVertical: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'flex-start',
    '& textarea': {
      padding: 0,
      overflowY: 'auto'
    },
    MuiInput: {
      root: { width: '100%' }
    }
  },
  inputVertical: {
    width: '100%',
    '& div': {
      overflowY: 'auto',
      height: '100%'
    },
    '& textarea': {
      minHeight: '100%'
    }
  },
  fileRow: {
    display: 'flex'
  },
  deleteButton: {
    marginLeft: 24,
    marginRight: 15,
    color: simappColors.strawberry,
    backgroundColor: simappColors.paleGrey,
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: simappColors.steelGrey,
    }
  },
  hide: {
    display: 'none',
    '& + hr': {
      display: 'none',
    }
  },
};

const FileField = observer((props) => {
  const { fields, name, onChange } = props;
  const rootClassVertical = fields[name].vertical ? props.classes.rootVertical : null;
  const inputClassVertical = fields[name].vertical ? props.classes.inputVertical : null;
  const hideClass = (fields[name].hideWhenDisabled && (fields[name].disabled || props.disabled)) ? props.classes.hide : null;

  return (
    <FormControl
      error={!!fields[name].error}
      disabled={fields[name].disabled || props.disabled}
      required={fields[name].required}
      className={`${props.classes.root} ${rootClassVertical} ${hideClass}`}
    >
      { !!fields[name].label
      && (
        <div className={props.classes.labelWrapper}>
          <InputLabel htmlFor={name} disableAnimation shrink={false} className={props.classes.label}>{fields[name].label}</InputLabel>
          <FormHelperText id={`${name}-error`} className={props.classes.errorMessage}>{fields[name].error}</FormHelperText>
        </div>
      )
      }
      <div
        className={`${props.classes.inputField} ${inputClassVertical}`}
      >
        {fields[name].value
          ? (
            <div className={props.classes.fileRow}>
              <Typography>{fields[name].value.name}</Typography>
              <ButtonBase
                className={props.classes.deleteButton}
                title="Delete file"
                disabled={fields[name].disabled || props.disabled}
                onClick={() => { onChange({ target: { id: name, value: null, is_file: true } }); }}
              >
                <SimappIcon icon="x" />
              </ButtonBase>
            </div>
          )
          : (
            <Button
              variant="contained"
              component="label"
              color="primary"
              disabled={fields[name].disabled || props.disabled}
            >
              Select File
              <input
                type="file"
                onChange={(e) => { onChange({ target: { id: name, value: e.target.files[0], is_file: true } }); }}
                hidden
                accept={props.accept}
              />
            </Button>
          )}
      </div>
    </FormControl>
  );
});

FileField.propTypes = {
  classes: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  accept: PropTypes.string,
};

FileField.defaultProps = {
  disabled: false,
  accept: '*',
};

export default withStyles(styles)(FileField);
