import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { simappConstants } from 'shared/lib/theme';
import pctConstants from 'shared/lib/resources/pct-constants';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import HeInputField from 'web/components/he-model/he-inputfield';
import ClickableTextButton from 'web/components/_misc/clickableTextButton';
import { isPercentage, calculateValue, checkChange } from 'shared/helper/pct-helper';
import { PctClinicalEffectCalculation } from 'shared/helper/pct-calculationhelper';
import PctNavButton from './pct-navbutton';
import PctDialog from './pct-dialog';
import PctAbbreviations from './pct-abbreviations';

const styles = {
  root: {
    width: '100%',
    height: '100vH',
    display: 'flex',
    flexDirection: 'column',
    padding: simappConstants.contentMarginLarge,
    overflowX: 'hidden',
    fontFamily: 'helvetica-neue-lt, sans-serif'
  },
  borderlessExpansionPanel: {
    '&::before': { content: 'none' },
    marginBottom: '2px',
    boxShadow: 'unset'
  },
  bottomBar: {
    width: '100%',
    marginTop: '30px',
    paddingBottom: '30px'
  },
  textContainer: {
    marginTop: '40px',
    marginBottom: '60px',
    marginLeft: simappConstants.pctHorizontalMargin,
    marginRight: simappConstants.pctHorizontalMargin
  },
  tableCell: {
    textAlign: 'center',
    width: '200px',
    padding: '0 5px'
  },
  tableHeaderRow: {
    height: '29px'
  },
  expansionContent: {
    paddingLeft: '10px',
    paddingRight: '0px',
  },
  mediumText: {
    fontWeight: '500'
  },
  table: {
    marginTop: '-35px',
    marginBottom: '-25px',
    paddingLeft: '10px',
    paddingRight: '0px'
  },
  animationlessPanelSummary: {
    minHeight: '48px !important',
    lineHeight: '48px',
    '& div:last-child': {
      padding: '0'
    },
    '& div:first-child': {
      margin: '0'
    }
  },
  leftAlignedText: {
    textAlign: 'left',
    marginLeft: '-40px'
  },
  borderlessCell: {
    border: 'none'
  }
};

@inject('applicationStore', 'pctStore', 'routing') @observer
class PctClinicalEffect extends Component {
  isUS = this.props.applicationStore.presentingInName === pctConstants.CountryNameUS;
  calculation;
  lastRowText;
  tableTypeArray;

  constructor(props) {
    super(props);
    this.state = {
      showValueChangeDialog: false,
      showNavigationDialog: false,
      valueChanged: false
    };
    this.getTableTypeArray();
    this.calculation = new PctClinicalEffectCalculation(props.pctStore, this.isUS, this.getPageType());
  }

  onChangePctValue = (key, index, value) => this.onChange(key, index, value, pctConstants.DataTypePct, this.props.pctStore.setPctValue);

  onChangeNoPctValue = (key, index, value) => this.onChange(key, index, value, pctConstants.DataTypeNoPct, this.props.pctStore.setNoPctValue);

  onChange(key, index, value, type, updateStore) {
    const hasShownDialog = this.props.pctStore.hasShownDialog(key, index, pctConstants.DataTypePct, this.isUS);
    const showValueChangeDialog = value !== undefined && value !== null && !hasShownDialog;
    this.props.pctStore.setHasShownDialog(key, index, type, showValueChangeDialog || hasShownDialog);

    updateStore(key, index, value);
    this.setState({
      showValueChangeDialog,
      valueChanged: true
    });
  }

  onNavigationClick(location) {
    if (!this.isUS || !this.state.valueChanged || this.props.pctStore.pctNavigateDialogPopUp[this.props.routing.location.pathname] === false) {
      this.props.routing.push(location);
      return;
    }
    this.setState({
      showNavigationDialog: true,
      navigationDestination: location
    });
  }

  getExpansionPanelArray() {
    const panel = [];
    let index = 0;
    let value = this.props.pctStore.getText(this.getTextFieldName() + index);
    while (value !== undefined) {
      panel.push(value);
      value = this.props.pctStore.getText(this.getTextFieldName() + ++index);
    }
    return panel;
  }

  getTableRowArray(i) {
    const table = [];
    let j = 0;
    let value = this.props.pctStore.getText(this.getTextFieldName() + i + pctConstants.PctFieldText + j);
    while (value !== undefined) {
      table.push(value);
      value = this.props.pctStore.getText(this.getTextFieldName() + i + pctConstants.PctFieldText + ++j);
    }
    return table;
  }

  getTableTypeArray() {
    this.tableTypeArray = [];
    this.tableTypeArray.push([pctConstants.PctTableRowDays, pctConstants.PctTableRowDays]);
    this.tableTypeArray.push([pctConstants.PctTableRowPercentage, pctConstants.PctTableRowPercentage]);
    this.tableTypeArray.push([pctConstants.PctTableRowDays, pctConstants.PctTableRowDays]);
    this.tableTypeArray.push([pctConstants.PctTableRowPercentage, pctConstants.PctTableRowNone, pctConstants.PctTableRowPercentage]);
    this.tableTypeArray.push([pctConstants.PctTableRowNone, pctConstants.PctTableRowNone]);
    this.tableTypeArray.push([pctConstants.PctTableRowPercentage, pctConstants.PctTableRowPercentage, pctConstants.PctTableRowNone]);
    this.tableTypeArray.push([pctConstants.PctTableRowPercentage, pctConstants.PctTableRowNone]);
  }

  getScreenNumber() {
    const type = this.getPageType();
    return type === 'lrti' ? 3 : 4;
  }

  getPageType() {
    if (this.isUS) {
      const url = this.props.location.pathname;
      // cut out the first (host) part, because it could contain 'lrti'
      return url.substring(url.indexOf('/')).includes('lrti') ? 'lrti' : 'sepsis';
    }
    return undefined;
  }

  getDataFieldName() {
    if (this.isUS) {
      const type = this.getPageType();
      if (type === 'lrti') {
        return pctConstants.ClinicalEffectLrti;
      }
      return pctConstants.ClinicalEffectSepsis;
    }
    return pctConstants.ClinicalEffectField;
  }

  getTextFieldName() {
    if (this.isUS) {
      const type = this.getPageType();
      if (type === 'lrti') {
        return pctConstants.PctClinicalEffectTableHeadText;
      }
      return pctConstants.PctClinicalEffectSepsisTableHeadText;
    }
    return pctConstants.PctClinicalEffectTableHeadText;
  }

  getHeadTextFieldName() {
    if (this.isUS) {
      const type = this.getPageType();
      if (type === 'lrti') {
        return pctConstants.PctClinicalEffectHeadText;
      }
      return pctConstants.PctClinicalEffectSepsisHeadText;
    }
    return pctConstants.PctClinicalEffectHeadText;
  }

  render() {
    const { push } = this.props.routing;
    let i = -1;
    this.state.valueChanged = checkChange(this.props.pctStore, this.getDataFieldName(), '12');
    const expansionPanel = this.getExpansionPanelArray();
    const dataField = this.getDataFieldName();
    let headline = this.props.pctStore.getText(pctConstants.PctClinicalEffectHeadText);
    if (this.isUS) {
      headline = headline.replace('{0}', this.getPageType() === 'lrti' ? pctConstants.PctLrti : pctConstants.PctSepsis);
    }
    return (
      <div className={this.props.classes.root} key={this.getPageType()}>
        <div className={this.props.classes.textContainer}>
          <Typography align="center" variant="subtitle1" className={this.isUS ? this.props.classes.leftAlignedText : undefined}>{headline}</Typography>
        </div>
        <div>
          {expansionPanel.map((text, index0) => {
            const tableRow = this.getTableRowArray(index0);
            const index = index0;
            return (
              <ExpansionPanel key={index} CollapseProps={{ timeout: 0 }} className={this.props.classes.borderlessExpansionPanel}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={this.props.classes.animationlessPanelSummary}>
                  <Typography variant="body1" className={this.props.classes.mediumText}>{text}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={this.props.classes.expansionContent}>
                  <Table className={this.props.classes.table}>
                    <TableHead>
                      <TableRow className={this.props.classes.tableHeaderRow}>
                        <TableCell />
                        <TableCell className={this.props.classes.tableCell}>{this.props.pctStore.getText(pctConstants.PctWithoutPct)}</TableCell>
                        <TableCell className={this.props.classes.tableCell}>{this.props.pctStore.getText(pctConstants.PctWithPct)}</TableCell>
                        <TableCell className={this.props.classes.tableCell}>{this.props.pctStore.getText(pctConstants.PctDifference)}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableRow.map((rowText, index1) => {
                        i++;
                        const tableRowType = this.tableTypeArray[index0][index1];
                        const rowValues = this.calculation.getRowValues(tableRowType, rowText, dataField, i, this.lastRowText);
                        this.lastRowText = rowText;
                        const standardIsInput = rowValues[0];
                        const pctIsInput = rowValues[1];
                        const isValuePercentage = isPercentage(this.tableTypeArray[index0][index1]);
                        const valueWithout = rowValues[2];
                        const valueWith = rowValues[3];
                        const rowIndex = `${index0}${index1}`;
                        const maxValue = tableRowType === pctConstants.PctTableRowPercentage ? 1 : -1;
                        const isLastRow = index0 === expansionPanel.length - 1 && index1 === tableRow.length - 1;
                        return (
                          <TableRow key={rowIndex}>
                            <TableCell className={isLastRow ? this.props.classes.borderlessCell : ''}>
                              <Typography variant="body1">{rowText}</Typography>
                            </TableCell>
                            <TableCell className={`${this.props.classes.tableCell} ${isLastRow ? this.props.classes.borderlessCell : ''}`}>
                              <HeInputField
                                fieldName={dataField}
                                fieldIndex={i}
                                isUSNumberInput={this.isUS}
                                displayType={standardIsInput ? 'input' : 'text'}
                                value={isValuePercentage ? valueWithout / 100 : valueWithout}
                                onValueChange={(key, fieldIndex, value) => this.onChangeNoPctValue(key, fieldIndex, value)}
                                isChanged={this.props.pctStore.isUserNoPctValue(dataField, i, isValuePercentage ? 4 : 2)}
                                type={this.tableTypeArray[index0][index1]}
                                maxValue={maxValue}
                                textAlign={this.isUS ? 'right' : 'center'}
                              />
                            </TableCell>
                            <TableCell className={`${this.props.classes.tableCell} ${isLastRow ? this.props.classes.borderlessCell : ''}`}>
                              <HeInputField
                                fieldName={dataField}
                                fieldIndex={i}
                                isUSNumberInput={this.isUS}
                                displayType={pctIsInput ? 'input' : 'text'}
                                value={isValuePercentage ? valueWith / 100 : valueWith}
                                onValueChange={(key, fieldIndex, value) => this.onChangePctValue(key, fieldIndex, value)}
                                isChanged={this.props.pctStore.isUserPctValue(dataField, i, isValuePercentage ? 4 : 2)}
                                type={this.tableTypeArray[index0][index1]}
                                maxValue={maxValue}
                                textAlign={this.isUS ? 'right' : 'center'}
                              />
                            </TableCell>
                            <TableCell className={`${this.props.classes.tableCell} ${isLastRow ? this.props.classes.borderlessCell : ''}`}>
                              <HeInputField
                                fieldName={dataField}
                                fieldIndex={i}
                                isUSNumberInput={this.isUS}
                                displayType="text"
                                value={isValuePercentage ? calculateValue(valueWith, valueWithout) / 100 : calculateValue(valueWith, valueWithout)}
                                type={this.tableTypeArray[index0][index1]}
                                allowNegative
                                textAlign={this.isUS ? 'right' : 'center'}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            );
          })}
        </div>
        <div><PctAbbreviations cDiff abr hidden={!this.isUS} /></div>
        <div className={this.props.classes.bottomBar}>
          <ClickableTextButton onClick={() => this.props.routing.push('/he-models/pct-calculator/references')} text={this.props.pctStore.getText(pctConstants.PctReferences)} />
          <PctNavButton
            nextText={this.props.pctStore.getText(pctConstants.PctNextStep)}
            backText={this.props.pctStore.getText(pctConstants.PctPreviousStep)}
            backButton
            backOnClick={() => {
              if (this.getPageType() === 'lrti') {
                this.onNavigationClick('/he-models/pct-calculator/healthcare');
              } else if (this.getPageType() === 'sepsis') {
                this.onNavigationClick('/he-models/pct-calculator/clinicaleffect-lrti');
              } else {
                push('/he-models/pct-calculator/healthcare');
              }
            }
            }
            nextOnClickLocation={this.getPageType() === 'lrti' ? '/he-models/pct-calculator/clinicaleffect-sepsis' : '/he-models/pct-calculator/results'}
            nextOnClick={(location) => {
              this.onNavigationClick(location);
            }}
            isUS={this.isUS}
            screenNumber={this.getScreenNumber()}
          />
          {this.isUS
            ? (
              <div>
                <PctDialog
                  trigger={this.state.showNavigationDialog && this.state.valueChanged}
                  message={this.props.pctStore.getText('PctValueChangeNavigationDialogText')}
                  okText={this.props.pctStore.getText('PctValueChangeDialogOkText')}
                  cancelText={this.props.pctStore.getText('PctNavigationAlertCancel')}
                  onOk={() => {
                    this.props.pctStore.setNavigationDialogSetting(this.props.routing.location.pathname, false);
                    push(this.state.navigationDestination);
                    this.state.showNavigationDialog = false;
                  }}
                  onCancel={() => {
                    this.setState({
                      showValueChangeDialog: false,
                      showNavigationDialog: false
                    });
                  }}
                />
                <PctDialog
                  trigger={this.state.showValueChangeDialog}
                  message={this.props.pctStore.getText('PctValueChangeDialogText')}
                  okText={this.props.pctStore.getText('PctValueChangeDialogOkText')}
                  hasCheckbox
                  checkboxMessage={this.props.pctStore.getText('PctValueChangeDialogCheckboxText')}
                  onOk={(state) => {
                    this.props.pctStore.setValueChangeDialogSetting(state);
                    this.setState({
                      showValueChangeDialog: false,
                      showNavigationDialog: false
                    });
                  }}
                />
              </div>
            )
            : ''
          }
        </div>
        <PctDialog />
      </div>
    );
  }
}

PctClinicalEffect.wrappedComponent.propTypes = {
  applicationStore: PropTypes.object.isRequired,
  pctStore: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  routing: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withStyles(styles)(PctClinicalEffect);
