import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';
import simappColors from 'shared/lib/theme/simapp-colors';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      border: `1px solid ${simappColors.placeholderGrey}`,
    },
  },
};

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    padding: '0.5em',
  },
  labelWrapper: {
    display: 'flex',
    position: 'relative',
  },
  label: {
    position: 'static',
    transform: 'none',
    alignSelf: 'center',
    whiteSpace: 'nowrap'
  },
  errorMessage: {
    whiteSpace: 'nowrap',
    position: 'absolute',
    bottom: -14
  },
  select: {
    // width: 250
  },
  placeholder: {
    color: simappColors.placeholderGrey,
    fontSize: '1rem',
    padding: '4px 6px',
  }
});

const SelectField = observer(({ fields, name, onChange, currentValue, list, valueField, classes, disabled, labelWidth }) => (
  <FormControl
    error={!!fields[name].error}
    disabled={fields[name].disabled || disabled}
    required={fields[name].required}
    className={classes.root}
  >
    <div className={classes.labelWrapper} style={{ width: labelWidth }}>
      <InputLabel htmlFor={name} disableAnimation shrink={false} className={classes.label}>{fields[name].label}</InputLabel>
      <FormHelperText id={`${name}-error`} className={classes.errorMessage}>{fields[name].error}</FormHelperText>
    </div>
    { !!list.length && (
      <Select
        className={classes.select}
        disableUnderline
        value={list}
        onChange={(e) => { onChange(e.target.value); }}
        renderValue={() => currentValue || fields[name].placeholder}
        MenuProps={MenuProps}
      >
        {list.map(p => (
          <MenuItem key={p.id} value={p.id}>
            <ListItemText primary={p[valueField]} />
          </MenuItem>
        ))}
      </Select>
    )}
    { !list.length && (
      <Typography className={classes.placeholder}>{fields[name].unavailableInfo}</Typography>
    )}
  </FormControl>
));

SelectField.propTypes = {
  classes: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
  currentValue: PropTypes.string,
  valueField: PropTypes.string,
  disabled: PropTypes.bool,
  labelWidth: PropTypes.string,
};

SelectField.defaultProps = {
  currentValue: '',
  valueField: 'value',
  disabled: false,
  labelWidth: '25%',
};

export default withStyles(styles)(SelectField);
