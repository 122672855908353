import React from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'shared/lib/helpers';
import simappIcons from './simapp-icons';

const SimappIcon = (props) => {
  const Icon = simappIcons[`${capitalize(props.icon)}Icon`];

  return (
    <span // eslint-disable-line
      style={{ lineHeight: props.height !== null ? `${props.height}px` : 0 }}
      className={props.className}
      onClick={props.onClick}
      title={props.title}
    >
      { props.width !== null && props.height !== null
        ? <Icon style={{ color: 'currentColor' }} width={props.width} height={props.height} />
        : <Icon style={{ color: 'currentColor' }} />
      }
    </span>
  );
};

SimappIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number
};

SimappIcon.defaultProps = {
  className: '',
  title: '',
  onClick: () => {},
  width: null,
  height: null
};

export default SimappIcon;
