import React from 'react';
import PropTypes from 'prop-types';

const LogoutIcon = props => (
  <svg width={24} height={24} viewBox="0 0 19 19" fill={props.style.color} {...props}>
    <path d="M5.473 4.402a.998.998 0 0 1 1.39.258.998.998 0 0 1-.258 1.39A6.002 6.002 0 0 0 10 17a6.002 6.002 0 0 0 3.473-10.895 1 1 0 0 1 1.16-1.628 8 8 0 1 1-9.16-.074zM9 1.996C9 1.446 9.445 1 10 1c.55 0 1 .457 1 .996v8.008c0 .55-.445.996-1 .996-.55 0-1-.457-1-.996zm0 0" />
  </svg>
);

const EnvelopeIcon = props => (
  <svg width={24} height={15.6} {...props}>
    <path d="M0 0v15.6h24V0zm12 9.125L1.742.72h20.516zM8.845 7.533L.72 14.19V.875zm.606.496L12 10.117l2.549-2.088 8.361 6.851H1.09zm5.704-.496L23.28.875V14.19z" />
  </svg>
);

const DashboardIcon = props => (
  <svg width={24} height={24} fill={props.style.color} {...props}>
    <defs>
      <clipPath id="c">
        <path d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12s4.477 10 10 10 10-4.477 10-10zM0 12C0 5.371 5.371 0 12 0s12 5.371 12 12-5.371 12-12 12S0 18.629 0 12zm0 0" />
      </clipPath>
      <clipPath id="d">
        <path d="M4 4h16v17H4zm0 0" />
      </clipPath>
      <clipPath id="e">
        <path d="M18.555 10.098a.984.984 0 0 0-.996.976.986.986 0 1 0 1.972.02.989.989 0 0 0-.976-.996zm-.7-1.047a.987.987 0 1 0-.896-1.76.987.987 0 0 0 .896 1.76zM14.48 6.914a.991.991 0 0 0 1.38-.226.995.995 0 0 0-.227-1.38.99.99 0 0 0-1.38.227.991.991 0 0 0 .227 1.38zm-2.601-.566a.995.995 0 0 0 1.117-.836.988.988 0 0 0-1.953-.282.987.987 0 0 0 .836 1.118zm-2.566.699a.997.997 0 0 0 .605-1.258.997.997 0 0 0-1.262-.605.988.988 0 1 0 .656 1.863zM7.354 8.852a.991.991 0 0 0-.046-1.395.987.987 0 1 0 .047 1.395zM5.77 10.14a.996.996 0 0 0-1.22.687.987.987 0 1 0 1.219-.688zm6.136 5.632a1.663 1.663 0 0 0 1.348-2.636l1.254-4.106c.039-.125-.008-.254-.098-.3-.098-.043-.226.003-.297.105l-2.488 3.633a1.664 1.664 0 0 0 .281 3.305zm5.598.727h-3.246a.474.474 0 0 0-.328.133 2.811 2.811 0 0 1-1.934.762c-.723 0-1.406-.27-1.93-.762a.474.474 0 0 0-.328-.133H6.496a.5.5 0 0 0-.45.305.567.567 0 0 0 .067.566A7.387 7.387 0 0 0 12 20.25a7.381 7.381 0 0 0 5.887-2.879.567.567 0 0 0 .066-.566.5.5 0 0 0-.45-.305zm0 0" />
      </clipPath>
    </defs>
    <g clipPath="url(#b)">
      <g id="a">
        <g clipPath="url(#c)">
          <path d="M0 0h24v24H0V0z" />
        </g>
        <g clipPath="url(#d)">
          <g
            clipRule="evenodd"
            clipPath="url(#e)"
          >
            <path d="M0 0h24v24H0V0z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const MarketingMaterialsIcon = props => (
  <svg width={24} height={24} fill={props.style.color} {...props}>
    <path d="M20.34 0H5.992c-.062 0-.12.012-.18.02a1.28 1.28 0 0 0-.82.332L.426 4.582a1.28 1.28 0 0 0-.403.988.953.953 0 0 0-.023.215v16.942C0 23.426.574 24 1.273 24h14.344a1.92 1.92 0 0 0 1.914-1.914V6.426a1.92 1.92 0 0 0-1.914-1.914H4.27l2.261-2.09h12.164c.657 0 1.196.535 1.196 1.195v15.848l.449.004a1.896 1.896 0 0 0 1.914-1.895V1.914C22.254.864 21.39 0 20.34 0" />
  </svg>
);

const PctIcon = props => (
  <svg width={24} height={24} fill={props.style.color} {...props}>
    <path d="M15.34 0H5.992c-.062 0-.12.012-.18.02a1.28 1.28 0 0 0-.82.332L.426 4.582a1.28 1.28 0 0 0-.403.988.953.953 0 0 0-.023.215v16.942C0 23.426.574 24 1.273 24h14.344a1.92 1.92 0 0 0 1.914-1.914V6.426a1.92 1.92 0 0 0-1.914-1.914H4.27l2.261-2.09h12.164c.657 0 1.196.535 1.196 1.195v15.848l.449.004a1.896 1.896 0 0 0 1.914-1.895V1.914C22.254.864 21.39 0 20.34 0" />
  </svg>
);

const SettingsIcon = props => (
  <svg width={16} height={16} fill={props.style.color} {...props}>
    <defs>
      <clipPath id="a">
        <path d="M15.7 9.176c.163-.047.3-.23.3-.399V7.223a.442.442 0 0 0-.297-.399l-1.625-.488a.705.705 0 0 1-.402-.383l-.215-.52a.686.686 0 0 1 .012-.554l.804-1.492a.447.447 0 0 0-.074-.496L13.11 1.797a.447.447 0 0 0-.496-.074l-1.496.804a.702.702 0 0 1-.555.016l-.515-.215a.705.705 0 0 1-.383-.402L9.176.296A.448.448 0 0 0 8.777 0H7.223a.448.448 0 0 0-.399.297l-.488 1.629a.712.712 0 0 1-.383.406l-.512.211a.686.686 0 0 1-.554-.016l-1.5-.804a.447.447 0 0 0-.496.074L1.797 2.89a.447.447 0 0 0-.074.496l.808 1.5a.686.686 0 0 1 .012.554l-.211.508a.682.682 0 0 1-.402.383l-1.633.492A.442.442 0 0 0 0 7.223v1.554c0 .168.133.352.297.399l1.629.488c.164.05.347.223.406.383l.211.512a.686.686 0 0 1-.016.554l-.804 1.5a.447.447 0 0 0 .074.496l1.094 1.094a.447.447 0 0 0 .496.074l1.496-.804a.702.702 0 0 1 .554-.016l.516.215c.16.058.332.242.383.406l.488 1.621c.047.164.23.301.399.301h1.554a.446.446 0 0 0 .399-.3l.488-1.622a.705.705 0 0 1 .383-.402l.52-.215a.702.702 0 0 1 .554.012l1.492.804a.447.447 0 0 0 .496-.074l1.094-1.094a.447.447 0 0 0 .074-.496l-.8-1.492a.686.686 0 0 1-.016-.555l.215-.52a.7.7 0 0 1 .406-.382zM8 11.41a3.41 3.41 0 0 0 0-6.82A3.412 3.412 0 0 0 4.586 8 3.412 3.412 0 0 0 8 11.41zm0 0" />
      </clipPath>
    </defs>
    <g
      clipPath="url(#a)"
      clipRule="evenodd"
    >
      <path d="M0 0v16h16V0H0zm8.25 4A3.75 3.75 0 0 1 12 7.75a3.75 3.75 0 0 1-3.75 3.75A3.75 3.75 0 0 1 4.5 7.75 3.75 3.75 0 0 1 8.25 4z" />
    </g>
  </svg>
);
const EditIcon = props => (
  <svg width={24} height={24} fill={props.style.color} {...props}>
    <defs>
      <clipPath id="f">
        <path d="M5 5h14v14H5zm0 0" />
      </clipPath>
      <clipPath id="g">
        <path d="M5 19h2.918l7.965-8.07-2.918-2.914L5 16.082zM18.773 8.145a.78.78 0 0 0 0-1.098l-1.82-1.82a.78.78 0 0 0-1.098 0l-1.421 1.425 2.914 2.914zm0 0" />
      </clipPath>
    </defs>
    <g clipPath="url(#f)">
      <g
        clipPath="url(#g)"
        clipRule="evenodd"
      >
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
);

const StarIcon = props => (
  <svg width={24} height={24} fill={props.style.color} {...props}>
    <path d="M12.879 16.914c-.484-.254-1.277-.254-1.758 0l-1.95 1.023c-.487.258-.804.02-.71-.515l.371-2.168c.094-.54-.152-1.293-.543-1.672l-1.578-1.535c-.395-.383-.266-.758.273-.836l2.18-.316c.543-.079 1.18-.543 1.422-1.032l.976-1.972c.243-.493.637-.489.876 0l.976 1.972c.242.492.883.953 1.422 1.032l2.18.316c.543.078.664.457.273.836l-1.578 1.535c-.395.383-.637 1.137-.543 1.672l.371 2.168c.094.539-.226.77-.71.515zm0 0" />
  </svg>
);

const SearchIcon = props => (
  <svg width={24} height={24} {...props}>
    <defs>
      <clipPath id="h">
        <path d="M2 2h17v17H2zm0 0" />
      </clipPath>
      <clipPath id="i">
        <path d="M10.25 18.5a8.25 8.25 0 1 1 0-16.5 8.25 8.25 0 1 1 0 16.5zm0 0" />
      </clipPath>
    </defs>
    <g clipPath="url(#h)">
      <g
        clipPath="url(#i)"
        clipRule="evenodd"
      >
        <path
          d="M10.25 18.5a8.25 8.25 0 1 1 0-16.5 8.25 8.25 0 1 1 0 16.5zm0 0"
          fill="none"
          strokeWidth="4"
          strokeLinejoin="round"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
      </g>
    </g>
    <path
      d="M14.844 15.844l5.523 5.523"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="currentColor"
      strokeMiterlimit="10"
    />
  </svg>
);

const EmptyIcon = props => (
  <svg width={88} height={88} {...props}>
    <path
      d="M20.691 1h-1.707c-.168 0-.324.027-.484.047a3.408 3.408 0 0 0-2.184.89L4.136 13.223a3.37 3.37 0 0 0-1.074 2.629c-.039.183-.062.378-.062.578v45.172A3.41 3.41 0 0 0 6.395 65H31.28l12.625-.11c2.809 0 5.106-2.3 5.106-5.105V18.031c0-2.808-2.297-5.11-5.106-5.11l-29.515.114 6.293-6.058 26.98-.051h5.188a3.2 3.2 0 0 1 3.191 3.187v42.79l1.195.011a5.056 5.056 0 0 0 5.106-5.05V6.104C62.344 3.297 60.043 1 57.238 1zm0 0"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="#d2d3d5"
      strokeDasharray="4,4"
      strokeMiterlimit="10"
    />
  </svg>
);

const XIcon = props => (
  <svg width={24} height={24} {...props}>
    <g
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      stroke="currentColor"
    >
      <path d="M8.168 8.168l7.664 7.664M15.832 8.168l-7.664 7.664" />
    </g>
  </svg>
);

const PresentationIcon = props => (
  <svg width={24} height={24} {...props}>
    <defs>
      <clipPath id="q">
        <path d="M5 16h14v8H5zm0 0" />
      </clipPath>
      <clipPath id="j">
        <path d="M12.707 16.293a1 1 0 0 0-1.414 0l-6 6a1 1 0 1 0 1.414 1.414l6-6h-1.414l6 6a1 1 0 1 0 1.414-1.414zm0 0" />
      </clipPath>
      <clipPath id="k">
        <path d="M2 0h20v18H2zm0 0" />
      </clipPath>
      <clipPath id="l">
        <path d="M21 2l-1-1v14.008a.994.994 0 0 1-.996.992H4.996A.994.994 0 0 1 4 15.008V1L3 2zM2 0v15.008A2.993 2.993 0 0 0 4.996 18h14.008A2.993 2.993 0 0 0 22 15.008V0zm0 0" />
      </clipPath>
      <clipPath id="m">
        <path d="M0 0h24v2H0zm0 0" />
      </clipPath>
      <clipPath id="n">
        <path d="M23 1H1h22zM1 0h22a1 1 0 1 1 0 2H1a1 1 0 1 1 0-2zm0 0" />
      </clipPath>
      <clipPath id="o">
        <path d="M6 5h12v8H6zm0 0" />
      </clipPath>
      <clipPath id="p">
        <path d="M12.445 9.754h1.414l-2.304-2.309-.707-.707-4.555 4.555 1.414 1.414 3.848-3.848H10.14l2.304 2.309.707.707.707-.707 3.848-3.844-1.414-1.414zm0 0" />
      </clipPath>
    </defs>
    <g clipPath="url(#q)">
      <g clipPath="url(#j)">
        <path
          d="M0 29h24V11H0zm0 0"
          fill={props.style.color}
        />
      </g>
    </g>
    <g clipPath="url(#k)">
      <g clipPath="url(#l)">
        <path
          d="M-3 23h30V-5H-3zm0 0"
          fill={props.style.color}
        />
      </g>
    </g>
    <g clipPath="url(#m)">
      <g clipPath="url(#n)">
        <path
          d="M-5 7h34V-5H-5zm0 0"
          fill={props.style.color}
        />
      </g>
    </g>
    <g clipPath="url(#o)">
      <g clipPath="url(#p)">
        <path
          d="M1.293 17.707h21.414V.91H1.293zm0 0"
          fill={props.style.color}
        />
      </g>
    </g>
  </svg>
);

const MainMenuIcon = props => (
  <svg width={24} height={24} {...props}>
    <path
      d="M0 0h24v24H0z"
      fill="none"
    />
    <path
      fill={props.style.color}
      d="M2.604 6.402h18.651v2.367H2.604zM2.604 12.414h18.651v2.367H2.604zM2.604 18.189h18.651v2.367H2.604z"
    />
  </svg>
);

const HeartEmptyIcon = props => (
  <svg width={24} height={24} {...props}>
    <defs>
      <clipPath id="r">
        <path d="M3 5h18v15H3zm0 0" />
      </clipPath>
      <clipPath id="s">
        <path d="M3 9.117c0 2.985 1.93 4.985 9 10.61 7.07-5.625 9-7.625 9-10.61C21 6.847 19.105 5 16.777 5c-2.132 0-3.246 1.238-4.136 2.23L12 7.934l-.64-.704C10.468 6.238 9.354 5 7.222 5 4.895 5 3 6.848 3 9.117zm0 0" />
      </clipPath>
    </defs>
    <g clipPath="url(#r)">
      <g
        clipPath="url(#s)"
        clipRule="evenodd"
      >
        <path
          d="M3 9.117c0 2.985 1.93 4.985 9 10.61 7.07-5.625 9-7.625 9-10.61C21 6.847 19.105 5 16.777 5c-2.132 0-3.246 1.238-4.136 2.23L12 7.934l-.64-.704C10.468 6.238 9.354 5 7.222 5 4.895 5 3 6.848 3 9.117zm0 0"
          fill="none"
          strokeWidth="2"
          strokeLinecap="round"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
      </g>
    </g>
  </svg>
);

const HeartFilledIcon = props => (
  <svg width={24} height={24} {...props}>
    <defs>
      <clipPath id="r">
        <path d="M3 5h18v15H3zm0 0" />
      </clipPath>
      <clipPath id="s">
        <path d="M3 9.117c0 2.985 1.93 4.985 9 10.61 7.07-5.625 9-7.625 9-10.61C21 6.847 19.105 5 16.777 5c-2.132 0-3.246 1.238-4.136 2.23L12 7.934l-.64-.704C10.468 6.238 9.354 5 7.222 5 4.895 5 3 6.848 3 9.117zm0 0" />
      </clipPath>
    </defs>
    <g clipPath="url(#r)">
      <g
        clipPath="url(#s)"
        clipRule="evenodd"
      >
        <path
          fill={props.style.color}
          d="M0 0h24v24H0z"
        />
      </g>
    </g>
  </svg>
);

const ShareIcon = props => (
  <svg width={24} height={24} {...props}>
    <g
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="currentColor"
      strokeMiterlimit="10"
    >
      <path d="M11.5 15.5v-13M7 7l4.5-4.5L16 7M15.5 10.5h2.992a1.01 1.01 0 0 1 1.008.996v8.008c0 .55-.445.996-1 .996h-14c-.55 0-1-.457-1-.996v-8.008c0-.55.441-.996 1.008-.996H7.5" />
    </g>
  </svg>
);

const MaterialMenuEmptyIcon = props => (
  <svg width={24} height={24} {...props}>
    <defs>
      <clipPath id="t">
        <path d="M1 9h6v6H1zm0 0" />
      </clipPath>
      <clipPath id="u">
        <path d="M4 15a3 3 0 1 0-.002-6.002A3 3 0 0 0 4 15zm0 0" />
      </clipPath>
      <clipPath id="v">
        <path d="M9 9h6v6H9zm0 0" />
      </clipPath>
      <clipPath id="w">
        <path d="M12 15a3 3 0 1 0-.002-6.002A3 3 0 0 0 12 15zm0 0" />
      </clipPath>
      <clipPath id="x">
        <path d="M17 9h6v6h-6zm0 0" />
      </clipPath>
      <clipPath id="y">
        <path d="M20 15a3 3 0 1 0-.002-6.002A3 3 0 0 0 20 15zm0 0" />
      </clipPath>
    </defs>
    <g clipPath="url(#t)">
      <g
        clipPath="url(#u)"
        clipRule="evenodd"
      >
        <path
          d="M4 15a3 3 0 1 0-.002-6.002A3 3 0 0 0 4 15zm0 0"
          fill="none"
          strokeWidth="2"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
      </g>
    </g>
    <g clipPath="url(#v)">
      <g
        clipPath="url(#w)"
        clipRule="evenodd"
      >
        <path
          d="M12 15a3 3 0 1 0-.002-6.002A3 3 0 0 0 12 15zm0 0"
          fill="none"
          strokeWidth="2"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
      </g>
    </g>
    <g clipPath="url(#x)">
      <g
        clipPath="url(#y)"
        clipRule="evenodd"
      >
        <path
          d="M20 15a3 3 0 1 0-.002-6.002A3 3 0 0 0 20 15zm0 0"
          fill="none"
          strokeWidth="2"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
      </g>
    </g>
  </svg>
);

const MaterialMenuFilledIcon = props => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill={props.style.color} {...props}>
    <defs>
      <clipPath id="bo">
        <path d="M1 9h6v6H1zm0 0" />
      </clipPath>
      <clipPath id="bp">
        <path d="M4 15a3 3 0 1 0-.002-6.002A3 3 0 0 0 4 15zm0 0" />
      </clipPath>
      <clipPath id="bq">
        <path d="M9 9h6v6H9zm0 0" />
      </clipPath>
      <clipPath id="br">
        <path d="M12 15a3 3 0 1 0-.002-6.002A3 3 0 0 0 12 15zm0 0" />
      </clipPath>
      <clipPath id="bs">
        <path d="M17 9h6v6h-6zm0 0" />
      </clipPath>
      <clipPath id="bt">
        <path d="M20 15a3 3 0 1 0-.002-6.002A3 3 0 0 0 20 15zm0 0" />
      </clipPath>
    </defs>
    <g clipPath="url(#bo)">
      <g clipPath="url(#bp)" clipRule="evenodd">
        <path d="M-4 4h16v16H-4zm0 0" fill="currentColor" />
      </g>
    </g>
    <g clipPath="url(#bq)">
      <g clipPath="url(#br)" clipRule="evenodd">
        <path d="M4 4h16v16H4zm0 0" fill="currentColor" />
      </g>
    </g>
    <g clipPath="url(#bs)">
      <g clipPath="url(#bt)" clipRule="evenodd">
        <path d="M12 4h16v16H12zm0 0" fill="currentColor" />
      </g>
    </g>
  </svg>
);

const ConsumablesCalculatorIcon = props => (
  <svg width={24} height={24} {...props}>
    <defs>
      <clipPath id="af">
        <path d="M1 1h22v21H1zm0 0" />
      </clipPath>
      <clipPath id="ag">
        <path d="M9 4v4c.016 1.5-7.656 8.898-7 11 .746 2.102.047 3 7 3h6c6.953 0 6.254-.898 7-3 .656-2.102-7.008-9.5-7-11V4h.004c.543 0 .996-.445.996-1V2c0-.559-.445-1-.996-1H8.996C8.453 1 8 1.445 8 2v1c0 .559.445 1 .996 1zm0 0" />
      </clipPath>
      <clipPath id="ab">
        <path d="M8 3v4c.016 1.5-7.656 8.898-7 11 .746 2.102.047 3 7 3h6c6.953 0 6.254-.898 7-3 .656-2.102-7.008-9.5-7-11V3h.004c.543 0 .996-.445.996-1V1c0-.559-.445-1-.996-1H7.996C7.453 0 7 .445 7 1v1c0 .559.445 1 .996 1zm0 0" />
      </clipPath>
      <clipPath id="ac">
        <path d="M0 13h22v8H0zm0 0" />
      </clipPath>
      <clipPath id="ad">
        <path d="M8 3v4c.016 1.5-7.656 8.898-7 11 .746 2.102.047 3 7 3h6c6.953 0 6.254-.898 7-3 .656-2.102-7.008-9.5-7-11V3h.004c.543 0 .996-.445.996-1V1c0-.559-.445-1-.996-1H7.996C7.453 0 7 .445 7 1v1c0 .559.445 1 .996 1zm0 0" />
      </clipPath>
      <clipPath id="ae">
        <path d="M3 14s1.977 2 8 0 8 0 8 0l4 8H-1zm0 0" />
      </clipPath>
      <clipPath id="aa">
        <path d="M0 0h22v21H0z" />
      </clipPath>
      <g id="ah" clipPath="url(#aa)">
        <g clipPath="url(#ab)" clipRule="evenodd">
          <path d="M8 3v4c.016 1.5-7.656 8.898-7 11 .746 2.102.047 3 7 3h6c6.953 0 6.254-.898 7-3 .656-2.102-7.008-9.5-7-11V3h.004c.543 0 .996-.445.996-1V1c0-.559-.445-1-.996-1H7.996C7.453 0 7 .445 7 1v1c0 .559.445 1 .996 1zm0 0" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" stroke="currentColor" strokeMiterlimit="10" />
        </g>
        <g clipPath="url(#ac)">
          <g clipPath="url(#ad)">
            <g clipPath="url(#ae)" clipRule="evenodd">
              <path d="M-6 8.11h34V27H-6zm0 0" fill={props.style.color} />
            </g>
          </g>
        </g>
      </g>
    </defs>
    <path d="M9 4v4c.016 1.5-7.656 8.898-7 11 .746 2.102.047 3 7 3h6c6.953 0 6.254-.898 7-3 .656-2.102-7.008-9.5-7-11V4h.004c.543 0 .996-.445.996-1V2c0-.559-.445-1-.996-1H8.996C8.453 1 8 1.445 8 2v1c0 .559.445 1 .996 1zm0 0" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" stroke="currentColor" strokeMiterlimit="10" />
    <g clipPath="url(#af)">
      <g clipPath="url(#ag)" clipRule="evenodd">
        <use href="#ah" transform="translate(1 1)" />
      </g>
    </g>
  </svg>
);

const PctCalculatorIcon = props => (
  <svg width={24} height={24} {...props}>
    <defs>
      <clipPath id="ba">
        <path d="M0 8h7v8H0zm0 0" />
      </clipPath>
      <clipPath id="bb">
        <path d="M2 12c0-.691.559-1.25 1.25-1.25S4.5 11.309 4.5 12s-.559 1.25-1.25 1.25S2 12.691 2 12zm4.5 0A3.25 3.25 0 1 0 0 12a3.25 3.25 0 0 0 6.5 0zm0 0" />
      </clipPath>
      <clipPath id="bc">
        <path d="M5 11h19v2H5zm0 0" />
      </clipPath>
      <clipPath id="bd">
        <path d="M23 13c.55 0 1-.45 1-1s-.45-1-1-1H6.492c-.55 0-1 .45-1 1s.45 1 1 1zm0 0" />
      </clipPath>
      <clipPath id="be">
        <path d="M19 8h5v8h-5zm0 0" />
      </clipPath>
      <clipPath id="bf">
        <path d="M23.336 12.742a.997.997 0 0 0 0-1.484l-2.668-2.403a1 1 0 1 0-1.336 1.489l2.664 2.398v-1.484l-2.664 2.398a1.001 1.001 0 0 0 1.336 1.489zm0 0" />
      </clipPath>
      <clipPath id="bg">
        <path d="M7 11h12v9H7zm0 0" />
      </clipPath>
      <clipPath id="bh">
        <path d="M11.734 19.465c.176.328.516.535.887.535h4.824c.551 0 1-.45 1-1s-.449-1-1-1h-4.824l.887.535-3.637-6.933a1 1 0 1 0-1.77.93zm0 0" />
      </clipPath>
      <clipPath id="bi">
        <path d="M5 4h9v10H5zm0 0" />
      </clipPath>
      <clipPath id="bj">
        <path d="M11.281 5.469L10.398 6h2.54c.55 0 1-.45 1-1s-.45-1-1-1h-2.54c-.367 0-.707.203-.882.531l-3.73 7a1.005 1.005 0 0 0 .413 1.356c.485.258 1.09.074 1.352-.414zm0 0" />
      </clipPath>
      <clipPath id="bk">
        <path d="M15 16h6v6h-6zm0 0" />
      </clipPath>
      <clipPath id="bl">
        <path d="M17.75 17.75l-1 1h2.5l-1-1v2.5l1-1h-2.5l1 1zm-1 3.5h2.5c.55 0 1-.45 1-1v-2.5c0-.55-.45-1-1-1h-2.5c-.55 0-1 .45-1 1v2.5c0 .55.45 1 1 1zm0 0" />
      </clipPath>
      <clipPath id="bm">
        <path d="M12 2h6v6h-6zm0 0" />
      </clipPath>
      <clipPath id="bn">
        <path d="M15 4.75c.137 0 .25.113.25.25s-.113.25-.25.25a.252.252 0 0 1-.25-.25c0-.137.113-.25.25-.25zm0 2.5a2.25 2.25 0 1 0-.001-4.501A2.25 2.25 0 0 0 15 7.25zm0 0" />
      </clipPath>
    </defs>
    <g clipPath="url(#ba)">
      <g clipPath="url(#bb)">
        <path d="M-5 3.75h16.5v16.5H-5zm0 0" fill={props.style.color} />
      </g>
    </g>
    <g clipPath="url(#bc)">
      <g clipPath="url(#bd)">
        <path d="M.492 6H29v12H.492zm0 0" fill={props.style.color} />
      </g>
    </g>
    <g clipPath="url(#be)">
      <g clipPath="url(#bf)">
        <path d="M14 3.602h14.668v16.796H14zm0 0" fill={props.style.color} />
      </g>
    </g>
    <g clipPath="url(#bg)">
      <g clipPath="url(#bh)">
        <path d="M2.988 6.066h20.457V25H2.988zm0 0" fill={props.style.color} />
      </g>
    </g>
    <g clipPath="url(#bi)">
      <g clipPath="url(#bj)">
        <path d="M.668-1h18.27v19.004H.668zm0 0" fill={props.style.color} />
      </g>
    </g>
    <g clipPath="url(#bk)">
      <g clipPath="url(#bl)">
        <path d="M10.75 11.75h14.5v14.5h-14.5zm0 0" fill={props.style.color} />
      </g>
    </g>
    <g clipPath="url(#bm)">
      <g clipPath="url(#bn)">
        <path d="M7.75-2.25h14.5v14.5H7.75zm0 0" fill={props.style.color} />
      </g>
    </g>
  </svg>
);

const ClinicalIcon = props => (
  <svg viewBox="0 0 90 89" fill={props.style.color} {...props}>
    <path d="M30.9 1.6c-.8 1-1.4 4.1-1.4 7.3 0 4.9.2 5.6 2 5.6 1.7 0 2-.8 2.3-5.3l.3-5.2h21.8l.3 5.2c.3 4.5.6 5.3 2.3 5.3 1.8 0 2-.7 2-5.6C60.5.3 60 0 45 0 34.4 0 32 .3 30.9 1.6zM0 53.5V89h90V18H0v35.5zm14 0V85H4V22h10v31.5zm58 0V85H18V22h54v31.5zm14 0V85H76V22h10v31.5z" />
    <path d="M43 41v9h-9c-8.9 0-9 0-9 2.5s.1 2.5 9 2.5h9v9c0 8.3.1 9 2 9s2-.7 2-9v-9h8c7.9 0 8 0 8-2.5s-.1-2.5-8-2.5h-8v-9c0-8.3-.1-9-2-9s-2 .7-2 9z" />
  </svg>
);

const TechnicalIcon = props => (
  <svg viewBox="0 0 120 98" fill={props.style.color} {...props}>
    <path d="M89.5 3c-.8 3.7-3.7 5.6-5.5 3.5-1.9-2.3-5.4-1.8-8.1 1.1-2.3 2.4-2.3 2.6-.7 5.4 2.1 3.7 1.3 7-1.6 7-3.1 0-4.6 1.9-4.6 5.5 0 3.7 1.5 5.5 4.6 5.5 3.1 0 4.1 3.5 1.8 6.1-3.7 4 3 10.8 7.6 7.6 3-2.1 5.5-1.1 6.6 2.6.8 3 1.2 3.2 5.4 3.2s4.5-.2 4.8-2.8c.4-3.6 3.7-5.4 6.1-3.3 2.4 2.2 4.4 2 6.9-.7 2.5-2.6 2.8-4.8 1-7.6-1.6-2.7-.4-4.7 3.2-5.4 3-.7 3.1-.8 2.8-5.4-.3-4.3-.6-4.8-2.9-5.1-3.3-.4-5.5-4.5-3.3-6.2.8-.7 1.4-2.2 1.4-3.5 0-4.4-6.3-7.3-9-4.1-1.7 2.2-5.8 0-6.2-3.3-.3-2.3-.8-2.6-5-2.9-4.4-.3-4.6-.2-5.3 2.8zm6.7 3.7c.4 3.5 7.7 6.5 10.9 4.4 2.7-1.6 3.4-.9 1.8 1.8-2.1 3.2.9 10.5 4.4 10.9 3.5.4 3.4 1.5-.2 2.9-3.7 1.3-6.2 8.2-4.3 11.4 1.5 2.4 1.5 3.6 0 2.1-3.2-3.2-12.8.1-12.8 4.3 0 3.4-1.6 3.1-2.3-.4-.4-2.1-1.5-3.2-3.8-4-4-1.4-7.6-1.4-8.4-.1-.4.6-1.1.8-1.6.4-.6-.4-.4-1.3.7-2.5 1.6-1.8 1.6-2.1 0-6.3-1.4-3.5-2.3-4.6-4.7-5.1-3.7-.8-3.8-2.3-.2-2.7 2.1-.2 3.2-1.3 4.7-4.3 1.7-3.7 1.7-4.2.2-6.9s-1.5-2.8.1-2.1C86.3 12.9 94 10.3 94 6c0-3 1.8-2.4 2.2.7z" />
    <path d="M91.3 18c-4.5 1.8-5.8 9-2.3 12.8 3.1 3.3 9.2 3 11.9-.4 3.4-4.3 2.1-10.2-2.6-12.3-2.8-1.3-4-1.3-7-.1zM33.1 20.9c-1.3 1.3-2.1 3.4-2.1 5.4 0 3.1-.4 3.5-4 4.7-3.7 1.2-4.2 1.2-6.3-.8-3.2-3-6.8-2.8-10 .6-3.3 3.4-3.4 6.8-.5 9.9 2 2.1 2 2.6.8 6.3-1.3 4-2.1 4.6-8 5.2-2.3.3-2.5.7-2.8 6.5-.3 6.8-.4 6.7 6.8 7.5 1.9.2 2.8 1.2 4 4.5 1.3 4 1.3 4.3-.8 6.6-3 3.2-2.8 5.8 1.1 9.5 4 3.9 5.3 4 8.8.6 2.7-2.6 2.9-2.6 6.6-1.1 3.3 1.3 3.9 2 4.5 5.8.9 5.1 1.9 5.9 8 5.9 5.3 0 6.8-1.5 6.8-6.8 0-2.4.7-3.2 4-4.7l3.9-1.8 3.1 2.7c4.5 3.8 5.5 3.6 10.2-2.2 2.9-3.5 2.8-5.2-.5-8.9-1.8-2.1-1.9-2.4-.4-6 1.3-3.4 1.9-3.9 5.7-4.4 4.9-.7 6-2 6-7.4 0-5-1.4-6.5-5.7-6.5-2.3 0-3.7-.7-4.7-2.3-2.8-4.5-2.9-6.6-.2-9.8 1.4-1.7 2.6-3.5 2.6-3.9 0-.5-1.4-2.5-3.1-4.5-3.6-4.1-7.1-4.6-10.6-1.4-2.1 2.1-2.5 2.1-6 .8-3.4-1.3-3.9-1.9-4.4-5.7-.3-2.3-.8-4.4-1.2-4.8-.3-.3-2.6-.9-5-1.1-3.6-.4-4.9-.1-6.6 1.6zm8.9 7.2c0 3.9.1 4.1 5.5 6.5 6.9 3 7.6 3 11.3-.1 2.9-2.4 2.9-2.4 4.7-.5 1.8 2 1.8 2.1-.2 4.3-3.3 3.7-3.4 4.3-1.2 10.1 2.2 6.1 4 7.6 8.9 7.6 2.8 0 3.1.3 2.8 2.7-.3 2.5-.8 2.9-4.5 3.4-2.4.3-4.5.7-4.7 1-.2.2-1.4 2.8-2.5 5.6-2.2 5.5-2 7.5 1.1 10.9 1.8 2 1.8 2.1-.2 3.9-2 1.8-2.1 1.8-4.3-.3-3-2.8-6.1-2.8-11.7 0-4.2 2.1-4.5 2.6-4.8 6.5-.3 4-.5 4.3-3.3 4.3-2.5 0-2.9-.4-2.9-2.9 0-4.7-1-6.2-5.7-8.2-5.8-2.5-9.1-2.4-12 .3-2.2 2.1-2.3 2.1-4.3.3-1.9-1.8-1.9-1.8.5-4.7 3.1-3.7 3.1-4.4.1-11.3C12.2 62.1 12 62 8.1 62S4 61.8 4 59c0-2.7.3-3 3.3-3 4.1 0 5.9-1.9 8.3-8.7l1.8-5.2-2.3-3.2c-2.1-2.8-2.2-3.3-.8-4.6 1.4-1.4 1.8-1.4 4.3.6 3.4 2.7 4.7 2.6 11-.3 5.2-2.4 6.4-3.9 6.4-8.2 0-2 .5-2.4 3-2.4 2.8 0 3 .2 3 4.1z" />
    <path d="M35 47.7c-.8.3-2.7 1.7-4.2 3.1-8 7.4-1.5 20.4 9.5 18.9 7.8-1.1 11.9-9.5 8.2-16.6-2.3-4.5-8.8-7.1-13.5-5.4zm9 7c2.5 3.2 2 6.9-1.2 9.5-2.7 2.3-4.9 2.3-8.2-.3-3.1-2.4-3.5-7.2-.9-10.1 2.5-2.8 7.7-2.4 10.3.9z" />
  </svg>
);

const EconomicalIcon = props => (
  <svg viewBox="0 0 86 86" fill={props.style.color} {...props}>
    <path d="M35.8 1C1.5 7-10.9 50.1 14.7 74.1c8.7 8.1 18.1 11.3 31.3 10.7 7.4-.3 9.8-.9 15.6-3.8C86.9 68.6 93.5 35.8 75 14.7 65.6 4 49.9-1.5 35.8 1zm19.6 5.7C69 11.4 78.2 22.2 81 36.6c3.1 15.8-6.2 33.5-21.7 41.1-5.2 2.5-6.8 2.8-15.8 2.8-9.3 0-10.5-.2-16.6-3.2-8-3.9-15-11.1-18.7-19.1-2.3-4.9-2.7-7-2.7-15.2 0-8.5.3-10.2 3.1-16C17.2 9 37.2.2 55.4 6.7z" />
    <path d="M35.3 23c-3.5 2.1-5.3 4.9-6.3 9.5-.4 2-1.3 3.1-3 3.5-3.1.7-3.3 3.4-.2 3.8 1.7.3 2.2 1 2.2 3.2s-.5 2.9-2.2 3.2c-3.1.4-2.9 3.1.2 3.8 1.7.4 2.6 1.5 3.1 3.8 1.8 9.1 11.2 13.6 20.3 9.8 3.5-1.5 7.6-6.3 7.6-9 0-2.4-3.7-2-4.5.5-.8 2.5-6.2 5.9-9.3 5.9-4.8 0-10.1-4.5-10.2-8.7 0-2.1.4-2.2 9.2-2.5 7.9-.3 9.3-.6 9.6-2.1.3-1.5-.7-1.7-9.7-1.7H32v-5.9l9.7-.3c8.4-.3 9.8-.6 10.1-2.1.3-1.5-.7-1.7-9.7-1.7h-10l1-3.3C34.4 28 38.3 25 43.2 25c3.5 0 4.6.6 8 4.2 3 3.3 4.1 3.9 5.1 2.9 1.6-1.6-.8-5.9-5.2-8.8-4.3-2.9-11.3-3.1-15.8-.3z" />
  </svg>
);

const EmotionalIcon = props => (
  <svg viewBox="0 0 83 83" fill={props.style.color} {...props}>
    <path d="M28.3 2C16 6 5.7 16.7 1.9 29.2-5.5 53.3 10.4 78.5 35 81.9 63 85.7 85.7 63 81.9 35 80 21.7 71.8 10.5 59.5 4.4 53.1 1.2 51 .7 43.3.4 36.4.1 33.2.4 28.3 2zm27.2 6.4c7.6 3.4 13.9 9.7 17.7 17.5 2.9 5.8 3.3 7.5 3.3 15.1s-.4 9.3-3.2 15.1c-3.9 7.9-10.2 14-18.1 17.7-4.8 2.3-7 2.7-14.2 2.7-7-.1-9.5-.5-14.2-2.7-7.9-3.7-14.2-9.8-18.1-17.7-2.9-5.9-3.2-7.5-3.2-15.1 0-7 .5-9.5 2.7-14.1C16.7 8.4 37.7.2 55.5 8.4z" />
    <path d="M17.4 43.5c-1.1 2.7 1.8 11.6 5 15.8 1.8 2.5 5.4 5.4 8.6 7 8.9 4.6 18.6 3 25.9-4.4 4.4-4.3 6.2-8.2 6.8-14.6.6-5 .4-5.5-1.3-5.1-1.4.2-2.1 1.5-2.6 5.2-1.4 9.8-9.1 16.7-18.6 16.7-10.7.1-17.7-6.5-19.7-18.4-.6-3.8-3-5.1-4.1-2.2z" />
    <circle cx="19.766" cy="28.918" r="3.266" fill="currentColor" />
    <circle cx="62.748" cy="29.146" r="3.266" fill="currentColor" />
  </svg>
);

const TrashIcon = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill={props.style.color} {...props}>
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" stroke="currentColor" strokeMiterlimit="10">
      <path d="M4.5 5.5h14v15c0 .55-.45 1-1.008 1H5.508a1.001 1.001 0 0 1-1.008-1zm0 0M7.5 3.5c0-.555.453-1 .996-1h6.008c.55 0 .996.441.996 1v2h-8zm0 0M3 5.5h17M9.5 9v8M13.5 9v8" />
    </g>
  </svg>
);

const QCIcon = props => (
  <svg width={24} height={24} viewBox="0 0 217 293" {...props}>
    <g transform="translate(0.000000,293.000000) scale(0.10000,-0.100000)" fill={props.style.color} stroke="none">
      <path d="M148 2920 c-50 -15 -93 -48 -116 -88 l-22 -40 0 -1326 c0 -1302 0 -1328 20 -1366 11 -21 34 -49 52 -62 l33 -23 950 -3 c523 -1 960 0 973 3 37 9 101 74 112 113 13 50 14 2624 0 2671 -11 39 -51 85 -95 109 -27 15 -114 17 -955 19 -509 1 -937 -2 -952 -7z m1707 -169 c51 -23 102 -81 110 -123 3 -18 5 -148 3 -289 -3 -250 -3 -256 -27 -290 -13 -19 -40 -46 -60 -60 l-36 -24 -751 0 -751 0 -41 29 c-77 53 -82 75 -82 369 0 280 3 298 56 351 57 57 29 55 814 55 680 1 727 -1 765 -18z m-919 -965 c62 -37 64 -48 64 -325 l0 -250 -31 -35 c-17 -20 -47 -40 -67 -46 -48 -13 -456 -13 -504 0 -20 6 -50 26 -67 46 l-31 35 0 249 c0 265 3 287 48 316 46 30 75 33 317 31 215 -2 243 -4 271 -21z m862 1 c18 -10 42 -32 52 -50 18 -29 20 -52 20 -270 0 -263 -3 -276 -62 -319 -32 -23 -37 -23 -288 -23 -248 0 -256 1 -291 23 -62 38 -69 70 -69 320 1 346 -4 342 370 339 205 -2 239 -4 268 -20z m-868 -812 c15 -8 38 -27 49 -41 20 -25 21 -38 21 -276 0 -359 16 -342 -315 -346 -282 -4 -305 -1 -350 44 l-35 35 0 257 c0 283 0 284 64 323 28 17 53 19 284 19 204 0 259 -3 282 -15z m870 0 c15 -8 38 -30 49 -48 20 -32 21 -48 21 -280 l0 -246 -31 -36 c-17 -19 -47 -40 -67 -46 -24 -7 -124 -9 -279 -7 -226 3 -243 4 -270 24 -58 43 -63 67 -63 313 0 203 2 229 20 265 35 74 41 75 333 76 208 0 263 -3 287 -15z" />
      <path d="M655 2671 c-133 -35 -205 -136 -205 -286 0 -125 53 -221 146 -264 60 -29 166 -38 244 -21 52 11 63 11 78 -2 9 -9 35 -25 58 -36 l41 -21 22 47 c13 25 19 48 14 49 -4 2 -22 12 -39 22 l-32 19 26 52 c18 38 27 73 30 129 8 143 -41 242 -146 291 -51 24 -182 36 -237 21z m170 -151 c41 -39 54 -156 26 -232 -6 -17 -9 -16 -41 6 -52 34 -76 40 -83 19 -2 -10 -8 -25 -11 -34 -5 -11 4 -20 31 -34 30 -14 34 -19 20 -23 -33 -7 -83 8 -105 31 -12 13 -27 49 -33 81 -20 96 11 185 71 209 31 13 101 -1 125 -23z" />
      <path d="M1302 2666 c-120 -39 -182 -135 -182 -281 0 -183 110 -295 290 -295 89 0 151 23 197 73 34 37 73 113 61 121 -1 1 -34 12 -73 23 l-70 21 -23 -45 c-32 -61 -90 -80 -151 -48 -74 38 -79 231 -8 291 26 22 38 25 76 21 42 -5 81 -32 92 -66 3 -6 32 -4 81 7 l76 17 -19 39 c-44 90 -106 127 -224 132 -51 3 -95 -1 -123 -10z" />
      <path d="M590 1625 l0 -95 -100 0 -100 0 0 -60 0 -60 100 0 100 0 0 -95 0 -95 60 0 60 0 0 95 0 95 100 0 100 0 0 60 0 60 -100 0 -100 0 0 95 0 95 -60 0 -60 0 0 -95z" />
      <path d="M1300 1470 l0 -60 220 0 220 0 0 60 0 60 -220 0 -220 0 0 -60z" />
      <path d="M467 832 c-20 -21 -37 -42 -37 -47 0 -6 30 -36 66 -69 l66 -59 -68 -68 -69 -69 42 -42 42 -41 70 62 71 63 70 -63 71 -63 42 43 42 42 -69 66 -70 67 67 62 c37 34 67 66 67 71 0 5 -18 27 -40 48 l-40 39 -70 -69 -70 -69 -68 67 c-37 37 -70 67 -73 67 -3 0 -22 -17 -42 -38z" />
      <path d="M1304 806 c-3 -7 -4 -33 -2 -57 l3 -44 213 -3 212 -2 0 60 0 60 -210 0 c-170 0 -212 -3 -216 -14z" />
      <path d="M1300 525 l0 -54 215 -3 215 -3 0 58 0 57 -215 0 -215 0 0 -55z" />
    </g>
  </svg>
);

const MCIcon = props => (
  <svg width={24} height={24} viewBox="0 0 400 400" {...props}>
    <g fill={props.style.color} stroke="none">
      <path d="M51.650 47.063 L 44.848 52.914 44.848 197.299 L 44.848 341.684 50.370 347.205 L 55.892 352.727 200.277 352.727 L 344.662 352.727 350.737 345.665 C 357.904 337.333,357.095 326.268,348.815 319.394 C 344.088 315.469,333.922 315.152,213.065 315.152 L 82.424 315.152 82.424 185.653 C 82.424 46.133,82.695 50.003,72.554 44.759 C 63.124 39.882,59.539 40.278,51.650 47.063 M300.782 86.388 C 288.635 101.830,310.406 121.286,323.564 106.747 C 332.688 96.664,325.812 80.000,312.527 80.000 C 308.154 80.000,304.051 82.231,300.782 86.388 M244.232 146.061 C 228.392 158.161,247.352 182.224,263.703 170.772 C 270.495 166.014,271.027 152.806,264.658 147.042 C 259.402 142.286,249.780 141.823,244.232 146.061 M302.150 146.681 C 288.396 160.435,305.027 181.737,321.230 171.120 C 333.588 163.023,327.392 143.030,312.525 143.030 C 308.827 143.030,304.158 144.673,302.150 146.681 M188.255 164.433 C 176.912 176.967,191.707 196.694,206.575 188.862 C 213.753 185.081,215.675 182.024,215.720 174.315 C 215.796 161.558,196.982 154.790,188.255 164.433 M134.545 211.382 C 119.468 219.932,125.031 240.000,142.478 240.000 C 156.877 240.000,162.629 222.509,151.188 213.509 C 143.947 207.814,141.410 207.489,134.545 211.382 M188.016 223.570 C 176.681 239.753,196.119 258.107,210.452 244.754 C 216.943 238.706,217.370 227.665,211.324 222.194 C 204.823 216.311,192.595 217.032,188.016 223.570 M112.596 270.163 C 107.430 278.048,108.900 285.743,116.678 291.528 C 126.974 299.185,140.606 292.043,140.606 278.990 C 140.606 264.762,120.323 258.370,112.596 270.163 " />
    </g>
  </svg>
);

const ServiceIcon = props => (
  <svg width={24} height={24} viewBox="0 0 85.333336 85.333336" {...props}>
    <defs id="defs6">
      <clipPath id="clipPath18" clipPathUnits="userSpaceOnUse">
        <path id="path16" d="M 0,64 H 64 V 0 H 0 Z" />
      </clipPath>
    </defs>
    <g fill={props.style.color} transform="matrix(1.3333333,0,0,-1.3333333,0,85.333333)" id="g10">
      <g id="g12">
        <g clipPath="url(#clipPath18)" id="g14">
          <g transform="translate(16.814,30.8085)" id="g20">
            <path
              id="path22"
              d="M 0,0 -5.995,5.571 8.997,21.703 19.063,12.348 21.821,15.323 10.488,25.855 C 8.32,27.87 4.899,27.744 2.884,25.576 L -10.304,11.385 c -2.016,-2.17 -1.888,-5.588 0.28,-7.602 l 7.277,-6.763 z"
            />
          </g>
          <g transform="translate(49.3517,15.6005)" id="g24">
            <path
              id="path26"
              d="M 0,0 C -0.873,-0.939 -2.345,-0.995 -3.286,-0.12 -4.227,0.755 -4.28,2.227 -3.407,3.166 -2.533,4.107 -1.061,4.163 -0.119,3.288 0.822,2.413 0.874,0.941 0,0 M 7.908,13.222 -3.53,23.853 -6.289,20.878 2.743,12.485 -12.249,-3.647 l -4.823,4.482 -2.747,-2.98 7.215,-6.705 c 2.17,-2.017 5.586,-1.893 7.602,0.276 L 8.186,5.617 c 2.016,2.169 1.893,5.588 -0.278,7.605"
            />
          </g>
          <g transform="translate(37.0101,34.3645)" id="g28">
            <path
              id="path30"
              d="m 0,0 7.9,8.518 c 1.065,1.154 2.306,2.133 3.676,2.901 l 0.827,0.464 c 1.035,0.58 1.972,1.32 2.777,2.193 l 3.056,3.311 c 0.403,0.437 0.376,1.118 -0.061,1.521 l -3.544,3.269 c -0.436,0.403 -1.117,0.376 -1.52,-0.061 L 10.055,18.805 C 9.25,17.932 8.588,16.938 8.093,15.86 L 7.697,14.998 C 7.042,13.571 6.165,12.256 5.1,11.101 L -2.8,2.584 Z"
            />
          </g>
          <g transform="translate(26.6945,19.9708)" id="g32">
            <path
              id="path34"
              d="m 0,0 -8.654,-9.392 c -0.404,-0.438 -1.092,-0.465 -1.53,-0.062 -0.437,0.404 -0.465,1.092 -0.061,1.53 l 8.654,9.392 C -1.187,1.906 -0.499,1.933 -0.062,1.53 0.376,1.126 0.404,0.438 0,0 m -14.555,-5.421 c -0.437,0.404 -0.465,1.092 -0.061,1.53 l 8.654,9.392 c 0.404,0.438 1.092,0.465 1.53,0.061 0.437,-0.403 0.465,-1.092 0.061,-1.529 l -8.654,-9.392 c -0.404,-0.438 -1.092,-0.466 -1.53,-0.062 M 6.022,3.09 C 5.498,3.573 5.466,4.389 5.949,4.913 L 6.535,5.548 C 7.018,6.072 7.834,6.105 8.358,5.622 8.831,5.185 9.575,5.215 10.012,5.688 l 2.464,2.671 c 0.437,0.473 0.407,1.218 -0.066,1.654 L 3.022,18.675 C 2.549,19.111 1.805,19.081 1.368,18.608 L -1.096,15.937 C -1.533,15.464 -1.503,14.72 -1.03,14.283 -0.506,13.8 -0.473,12.984 -0.956,12.46 l -0.587,-0.635 c -0.483,-0.524 -1.299,-0.557 -1.822,-0.073 -0.474,0.436 -1.218,0.406 -1.654,-0.067 l -12.527,-13.59 c -2.174,-2.355 -2.025,-6.061 0.331,-8.234 l 2.542,-2.346 c 2.355,-2.173 6.061,-2.024 8.234,0.331 L 6.088,1.436 C 6.525,1.909 6.495,2.654 6.022,3.09"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const CmtKeyIcon = props => (
  <svg
    x="0px"
    y="0px"
    width={24}
    height={24}
    viewBox="0 0 162.058 162.058"
    {...props}
  >
    <path
      style={{ fill: props.style.color, strokeWidth: '4px', stroke: props.style.color, strokeOpacity: 1, strokeLinejoin: 'round' }}
      d="M24.077 162.058c-.076 0-.153-.003-.229-.011l-6.04-.556a2.497 2.497 0 0 1-1.539-.722L3.844 148.344l-.029-.029-.029-.029-1.814-1.814a2.504 2.504 0 0 1-.721-2.012l1.139-11.621a2.5 2.5 0 0 1 .721-1.524l66.918-66.918c-6.97-17.515-2.953-37.221 10.462-50.635 18.347-18.348 48.199-18.348 66.546 0 8.888 8.887 13.783 20.704 13.783 33.273s-4.895 24.386-13.783 33.273c-13.414 13.416-33.12 17.433-50.635 10.462l-8.704 8.704a2.498 2.498 0 0 1-1.768.732h-5.113v5.112a2.5 2.5 0 0 1-.732 1.768l-7.057 7.057a2.498 2.498 0 0 1-1.768.732h-3.557v3.557a2.5 2.5 0 0 1-.732 1.768l-7.612 7.612a2.498 2.498 0 0 1-1.768.732H46.255v11.336a2.5 2.5 0 0 1-.732 1.768l-2.167 2.167a2.498 2.498 0 0 1-1.768.732h-4.001v4.001a2.5 2.5 0 0 1-.732 1.768l-11.01 11.011a2.508 2.508 0 0 1-1.768.731zm-4.911-5.463 3.973.366 9.449-9.449v-5.465a2.5 2.5 0 0 1 2.5-2.5h5.465l.703-.703v-12.8a2.5 2.5 0 0 1 2.5-2.5h12.8l6.148-6.148v-5.021a2.5 2.5 0 0 1 2.5-2.5h5.021l5.592-5.592v-6.577a2.5 2.5 0 0 1 2.5-2.5h6.577l9.158-9.158a2.5 2.5 0 0 1 2.774-.521c16 7.037 34.322 3.6 46.675-8.755 7.943-7.943 12.318-18.504 12.318-29.738s-4.375-21.794-12.318-29.738C127.104.9 100.423.899 84.025 17.296 71.671 29.65 68.235 47.971 75.27 63.972a2.5 2.5 0 0 1-.521 2.774l-67.47 67.471-.771 7.869 70.82-70.82a2.498 2.498 0 0 1 3.535 0 2.498 2.498 0 0 1 0 3.535L9.118 146.546l10.048 10.049zM129.104 42.68a10.917 10.917 0 0 1-7.769-3.218 10.913 10.913 0 0 1-3.218-7.769c0-2.935 1.143-5.693 3.218-7.769s4.834-3.218 7.769-3.218c2.935 0 5.694 1.143 7.769 3.218a10.915 10.915 0 0 1 3.218 7.769c0 2.935-1.143 5.694-3.218 7.769a10.917 10.917 0 0 1-7.769 3.218zm0-16.973c-1.599 0-3.103.623-4.233 1.753s-1.753 2.634-1.753 4.233.623 3.103 1.753 4.233c1.131 1.131 2.634 1.754 4.233 1.754s3.103-.623 4.233-1.753c1.131-1.131 1.754-2.634 1.754-4.233s-.623-3.103-1.753-4.233-2.635-1.754-4.234-1.754z"
    />
  </svg>
);

const CmtMenuIcon = props => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 85.333 85.333"
    {...props}
  >
    <defs>
      <clipPath id="ab" clipPathUnits="userSpaceOnUse">
        <path d="M0 64h64V0H0z" />
      </clipPath>
    </defs>
    <g clipPath="url(#ab)" transform="matrix(1.33333 0 0 -1.33333 0 85.333)">
      <path fill={props.style.color} d="M6.048 40.18H7.63v7.017H6.048a1.483 1.483 0 0 1-1.478-1.478v-4.06c0-.813.666-1.478 1.478-1.478" />
      <path fill={props.style.color} d="M56.682 16.523c-.473-.964-1.606-1.643-3.362-2.034.217.546.287 1.073.318 1.321l1.075 7.858c1.454-2.383 2.878-5.292 1.969-7.145M52.32 14.307a17.003 17.003 0 0 0-.61-.075c.207.023.415.045.61.074m-29.05 7.158c-.102.005-.171.11-.151.24l3.119 18.892L52.02 42.49c.183.03.315-.089.294-.26L49.35 20.308zm16.208-8.066c-8.875 1.017-18.713 2.238-27.564 3.361 0 0 6.838 1.398 9.174 1.886 8.35-.685 18.136-1.463 26.3-2.188a593.325 593.325 0 0 0-7.91-3.059m15.66 13.32l-.006.01 2.209 16.14c.13 1.044-.208 2.036-.928 2.724-.766.733-1.873 1.045-3.046.864l-8.53-.74V53.5c0 1.65-1.35 3-3 3H10.632c-1.651 0-3-1.35-3-3v-6.304h1.613c.813 0 1.478-.665 1.478-1.478v-4.06c0-.813-.665-1.478-1.478-1.478H7.63v-8.604h14.156l-.126-.736-.216-1.264H7.63v-.602c0-1.65 1.349-3 3-3h10.2a408.127 408.127 0 0 1-.864-5.314L5.625 17.543a1.5 1.5 0 0 1-1.18-1.4l-.055-1.245a1.946 1.946 0 0 1 1.104-1.837 1.54 1.54 0 0 1 .403-.128L35.58 7.915c.842-.136 1.536-.216 2.204-.216 1.072 0 2.08.207 3.536.725l.304.128c3.32 1.39 5.145 2.153 8.326 3.556a29.475 29.475 0 0 0-.905-.015 1 1 0 0 0 0 2c.789 0 1.517.032 2.193.09-.057-.005-.107-.014-.164-.017a1.001 1.001 0 0 1 .154-1.995c3.878.3 6.25 1.434 7.25 3.471 1.683 3.43-1.34 8.031-3.34 11.076" />
      <path fill="#555" d="M36.602 27.729c-1.44 0-2.961-.551-4.284-1.552a.845.845 0 0 1-.31-.447.551.551 0 0 1 .066-.462l.429-.612a.503.503 0 0 1 .423-.205c.152 0 .316.053.462.16.853.63 1.82.976 2.726.976.925 0 1.715-.337 2.284-.973.208-.232.61-.213.906.044l.705.61a.824.824 0 0 1 .275.463.523.523 0 0 1-.105.445c-.862 1.002-2.133 1.553-3.577 1.553" />
      <path fill="#555" d="M37.63 32.234c-2.57 0-5.26-1.022-7.572-2.878-.303-.245-.403-.641-.224-.895l.503-.713a.5.5 0 0 1 .406-.203c.177-.016.353.062.504.182 1.795 1.43 3.876 2.218 5.86 2.218 1.985 0 3.708-.788 4.85-2.22a.505.505 0 0 1 .405-.181h.016a.81.81 0 0 1 .5.204l.827.714c.294.254.375.649.184.894-1.468 1.856-3.69 2.878-6.258 2.878" />
      <path fill="#555" d="M38.634 36.645c-3.667 0-7.492-1.482-10.771-4.175-.296-.242-.392-.633-.216-.885l.454-.65a.502.502 0 0 1 .412-.204.814.814 0 0 1 .509.188c2.78 2.277 6.017 3.53 9.114 3.53 3.095 0 5.759-1.253 7.5-3.53a.509.509 0 0 1 .413-.188h.01a.809.809 0 0 1 .506.205l.75.649c.292.25.375.643.189.886-2.052 2.693-5.202 4.175-8.87 4.175" />
    </g>
  </svg>
);

LogoutIcon.propTypes = {
  style: PropTypes.any.isRequired
};
EnvelopeIcon.propTypes = {
  style: PropTypes.any.isRequired
};
DashboardIcon.propTypes = {
  style: PropTypes.any.isRequired
};
MarketingMaterialsIcon.propTypes = {
  style: PropTypes.any.isRequired
};
PctIcon.propTypes = {
  style: PropTypes.any.isRequired
};
SettingsIcon.propTypes = {
  style: PropTypes.any.isRequired
};
EditIcon.propTypes = {
  style: PropTypes.any.isRequired
};
StarIcon.propTypes = {
  style: PropTypes.any.isRequired
};
SearchIcon.propTypes = {
  style: PropTypes.any.isRequired
};
EmptyIcon.propTypes = {
  style: PropTypes.any.isRequired
};
XIcon.propTypes = {
  style: PropTypes.any.isRequired
};
PresentationIcon.propTypes = {
  style: PropTypes.any.isRequired
};
MainMenuIcon.propTypes = {
  style: PropTypes.any.isRequired
};
HeartEmptyIcon.propTypes = {
  style: PropTypes.any.isRequired
};
HeartFilledIcon.propTypes = {
  style: PropTypes.any.isRequired
};
ShareIcon.propTypes = {
  style: PropTypes.any.isRequired
};
MaterialMenuEmptyIcon.propTypes = {
  style: PropTypes.any.isRequired
};
MaterialMenuFilledIcon.propTypes = {
  style: PropTypes.any.isRequired
};
ConsumablesCalculatorIcon.propTypes = {
  style: PropTypes.any.isRequired
};
PctCalculatorIcon.propTypes = {
  style: PropTypes.any.isRequired
};
ClinicalIcon.propTypes = {
  style: PropTypes.any.isRequired
};
TechnicalIcon.propTypes = {
  style: PropTypes.any.isRequired
};
EconomicalIcon.propTypes = {
  style: PropTypes.any.isRequired
};
EmotionalIcon.propTypes = {
  style: PropTypes.any.isRequired
};
TrashIcon.propTypes = {
  style: PropTypes.any.isRequired
};

QCIcon.propTypes = {
  style: PropTypes.any.isRequired
};

MCIcon.propTypes = {
  style: PropTypes.any.isRequired
};

ServiceIcon.propTypes = {
  style: PropTypes.any.isRequired
};

CmtKeyIcon.propTypes = {
  style: PropTypes.any.isRequired
};

CmtMenuIcon.propTypes = {
  style: PropTypes.any.isRequired
};

export {
  LogoutIcon,
  EnvelopeIcon,
  DashboardIcon,
  MarketingMaterialsIcon,
  PctIcon,
  SettingsIcon,
  EditIcon,
  StarIcon,
  SearchIcon,
  EmptyIcon,
  XIcon,
  PresentationIcon,
  MainMenuIcon,
  HeartEmptyIcon,
  HeartFilledIcon,
  ShareIcon,
  MaterialMenuEmptyIcon,
  MaterialMenuFilledIcon,
  ConsumablesCalculatorIcon,
  PctCalculatorIcon,
  ClinicalIcon,
  TechnicalIcon,
  EconomicalIcon,
  EmotionalIcon,
  TrashIcon,
  QCIcon,
  MCIcon,
  ServiceIcon,
  CmtKeyIcon,
  CmtMenuIcon
};

export default {
  LogoutIcon,
  EnvelopeIcon,
  DashboardIcon,
  MarketingMaterialsIcon,
  PctIcon,
  SettingsIcon,
  EditIcon,
  StarIcon,
  SearchIcon,
  EmptyIcon,
  XIcon,
  PresentationIcon,
  MainMenuIcon,
  HeartEmptyIcon,
  HeartFilledIcon,
  ShareIcon,
  MaterialMenuEmptyIcon,
  MaterialMenuFilledIcon,
  ConsumablesCalculatorIcon,
  PctCalculatorIcon,
  ClinicalIcon,
  TechnicalIcon,
  EconomicalIcon,
  EmotionalIcon,
  TrashIcon,
  QCIcon,
  MCIcon,
  ServiceIcon,
  CmtKeyIcon,
  CmtMenuIcon
};
