import { action, observable, runInAction, toJS } from 'mobx';
import { persist } from 'mobx-persist';
import rest from 'shared/lib/rest';
import { merge } from 'shared/lib/helpers';
import createStorage from './create-storage';

const classicalContentTypeRoute = '/classical_content_type';
const sciLitContentTypeRoute = '/scientific_literature_content_type';

class ContentTypeStore {
  constructor() {
    createStorage('contentTypeStore', this).then(() => {
    });
  }

  @persist('list') @observable classicalContentTypes = [];
  @persist('list') @observable sciLitContentTypes = [];
  @persist('list') @observable contentTypes = [];
  presentationContentTypes = [{ id: 'p:1', name: 'My Presentations' }];

  @observable filteredContentTypes = [];
  @observable disabledContentTypes = {};

  loadData(forceReload = false, lastUpdated = null) {
    const startTime = new Date().getTime();
    return new Promise((resolve) => {
      let params = '?';
      if (this.hasDataLoaded && !forceReload && lastUpdated !== null) {
        params += `since=${lastUpdated}`;
      // } else if (forceReload) {
      //   runInAction(() => {
      //     this.classicalContentTypes = [];
      //     this.sciLitContentTypes = [];
      //     this.contentTypes = [];
      //   });
      }

      Promise
        .all([rest.get(classicalContentTypeRoute + params), rest.get(sciLitContentTypeRoute + params)])
        .then((response) => {
          runInAction(() => {
            response[0].data.forEach((entry) => {
              entry.id = `c:${entry.id}`;
              entry.name = entry.title;
            });
            response[1].data.forEach((entry) => {
              entry.id = `s:${entry.id}`;
            });
            if (response[0].data.length > 0 || response[1].data.length > 0) {
              merge(this.classicalContentTypes, response[0].data, 'id', 'name');
              merge(this.sciLitContentTypes, response[1].data, 'id', 'name');
              this.setContentTypesForMaterials();
              this.resetFilters();
            }
            console.log(`${new Date().getTime() - startTime}ms - ${this.constructor.name}`);
            resolve();
          });
        }).catch((error) => {
          console.log('Could not load content types');
          console.log(error);
          resolve();
        });
    });
  }

  get hasDataLoaded() {
    return !!this.classicalContentTypes.length && !!this.sciLitContentTypes.length;
  }

  getClassicalContentTypeName(id) {
    const result = this.classicalContentTypes.find(contentType => contentType.id === `c:${id}`);
    return typeof result !== 'undefined' ? result.name : '';
  }

  getSciLitContentTypeName(id) {
    const result = this.sciLitContentTypes.find(contentType => contentType.id === `s:${id}`);
    return typeof result !== 'undefined' ? result.name : '';
  }

  getContentTypeName(id) {
    const result = this.contentTypes.find(contentType => contentType.id === id);
    return typeof result !== 'undefined' ? result.name : '';
  }

  get filteredContentTypesForUI() {
    return this.filteredContentTypes.length === this.contentTypes.length ? [] : toJS(this.filteredContentTypes);
  }

  @action setFilter(ids) {
    if (ids.length === 0) {
      this.filteredContentTypes = this.contentTypes.map(ct => ct.id);
    } else {
      this.filteredContentTypes = ids;
    }
  }

  @action toggleFilter(id) {
    if (this.filteredContentTypesForUI.length > 0) {
      const index = this.filteredContentTypes.indexOf(id);
      if (index > -1) {
        this.filteredContentTypes.splice(index, 1);
        if (this.filteredContentTypes.length === 0) {
          this.filteredContentTypes = this.contentTypes.map(ct => ct.id);
        }
      } else {
        this.filteredContentTypes.push(id);
      }
    } else {
      this.filteredContentTypes = [id];
    }
  }

  @action resetFilters = () => {
    if (this.filteredContentTypes.length !== this.contentTypes.length) {
      this.filteredContentTypes = this.contentTypes.map(ct => ct.id);
    }
  }

  setContentTypesForMaterials() {
    this.contentTypes = [...this.classicalContentTypes.filter(ct => ct.available_in_materials), ...this.sciLitContentTypes.filter(ct => ct.available_in_materials), ...this.presentationContentTypes];
  }

  setContentTypesForSoftware() {
    this.contentTypes = [...this.classicalContentTypes.filter(ct => ct.available_in_software), ...this.sciLitContentTypes.filter(ct => ct.available_in_software)];
  }
}

export default new ContentTypeStore();
