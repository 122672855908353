import { action, observable, runInAction } from 'mobx';
import { applicationStore } from 'shared/stores';
import BaseForm from './base-form';

class LoginForm extends BaseForm {
  @observable show2FAField = false;
  userId = null;
  rnd = null;

  @observable
  fields = {
    username: {
      value: '',
      error: null,
      label: 'Email',
      rule: 'required',
      type: 'email'
    },
    password: {
      value: '',
      error: null,
      rule: 'required',
      label: 'Password',
      type: 'password'
    },
    pin: {
      value: '',
      error: null,
      rule: 'min:0',
      label: 'Code',
      type: 'number'
    },
    remember: {
      value: false,
      error: null,
      rule: 'boolean',
      label: 'Remember this code in this browser for the next 3 months',
    },
  }

  @action resetForm = () => {
    this.fields.username.value = '';
    this.fields.password.value = '';
    this.fields.pin.value = '';
    this.fields.remember.value = false;
    this.show2FAField = false;
    this.error = null;
    this.rnd = null;
    this.userId = null;
  }

  @action save = async (e) => {
    e.preventDefault();
    this.saveInProgess = true;
    // check if cookie with code exists
    const cookies = decodeURIComponent(document.cookie).split('; ').filter(c => (c.indexOf('mfacode') === 0));
    const code = cookies.length ? cookies[0].substring(8) : null;
    // send request to backend
    // if login is OK, close form, otherwise show error
    const result = await applicationStore.loginLocal(this.fields.username.value.trim().toLowerCase(), this.fields.password.value, code);
    this.saveInProgess = false;
    if (result === true) {
      this.resetForm();
    } else if (result.success === true) {
      // this.resetForm();
      this.userId = result.id;
      this.rnd = result.rnd;
      this.error = null;
      this.show2FAField = true;
      this.fields.password.value = null;
    } else {
      this.error = result.message;
    }
  }

  @action login2FA = (e) => {
    e.preventDefault();
    this.saveInProgess = true;
    // send request to backend
    // if login is OK, close form, otherwise show error
    applicationStore.loginLocal2FA(this.fields.username.value.trim().toLowerCase(), this.userId, this.rnd, this.fields.pin.value, this.fields.remember.value).then((status) => {
      runInAction(() => {
        this.saveInProgess = false;
        if (status === true) {
          this.resetForm();
        } else {
          this.error = status;
        }
      });
    });
  }
}

export default new LoginForm();
