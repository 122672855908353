import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import { withDatabase } from '@nozbe/watermelondb/DatabaseProvider';
// import withObservables from '@nozbe/with-observables';
import { observer } from 'mobx-react-lite';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import { newsStore } from 'shared/stores';
import database from 'shared/stores/database/database';
import NewsItem from '../news-item';
import { headlineRow, section } from '../headline';

const newsLimit = 2;
const styles = {
  headlineRow,
  section,
  newsWrapper: {
    overflowY: 'auto',
    maxHeight: 'calc(100vH - 90px)',
    margin: '0 8px'
  },
  onlyNews: {
    marginBottom: 0
  },
  defaultView: {
    height: 190,
  }
};

const NewsSection = observer((props) => {
  const [theNews, setNews] = useState([]);

  useEffect(() => {
    const fetchNews = async () => {
      setNews(await database.collections.get('news').query().fetch());
    };

    fetchNews();
  }, []);

  const { classes } = props;
  const unreadNewsCount = theNews.filter(n => n.read === false).length;
  const dashboardNews = theNews.slice(0, newsLimit);
  const allNews = theNews.slice(newsLimit);

  return (
    <div className={`${classes.section} ${newsStore.onlyNews ? classes.onlyNews : classes.defaultView}`}>
      <div className={classes.headlineRow}>
        <Typography variant="h5">News Feed {unreadNewsCount
          ? <span>({unreadNewsCount} new)</span> : ''}
        </Typography>
        <Button
          color="primary"
          onClick={newsStore.onlyNews ? newsStore.backToDashboard : newsStore.openOnlyNews}
        >
          {newsStore.onlyNews ? 'Back to Dashboard' : 'View all News'}
        </Button>
      </div>
      <div className={classes.newsWrapper}>
        {dashboardNews.map(news => (
          <NewsItem
            key={news.uid}
            news={news}
            currentId={newsStore.currentId}
          />
        ))}
      </div>
      <Collapse in={newsStore.onlyNews}>
        <div className={classes.newsWrapper}>
          {allNews.map(news => (
            <NewsItem
              key={news.uid}
              news={news}
              currentId={newsStore.currentId}
            />
          ))}
        </div>
      </Collapse>
    </div>
  );
});

NewsSection.propTypes = {
  classes: PropTypes.object.isRequired,
  news: PropTypes.array,
};

NewsSection.defaultProps = {
  news: [],
};

// export default withDatabase(withObservables([], ({ database }) => {
//   console.log(database.collections.get('news').query().observeWithColumns(['read']));
//   return {
//     news: database.collections.get('news').query().observeWithColumns(['read']),
//   };
// })(withStyles(styles)(NewsSection)));

export default withStyles(styles)(NewsSection);
