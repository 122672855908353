import { observable, action, computed } from 'mobx';
import { Q } from '@nozbe/watermelondb';
import rest from 'shared/lib/rest';
import { applicationStore } from 'shared/stores';
import database from './database/database';

const api = {
  newsRead: '/newsread',
};

class NewsStore {
  getNewsType = (typeId) => {
    switch (typeId) {
      case 2:
        return 'Company news';
      case 3:
        return 'Product announcement';
      default:
        return 'Content update';
    }
  };

  markNewsAsReadById = (id) => {
    database.action(async () => {
      const result = await database.collections.get('news').query(Q.where('uid', id)).fetch();
      if (!result[0].read) {
        rest.post(api.newsRead, {
          news_id: id,
          user_id: applicationStore.appUser.get('id'),
          read: true
        }).catch(error => console.log(error));
        await result[0].update((news) => { news.read = true; });
      }
    });
  };

  // ----- UI states -----

  @observable currentId = null;

  @action updateExpandedNewsId(id) {
    if (this.currentId === id) {
      this.currentId = null;
    } else {
      this.currentId = id;
      this.markNewsAsReadById(id);
    }
    applicationStore.setDashboardFullView(applicationStore.dashboardFullOptions.news);
  }

  openOnlyNews = () => {
    applicationStore.setDashboardFullView(applicationStore.dashboardFullOptions.news);
  }

  @action backToDashboard = () => {
    applicationStore.setDashboardFullView(null);
    this.currentId = null;
  }

  // eslint-disable-next-line class-methods-use-this
  @computed get onlyNews() {
    return applicationStore.dashboardFullView === applicationStore.dashboardFullOptions.news;
  }
}

export default new NewsStore();
