import React, { Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import KeyIcon from '@material-ui/icons/VpnKeyOutlined';
import ShieldIcon from '@material-ui/icons/SecurityOutlined';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import EmailIcon from '@material-ui/icons/Email';
import UpIcon from '@material-ui/icons/ArrowUpward';
import RenewIcon from '@material-ui/icons/Cached';
import FilterListIcon from '@material-ui/icons/FilterList';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { applicationStore, routing, countryStore, cmtLicenseForm } from 'shared/stores';
import simappColors from 'shared/lib/theme/simapp-colors';
import rest from 'shared/lib/rest';

function desc(a, b, orderBy) {
  if (a.unused && !b.unused) {
    return -1;
  }
  if (!a.unused && b.unused) {
    return 1;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function asc(a, b, orderBy) {
  if (a.unused && !b.unused) {
    return -1;
  }
  if (!a.unused && b.unused) {
    return 1;
  }
  if (b[orderBy] < a[orderBy]) {
    return 1;
  }
  if (b[orderBy] > a[orderBy]) {
    return -1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => asc(a, b, orderBy);
}

const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
  },
});

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = (event) => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = (event) => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
          title="First Page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
          title="Previous Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
          title="Next Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
          title="Last Page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, { withTheme: true })(
  TablePaginationActions,
);

const headStyles = {
  tr: {
    height: 46
  },
  th: {
    fontSize: '1rem'
  }
};

class EnhancedTableToolbarComponent extends React.Component {
  createSortHandler = property => (event) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy, columns, classes } = this.props;

    return (
      <TableHead>
        <TableRow className={classes.tr}>
          {columns.map(
            row => (
              <TableCell
                key={row.id}
                align={row.numeric ? 'right' : 'left'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
                className={classes.th}
              >
                {/* <Tooltip
                  title="Sort"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                > */}
                <TableSortLabel
                  active={orderBy === row.id}
                  direction={order}
                  onClick={this.createSortHandler(row.id)}
                >
                  {row.label}
                </TableSortLabel>
                {/* </Tooltip>   */}
              </TableCell>
            ),
            this,
          )}
          <TableCell style={{ padding: 0 }} />
          <TableCell style={{ padding: 0 }} />
          { this.props.showLicenseActions && (
            <TableCell style={{ padding: 0 }} />
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableToolbarComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  showLicenseActions: PropTypes.bool.isRequired,
};

const EnhancedTableHead = withStyles(headStyles)(EnhancedTableToolbarComponent);

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

let EnhancedTableToolbar = (props) => {
  const { numSelected, classes } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="Filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = {
  root: {
    width: '100%',
  },
  table: {
    // minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tableCell: {
    maxWidth: '20vH',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  actionFirst: {
    paddingRight: 0
  },
  actionMiddle: {
    paddingLeft: 0,
    paddingRight: 0
  },
  actionLast: {
    paddingLeft: 0
  },
  actionButton: {
    color: simappColors.azul
  },
};

function download(filename, text) {
  const pom = document.createElement('a');
  pom.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`);
  pom.setAttribute('download', filename);

  if (document.createEvent) {
    const event = document.createEvent('MouseEvents');
    event.initEvent('click', true, true);
    pom.dispatchEvent(event);
  } else {
    pom.click();
  }
}

class EnhancedTable extends React.Component {
  state = {
    order: 'desc',
    orderBy: 'id',
    selected: [],
    page: 0,
    rowsPerPage: 15,
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  handleCreateCredentials = (userId) => {
    routing.push(`/cmt/credentials/${userId}`);
  }

  handleCreateLicense = (userId) => {
    routing.push(`/cmt/license/${userId}`);
  }

  handleLicenseDownload = (license) => {
    download(license.licenseFilename, license.license);
    // just trigger a backend route for the audit trail
    rest.post('/cmt_license_download', { id: license.id });
  }

  handleCreateLicenseCopy = (license) => {
    cmtLicenseForm.fillFromExisting(license);
    routing.push('/cmt/license');
  }

  render() {
    const { classes, data, columns } = this.props;
    const { order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <Paper className={classes.root}>
        {/* <EnhancedTableToolbar numSelected={selected.length} title={this.props.title} /> */}
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              columns={columns}
              showLicenseActions={this.props.showLicenseActions}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={n.id}
                  >
                    {columns.map(c => (
                      <TableCell
                        component="td"
                        className={classes.tableCell}
                        scope="row"
                        key={c.id}
                        style={{ color: n.alert ? simappColors.strawberry : (n.isDeactivated ? simappColors.placeholderGrey : 'inherit'), fontStyle: n.isDeactivated ? 'italic' : 'inherit' }}
                        title={c.id === 'country' && Array.isArray(n.countryIds) ? n.countryIds.map(i => countryStore.getCountryName(i)).join(', ') : null}
                      >
                        {c.checkbox
                          ? <Checkbox checked={n[c.id]} disabled />
                          : (
                            c.id === 'country' ? (
                              Array.isArray(n.countryIds)
                                ? `${countryStore.getCountryName(n.countryIds[0])}${n.countryIds.length > 1 ? ', ...' : ''}`
                                : countryStore.getCountryName(n.countryId)
                            ) : `${n[c.id]}`)}
                      </TableCell>
                    ))}
                    {this.props.showUserActions && (
                      <Fragment>
                        <TableCell component="th" scope="row" className={classes.actionFirst}>
                          { n.can_create_credentials && (
                            <IconButton onClick={() => this.handleCreateCredentials(n.id)} title="Create credentials for this user" className={classes.actionButton}>
                              <KeyIcon />
                            </IconButton>
                          )}
                        </TableCell>
                        <TableCell component="th" scope="row" className={classes.actionLast}>
                          { n.can_create_license && (
                            <IconButton onClick={() => this.handleCreateLicense(n.id)} title="Create license for this user" className={classes.actionButton}>
                              <ShieldIcon />
                            </IconButton>
                          )}
                        </TableCell>
                      </Fragment>
                    )}
                    {this.props.showLicenseActions && this.props.allowedProducts.includes(n.product) && !n.alert && (
                      <Fragment>
                        <TableCell component="th" scope="row" className={classes.actionFirst}>
                          <IconButton onClick={() => this.handleLicenseDownload(n)} title="Download license file" className={classes.actionButton}>
                            <DownloadIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell component="th" scope="row" className={classes.actionMiddle}>
                          <IconButton onClick={() => applicationStore.triggerLicenseByMail(n.id)} title="Email me license file" className={classes.actionButton}>
                            <EmailIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell component="th" scope="row" className={classes.actionLast}>
                          { !n.final && (
                            <IconButton onClick={() => this.handleCreateLicenseCopy(n)} title="Upgrade Demo to Final license" className={classes.actionButton}>
                              <UpIcon />
                            </IconButton>
                          )}
                        </TableCell>
                      </Fragment>
                    )}
                    {this.props.showLicenseActions && this.props.allowedProducts.includes(n.product) && n.alert && (
                      <Fragment>
                        <TableCell component="th" scope="row" className={classes.actionFirst}>
                          {n.final && (
                            <IconButton onClick={() => this.handleCreateLicenseCopy(n)} title="Renew License" className={classes.actionButton}>
                              <RenewIcon />
                            </IconButton>
                          )}
                        </TableCell>
                        <TableCell component="th" scope="row" className={classes.actionMiddle} />
                        <TableCell component="th" scope="row" className={classes.actionLast} />
                      </Fragment>
                    )}
                    {this.props.showLicenseActions && !this.props.allowedProducts.includes(n.product) && (
                      <Fragment>
                        <TableCell component="th" scope="row" className={classes.actionFirst} />
                        <TableCell component="th" scope="row" className={classes.actionMiddle} />
                        <TableCell component="th" scope="row" className={classes.actionLast} />
                      </Fragment>
                    )}
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={10} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[15]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActionsWrapped}
        />
      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  showUserActions: PropTypes.bool,
  showLicenseActions: PropTypes.bool,
  allowedProducts: PropTypes.array,
};

EnhancedTable.defaultProps = {
  showUserActions: false,
  showLicenseActions: false,
  allowedProducts: [],
};

export default withStyles(styles)(EnhancedTable);
