import { action, observable } from 'mobx';
import { applicationStore } from 'shared/stores';
import BaseForm from './base-form';

class SettingsForm extends BaseForm {
  saveButtonUsesPristine = true;

  @observable
  fields = {
    email: {
      value: '',
      error: null,
      disabled: true,
      label: 'Email',
      rule: 'required|email'
    },
    name: {
      value: '',
      error: null,
      rule: 'required',
      required: true,
      label: 'User\'s Name',
      placeholder: 'Your full name'
    },
    mobile_phone: {
      value: '',
      error: null,
      label: 'Phone Number',
      placeholder: 'Please also provide your region code, e.g. +49',
      rule: ['required', 'regex:/^\\+[0-9]{2,3}[0-9- ]{1,}$/'],
      required: true,
    },
    job: {
      value: '',
      error: null,
      label: 'Job Description',
      rule: 'required',
      required: true,
    },
    company: {
      value: '',
      error: null,
      label: 'Company Name',
      rule: 'required',
      required: true,
    },
    cmt_role_name: {
      value: '',
      error: null,
      label: 'Role',
      rule: 'min:0',
      disabled: true
    },
    cmt_group_name: {
      value: '',
      error: null,
      label: 'Group',
      rule: 'min:0',
      disabled: true
    },
    cmt_trained_kryptor: {
      value: false,
      error: null,
      label: 'Training Kryptor / Chat LIS',
      rule: 'boolean',
      disabled: true
    },
    cmt_trained_fastscreen: {
      value: false,
      error: null,
      label: 'Training FS PLUS',
      rule: 'boolean',
      disabled: true
    },
    country_id: {
      value: 0,
      error: null,
      label: 'Region',
      rule: 'required|min:1',
      required: true,
      placeholder: 'Select a region'
    },
    signature: {
      value: '',
      error: null,
      // label: 'Signature',
      rule: 'string',
      multiline: true,
      rows: 8
    },
    profile_pic: {
      value: '',
      error: null,
      // label: 'Signature',
      rule: 'string'
    }
  }

  @action setDefaultValues = () => {
    applicationStore.appUser.forEach((value, key) => {
      if (typeof this.fields[key] !== 'undefined') {
        this.fields[key].value = value;
        this.onFieldChange({ target: { id: key, value } });
      }
    });
    this.pristine = true;
  }

  @action onNativeFieldChange = (e) => {
    this.fields[e.fieldName].value = e.value;
  }

  @action save = (navigation) => {
    // eslint-disable-next-line guard-for-in
    for (const field in this.fields) {
      this.onFieldChange({
        target: {
          id: field,
          value: this.fields[field].value,
        } });
    }
    if (!this.isValid) return;
    this.saveInProgess = true;
    // news are filtered by user role on the server side, so we must reload them after changing the user role
    const reloadNews = false; // this.fields.role_id.value !== applicationStore.appUser.get('role_id');
    Object.keys(this.fields).forEach((key) => {
      if (this.fields[key]) {
        applicationStore.appUser.set(key, this.fields[key].value);
      }
    });
    applicationStore.updateAppUser(navigation, reloadNews);
    this.saveInProgess = false;
  }
}

export default new SettingsForm();
