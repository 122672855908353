import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { simappConstants } from 'shared/lib/theme';
import HeInputField from 'web/components/he-model/he-inputfield';
import Typography from '@material-ui/core/Typography';
import pctConstants from 'shared/lib/resources/pct-constants';
import { calculatePercentage, checkChange } from 'shared/helper/pct-helper';
import ClickableTextButton from '../_misc/clickableTextButton';
import PctNavButton from './pct-navbutton';
import PctDialog from './pct-dialog';
import heConstants from '../../../shared/lib/resources/he-constants';

const constants = simappConstants;

const styles = {
  root: {
    width: '100%',
    height: '100vH',
    display: 'flex',
    flexDirection: 'column',
    padding: simappConstants.contentMarginLarge,
    overflowX: 'hidden',
    fontFamily: 'helvetica-neue-lt, sans-serif'
  },
  borderlessExpansionPanel: {
    '&::before': { content: 'none' },
    marginBottom: '2px',
    boxShadow: 'unset'
  },
  bottomBar: {
    width: '100%',
    marginTop: '30px'
  },
  textContainer: {
    marginTop: '40px',
    marginBottom: '70px',
    marginLeft: constants.pctHorizontalMargin,
    marginRight: constants.pctHorizontalMargin,
    textAlign: 'center',
  },
  rightTableCell: {
    textAlign: 'right',
    width: '200px'
  },
  expansionContent: {
    paddingLeft: '10px',
    paddingRight: '0px',
  },
  mediumText: {
    fontWeight: '500'
  },
  table: {
    marginTop: '-15px',
    marginBottom: '-25px',
    paddingLeft: '10px',
    paddingRight: '0px'
  },
  animationlessPanelSummary: {
    minHeight: '48px !important',
    lineHeight: '48px',
    cursor: 'text !important',
    '& div:last-child': {
      padding: '0'
    },
    '& div:first-child': {
      margin: '0'
    },
  },
  leftAlignedText: {
    textAlign: 'left',
    marginLeft: '-40px'
  },
  borderlessCell: {
    border: 'none'
  }
};

@inject('applicationStore', 'pctStore', 'routing') @observer
class PctPopulation extends Component {
  isUS = false;

  constructor(props) {
    super(props);
    this.state = {
      showValueChangeDialog: false,
      showNavigationDialog: false,
      valueChanged: false
    };
  }

  onValueChange(key, index, value, avoidValueChangedDialog = false) {
    let showValueChangeDialog;
    if (!avoidValueChangedDialog) {
      const hasShownDialog = this.props.pctStore.hasShownDialog(key, index, pctConstants.DataTypePct, this.isUS);
      showValueChangeDialog = value !== undefined && value !== null && !hasShownDialog;
      this.props.pctStore.setHasShownDialog(key, index, pctConstants.DataTypePct, showValueChangeDialog || hasShownDialog);
    }

    this.props.pctStore.setPctValue(key, index, value);
    this.setState({
      showValueChangeDialog,
      valueChanged: true
    }); // triggers update / recalculation
  }

  onNavigationClick(location) {
    if (!this.isUS || !this.state.valueChanged || this.props.pctStore.pctNavigateDialogPopUp[this.props.routing.location.pathname] === false) {
      this.props.routing.push(location);
      return;
    }
    this.setState({
      showNavigationDialog: true,
      navigationDestination: location
    });
  }

  getTableRows(pctStore, field0, field1) {
    if (this.isUS) {
      return (
        <TableBody>
          <TableRow key={1}>
            <TableCell>
              <Typography variant="subtitle1">{pctStore.getText('PctPopulationTableTotalIcuPatients')}</Typography>
            </TableCell>
            <TableCell className={this.props.classes.rightTableCell}>
              <HeInputField
                fieldName={pctConstants.PopulationField}
                fieldIndex={0}
                isChanged={this.props.pctStore.isUserPctValue(pctConstants.PopulationField, 0)}
                isUSNumberInput={this.isUS}
                type={pctConstants.PctTableRowNone}
                onValueChange={(key, index, value) => this.onValueChange(key, index, value)}
                value={field0}
                textAlign="right"
              />
            </TableCell>
          </TableRow>
          <TableRow key={3}>
            <TableCell className={this.props.classes.borderlessCell}>
              <Typography variant="subtitle1" className={this.props.classes.tableCellText}>{pctStore.getText('PctPopulationTableTotalSepsisPatientsIcu')}</Typography>
            </TableCell>
            <TableCell className={`${this.props.classes.rightTableCell} ${this.props.classes.borderlessCell}`}>
              <HeInputField
                fieldName={pctConstants.PopulationField}
                fieldIndex={1}
                isChanged={this.props.pctStore.isUserPctValue(pctConstants.PopulationField, 1)}
                type={pctConstants.PctTableRowNone}
                isUSNumberInput
                onValueChange={(key, index, value) => this.onValueChange(key, index, value)}
                value={field1}
                textAlign="right"
              />
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }
    return (
      <TableBody>
        <TableRow key={1}>
          <TableCell>
            <Typography variant="subtitle1" className={this.props.classes.tableCellText}>{pctStore.getText('PctPopulationTableTotalIcuPatients')}</Typography>
          </TableCell>
          <TableCell className={this.props.classes.rightTableCell}>
            <HeInputField
              fieldName={pctConstants.PopulationField}
              fieldIndex={0}
              isChanged={this.props.pctStore.isUserPctValue(pctConstants.PopulationField, 0)}
              isUSNumberInput={this.isUS}
              type={pctConstants.PctTableRowNone}
              onValueChange={(key, index, value) => this.onValueChange(key, index, value, false)}
              value={field0}
            />
          </TableCell>
        </TableRow>
        <TableRow key={2}>
          <TableCell>
            <Typography variant="subtitle1" className={this.props.classes.tableCellText}>{pctStore.getText('PctPopulationTableOccuranceSepsisIcu')}</Typography>
          </TableCell>
          <TableCell className={this.props.classes.rightTableCell}>
            <HeInputField
              fieldName={pctConstants.PopulationField}
              fieldIndex={1}
              isChanged={this.props.pctStore.isUserPctValue(pctConstants.PopulationField, 1)}
              isUSNumberInput={false}
              type={heConstants.HeTableRowPercentage}
              maxValue={1}
              onValueChange={(key, index, value) => this.onValueChange(key, index, value, false)}
              value={field1}
            />
          </TableCell>
        </TableRow>
        <TableRow key={4}>
          <TableCell className={this.props.classes.borderlessCell}>
            <Typography variant="subtitle1" className={this.props.classes.tableCellText}>{pctStore.getText('PctPopulationTableTotalSepsisPatientsIcu')}</Typography>
          </TableCell>
          <TableCell className={`${this.props.classes.rightTableCell} ${this.props.classes.borderlessCell}`}>
            <HeInputField
              isUSNumberInput={false}
              displayType="text"
              type={pctConstants.PctTableRowNone}
              value={calculatePercentage(field0, field1)}
            />
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  getTable(pctStore) {
    const field0 = this.props.pctStore.getPctValue(pctConstants.PopulationField, 0);
    const field1 = this.props.pctStore.getPctValue(pctConstants.PopulationField, 1);

    return (
      <ExpansionPanel expanded className={this.props.classes.borderlessExpansionPanel}>
        <ExpansionPanelSummary className={this.props.classes.animationlessPanelSummary}>
          <Typography variant="subtitle1" className={this.props.classes.mediumText}>{pctStore.getText('PctPopulationTableHeader')}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails key={0} className={this.props.classes.expansionContent}>
          <Table className={this.props.classes.table}>
            {this.getTableRows(pctStore, field0, field1)}
          </Table>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }

  getBottomBar(pctStore, push) {
    return (
      <div className={this.props.classes.bottomBar}>
        <ClickableTextButton onClick={() => push('/he-models/pct-calculator/references')} text={pctStore.getText('References')} />
        <PctNavButton
          nextOnClick={location => this.onNavigationClick(location)}
          nextOnClickLocation="/he-models/pct-calculator/healthcare"
          nextText={pctStore.getText('PctNextStep')}
          backOnClick={() => {
            this.props.applicationStore.setLocation(this.props.routing.location.pathname);
            push('/he-models/pct-calculator');
          }}
          isUS={this.isUS}
          backText={pctStore.getText('PctChooseAgain')}
          backButton
          screenNumber={1}
        />
        {this.isUS
          ? (
            <div>
              <PctDialog
                trigger={this.state.showNavigationDialog && this.state.valueChanged}
                message={pctStore.getText('PctValueChangeNavigationDialogText')}
                okText={pctStore.getText('PctValueChangeDialogOkText')}
                cancelText={pctStore.getText('PctNavigationAlertCancel')}
                onOk={() => {
                  this.props.pctStore.setNavigationDialogSetting(this.props.routing.location.pathname, false);
                  push(this.state.navigationDestination);
                }}
                onCancel={() => {
                  this.setState({
                    showValueChangeDialog: false,
                    showNavigationDialog: false
                  });
                }}
              />
              <PctDialog
                trigger={this.state.showValueChangeDialog}
                message={pctStore.getText('PctValueChangeDialogText')}
                okText={pctStore.getText('PctValueChangeDialogOkText')}
                hasCheckbox
                checkboxMessage={pctStore.getText('PctValueChangeDialogCheckboxText')}
                onOk={(state) => {
                  this.props.pctStore.setValueChangeDialogSetting(state);
                  this.setState({
                    showValueChangeDialog: false,
                    showNavigationDialog: false
                  });
                }}
              />
            </div>
          )
          : ''
        }
      </div>
    );
  }

  render() {
    this.isUS = this.props.applicationStore.presentingInName === pctConstants.CountryNameUS;
    const { push } = this.props.routing;
    const { pctStore } = this.props;
    this.state.valueChanged = checkChange(pctStore, pctConstants.PopulationField);

    const result = (
      <div className={this.props.classes.root}>
        <div>
          <div className={this.props.classes.textContainer}>
            <Typography variant="body1" className={`${this.props.classes.textBlock} ${this.isUS ? this.props.classes.leftAlignedText : undefined}`}>{pctStore.getText('PctPopulationHeader')}</Typography>
          </div>
          {this.getTable(pctStore)}
          {this.getBottomBar(pctStore, push)}
        </div>
        <PctDialog />
      </div>
    );
    return result;
  }
}

PctPopulation.wrappedComponent.propTypes = {
  applicationStore: PropTypes.object.isRequired,
  pctStore: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  routing: PropTypes.object.isRequired,
};

export default withStyles(styles)(PctPopulation);
