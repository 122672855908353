import { observable, runInAction, computed, action, reaction } from 'mobx';
import { persist } from 'mobx-persist';
import { Q } from '@nozbe/watermelondb';
import moment from 'moment';
import { intersection, union } from 'lodash';
import rest from 'shared/lib/rest';
import { applicationStore, contentTypeStore, languageStore, productStore } from 'shared/stores';
import createStorage from './create-storage';
import database from './database/database';

const sharingRoute = '/sharing';

class MaterialStore {
  constructor() {
    createStorage('materialStore', this).then(() => {
      // this.hydrated = true;
    });
  }

  sorts = [
    { id: 'newest', title: 'Newest first' },
    { id: 'recommended', title: 'Recommended first' },
    { id: 'type', title: 'Sort by Type' },
  ];

  groups = [
    { id: 'all', title: 'All Materials' },
    { id: 'recommended', title: 'Recommended' },
    { id: 'favorites', title: 'My Favourites' },
    { id: 'presentations', title: 'My Presentations' }
  ];

  getGroups = () => {
    if (applicationStore.isDistributor || this.isInSoftware) {
      return this.groups.filter(row => (row.id !== 'presentations'));
    }
    return this.groups;
  }

  materialTypes = {
    classicalContent: 1,
    scientificLiterature: 2,
    presentation: 3
  };

  @observable hydrated = false;
  @observable searchString = '';
  @observable searchInProgress = false;
  materials = [];
  @observable materialsUpdated = null; // timestamp
  @persist('list') @observable recent = []; // holds recently used materials
  @persist('list') @observable current = []; // like recent, but used for the currently open materials

  getLocalContentItem = async (uid, materialType = null, presentationItems = []) => {
    const dbUid = (materialType === this.materialTypes.presentation) ? `${presentationItems[0].content_piece_id}` : uid;
    const result = await database.collections.get('materialDownload').query(Q.where('uid', dbUid)).fetch();
    // eslint-disable-next-line no-underscore-dangle
    return result.length > 0 ? result[0]._raw : undefined;
  };

  @action removePresentationFromCache = (id) => {
    // remove from materials list
    const uid = `p:${id}`;
    const index = this.materials.findIndex(m => (m.uid === uid));
    if (index > -1) {
      this.materials.splice(index, 1);
    }
    // remove from recent and current
    this.removeFromRecent(uid);
    this.removeFromCurrent(uid);

    // remove from watermelonDB
    database.action(async () => {
      const result = await database.collections.get('material').query(Q.where('uid', uid)).fetch();
      if (result.length > 0) result[0].destroyPermanently();
    });
    this.materialsUpdated = Date.now();
  }

  @computed get materialCount() {
    return this.filteredMaterials.length;
  }

  get hasDataLoaded() {
    return !!this.materials.length;
  }

  @observable sort = 'newest';

  @action setSort(v) {
    this.sort = v;
  }

  @observable group = 'all';

  @action setGroup(group) {
    this.group = group;
  }

  sortMaterials = (a, b) => {
    if (this.sort === 'newest') {
      const yearA = a.material_type === this.materialTypes.scientificLiterature ? a.year : moment(a.b_created_at, 'x').format('YYYY');
      const yearB = b.material_type === this.materialTypes.scientificLiterature ? b.year : moment(b.b_created_at, 'x').format('YYYY');
      if (yearA < yearB) return 1;
      if (yearA > yearB) return -1;
      /**
       * Order material types:
       * 1. presentations
       * 2. classical content
       * 3. scientific literature
       */
      if (a.material_type === this.materialTypes.presentation && b.material_type !== this.materialTypes.presentation) return -1;
      if (a.material_type === this.materialTypes.classicalContent && b.material_type === this.materialTypes.presentation) return 1;
      if (a.material_type === this.materialTypes.classicalContent && b.material_type === this.materialTypes.scientificLiterature) return -1;
      if (a.material_type === this.materialTypes.scientificLiterature && b.material_type !== this.materialTypes.scientificLiterature) return 1;
      // if identical type and year, sort by created at
      if (a.b_created_at < b.b_created_at) return 1;
      if (a.b_created_at > b.b_created_at) return -1;
      return 0;
    } if (this.sort === 'recommended') {
      if (a.isRecommended()) return -1;
      return 0;
    } if (this.sort === 'type') {
      if (a.content_type < b.content_type) return -1;
      if (a.content_type > b.content_type) return 1;
      return 0;
    }
    return 0;
  }

  @observable filteredMaterials = [];

  buildFilteredMaterials = reaction(
    () => {
      let result = [];
      const indexes = intersection(this.materialIdxFilteredByCountry, this.materialIdxFilteredByType, this.materialIdxFilteredByProduct, this.materialIdxFilteredByProductGroup, this.materialIdxFilteredByLanguage);
      const m = this.materials
        .filter((material, i) => {
          if (!indexes.includes(i)) return false;
          switch (this.group) {
            case 'recommended':
              return !!material.isRecommended();
            case 'favorites':
              return !!material.favorite.value;
            case 'presentations':
              return material.content_type_id === contentTypeStore.presentationContentTypes[0].id;
            case 'all':
            default:
              return true;
          }
        });
      if (this.searchString.length) {
        const searchRegex = new RegExp(this.searchString, 'i');
        result = m.filter(material => (
          typeof material.title === 'string' && (
            material.title.search(searchRegex) !== -1
          || material.mastercontrol_id.search(searchRegex) !== -1
          || material.content_type.search(searchRegex) !== -1
          || material.year === this.searchString
          || material.article_number.search(searchRegex) !== -1
          || material.author.search(searchRegex) !== -1
          || material.bibliography_number.search(searchRegex) !== -1
          || material.publication_detail.search(searchRegex) !== -1
          || material.published_in.search(searchRegex) !== -1
          || material.keywords.findIndex(value => searchRegex.test(value)) !== -1
          )));
      } else {
        result = m.slice(); // slice() for creating a copy and suppress warning when sort()
      }
      return result.sort(this.sortMaterials);
    },
    (data) => {
      this.filteredMaterials = data;
      this.searchInProgress = false;
    }
  );

  /**
   * Handling of disabled filters, content to show
   */

  @observable materialIdxFilteredByCountry = [];
  @observable materialIdxFilteredByType = [];
  @observable materialIdxFilteredByLanguage = [];
  @observable materialIdxFilteredByProduct = [];
  @observable materialIdxFilteredByProductGroup = [];

  // first step: get all products based on one filter
  buildFilteredByCountry = reaction(
    () => {
      // eslint-disable-next-line no-unused-expressions
      this.materialsUpdated; // trigger this reaction
      return this.materials.reduce((acc, cur, i) => {
        if (cur.country_ids.includes(applicationStore.presentingIn)) acc.push(i);
        return acc;
      }, []);
    },
    (data) => {
      this.materialIdxFilteredByCountry = data;
    }
  );

  buildFilteredByType = reaction(
    () => {
      // eslint-disable-next-line no-unused-expressions
      this.materialsUpdated; // trigger this reaction
      return this.materials.length > 0 && contentTypeStore.filteredContentTypes.length === contentTypeStore.contentTypes.length
        ? this.materials.map((m, i) => i)
        : this.materials.reduce((acc, cur, i) => {
          if (contentTypeStore.filteredContentTypes.includes(cur.content_type_id)) acc.push(i);
          return acc;
        }, []);
    },
    (data) => {
      this.materialIdxFilteredByType = data;
    }
  );

  buildFilteredByLanguage = reaction(
    () => {
      // eslint-disable-next-line no-unused-expressions
      this.materialsUpdated; // trigger this reaction
      return this.materials.length > 0 && languageStore.filteredLanguages.length === languageStore.languages.length
        ? this.materials.map((m, i) => i)
        : this.materials.reduce((acc, cur, i) => {
          if (languageStore.filteredLanguages.includes(cur.language_id)) acc.push(i);
          return acc;
        }, []);
    },
    (data) => {
      this.materialIdxFilteredByLanguage = data;
    }
  );

  buildFilteredByProduct = reaction(
    () => {
      // eslint-disable-next-line no-unused-expressions
      this.materialsUpdated; // trigger this reaction
      return this.materials.length > 0 && productStore.filteredProductIds.length === productStore.products.length
        ? this.materials.map((m, i) => i)
        : this.materials.reduce((acc, cur, i) => {
          if (Array.isArray(cur.product_ids) && productStore.filteredProductIds.find(p => cur.product_ids.includes(p))) acc.push(i);
          return acc;
        }, []);
    },
    (data) => {
      this.materialIdxFilteredByProduct = data;
    }
  );

  buildFilteredByProductGroup = reaction(
    () => {
      // eslint-disable-next-line no-unused-expressions
      this.materialsUpdated; // trigger this reaction
      return this.materials.length > 0 && productStore.filteredProductIdsFromGroups.length === productStore.products.length
        ? this.materials.map((m, i) => i)
        : this.materials.reduce((acc, cur, i) => {
          if (Array.isArray(cur.product_ids) && productStore.filteredProductIdsFromGroups.find(p => cur.product_ids.includes(p))) acc.push(i);
          return acc;
        }, []);
    },
    (data) => {
      this.materialIdxFilteredByProductGroup = data;
    }
  );

  /**
   * HINT: This is not DRY, but needed if materials are modified (e.g. product group changed). Reactions would not catch this.
   */
  @action resetFiltered = () => {
    const uids = this.materials.map((m, i) => i);
    this.materialIdxFilteredByCountry = uids; // .filter(material => (isObservableArray(material.country_ids) && material.country_ids.includes(applicationStore.presentingIn)));
    this.materialIdxFilteredByType = uids;
    this.materialIdxFilteredByLanguage = uids;
    this.materialIdxFilteredByProduct = uids;
    this.materialIdxFilteredByProductGroup = uids;
  }

  // second step: combine filtered lists
  refreshDisabledLanguages = reaction(
    () => {
      const indexes = intersection(this.materialIdxFilteredByCountry, this.materialIdxFilteredByType, this.materialIdxFilteredByProduct, this.materialIdxFilteredByProductGroup);
      return this.materials.filter((m, i) => indexes.includes(i));
    },
    (filtered) => {
      languageStore.languages.forEach((l) => {
        languageStore.disabledLanguages[l.id] = !filtered.filter(material => material.language_id === l.id).length;
      });
    },
    { delay: 150 }
  );

  refreshDisabledContentTypes = reaction(
    () => {
      const indexes = intersection(this.materialIdxFilteredByCountry, this.materialIdxFilteredByLanguage, this.materialIdxFilteredByProduct, this.materialIdxFilteredByProductGroup);
      return this.materials.filter((m, i) => indexes.includes(i));
    },
    (filtered) => {
      contentTypeStore.contentTypes.forEach((l) => {
        contentTypeStore.disabledContentTypes[l.id] = !filtered.filter(material => material.content_type_id === l.id).length;
      });
    },
    { delay: 150 }
  );

  /**
   * in products, we do not only check that any material is available in combination with other parameters,
   * but the product must also child of group/subgroup if selected
   */

  refreshDisabledProducts = reaction(
    () => {
      const indexes = intersection(this.materialIdxFilteredByCountry, this.materialIdxFilteredByLanguage, this.materialIdxFilteredByType);
      return this.materials.length === 0 ? [] : {
        filtered: this.materials.filter((m, i) => indexes.includes(i)),
        productGroupIds: productStore.filteredProductGroupIds.length > 0 ? productStore.filteredProductGroupIds : productStore.productGroups.map(pg => pg.id),
        productSubgroupIds: productStore.filteredProductSubgroupIds.length > 0 ? productStore.filteredProductSubgroupIds : []
      };
    },
    (data) => {
      const { filtered, productGroupIds, productSubgroupIds } = data;
      let productIds = [];
      // if we leave /materials, this is still called with delay, but already empty, so do nothing
      if (typeof filtered === 'undefined') return;
      // get all product IDs from filtered material
      filtered.forEach((material) => { productIds = union(productIds, material.product_ids); });
      productStore.products.forEach((p) => {
        productStore.disabledProducts[p.id] = !productIds.includes(p.id) || !productGroupIds.includes(p.product_group_id) || (productSubgroupIds.length > 0 && !productSubgroupIds.some(r => p.product_subgroup_ids.includes(r)));
      });
    },
    { delay: 150 }
  );

  refreshDisabledProductGroups = reaction(
    () => {
      const indexes = intersection(this.materialIdxFilteredByCountry, this.materialIdxFilteredByLanguage, this.materialIdxFilteredByType);
      return this.materials.length === 0 ? [] : {
        filtered: this.materials.filter((m, i) => indexes.includes(i)),
        selectedProductIds: productStore.filteredProductIds.length > 0 ? productStore.filteredProductIds : []
      };
    },
    (data) => {
      const { filtered, selectedProductIds } = data;
      let productIds = [];
      let productGroupIds = [];
      if (typeof filtered === 'undefined') return;
      // get all product IDs from filtered material
      filtered.forEach((material) => { productIds = union(productIds, material.product_ids); });
      productIds = intersection(productIds, selectedProductIds);
      // get all product group IDs from product IDs
      productStore.products.filter(p => productIds.includes(p.id)).forEach((product) => { productGroupIds = union(productGroupIds, [product.product_group_id]); });

      productStore.productGroups.forEach((pg) => {
        productStore.disabledProductGroups[pg.id] = !productGroupIds.includes(pg.id);
      });
    },
    { delay: 150 }
  );

  refreshDisabledProductSubgroups = reaction(
    () => {
      const indexes = intersection(this.materialIdxFilteredByCountry, this.materialIdxFilteredByLanguage, this.materialIdxFilteredByType);
      return this.materials.length === 0 ? [] : {
        filtered: this.materials.filter((m, i) => indexes.includes(i)),
        selectedProductIds: productStore.filteredProductIds.length > 0 ? productStore.filteredProductIds : []
      };
    },
    (data) => {
      const { filtered, selectedProductIds } = data;
      let productIds = [];
      let productSubgroupIds = [];
      if (typeof filtered === 'undefined') return;
      // get all product IDs from filtered material
      filtered.forEach((material) => { productIds = union(productIds, material.product_ids); });
      productIds = intersection(productIds, selectedProductIds);
      // get all product subgroup IDs from product IDs
      productStore.products.filter(p => productIds.includes(p.id)).forEach((product) => { productSubgroupIds = union(productSubgroupIds, product.product_subgroup_ids); });

      productStore.productSubgroups.forEach((p) => {
        productStore.disabledProductSubgroups[p.id] = !productSubgroupIds.includes(p.id);
      });
    },
    { delay: 150 }
  );

  @action resetAllFilters = () => {
    contentTypeStore.resetFilters();
    languageStore.resetFilters();
    productStore.resetFilters();
    if (this.searchString.length > 0) this.searchString = '';
  }

  @action setSearchString(v) {
    this.searchString = v;
  }

  @action addRecent = (uid) => {
    // remove if in list
    this.recent.remove(uid);
    // add to end of list
    this.recent.push(uid);
  }

  @action removeFromRecent = (uid) => {
    const index = this.recent.findIndex(c => (c === uid));
    if (index > -1) {
      this.recent.splice(index, 1);
    }
  }

  getRecent() {
    const start = this.recent.length < 10 ? 0 : this.recent.length - 10;
    // const length = this.recent.length < 10 ? this.recent.length : start + 1;
    return this.recent.slice(start, start + 10).reverse();
  }

  @action addCurrent = (uid) => {
    // if in list, do nothing, otherwise append
    if (!this.current.includes(uid)) {
      this.addRecent(uid);
      this.current.push(uid);
    }
  }

  @action removeFromCurrent = (uid) => {
    const index = this.current.findIndex(c => (c === uid));
    if (index > -1) {
      this.current.splice(index, 1);
    }
  }

  getCurrent() {
    return this.current.map(uid => this.getMaterialByUid(uid));
  }

  getMaterialByUid = uid => this.materials.find(m => m.uid === uid)

  // we load thumbnails as a blob, so we can send auth headers
  loadThumbnail = material => new Promise((resolve) => {
    if (typeof material.thumbnailUrl !== 'undefined') {
      resolve(material.thumbnailUrl);
    } else {
      rest.get(`https:${material.thumbnail}`, { responseType: 'blob' }).then((response) => {
        const file = new Blob(
          [response.data],
          { type: 'image/png' }
        );
        runInAction(() => {
          material.thumbnailUrl = URL.createObjectURL(file);
        });
        // Build a URL from the file
        resolve(URL.createObjectURL(file));
      }).catch(error => console.log(error));
    }
  })

  loadMaterial = material => new Promise((resolve) => {
    rest.get(`https:${material.document}`, { responseType: 'blob' }).then((response) => {
      const file = new Blob(
        [response.data],
        { type: material.mimetype }
      );
      // Build a URL from the file
      resolve(URL.createObjectURL(file));
    }).catch(error => console.log(error));
  })

  @action toggleFavorite = (uid) => {
    const material = this.getMaterialByUid(uid);
    material.favorite.set(!material.favorite.value);
    switch (material.material_type) {
      case this.materialTypes.scientificLiterature:
        rest.post('/scientific_literature_fav', { user_id: applicationStore.appUser.get('id'), scientific_literature_id: material.b_id, favorite: material.favorite.value }).catch(error => console.log(error));
        break;
      case this.materialTypes.presentation:
        rest.post('/presentation_fav', { user_id: applicationStore.appUser.get('id'), presentation_id: material.b_id, favorite: material.favorite.value }).catch(error => console.log(error));
        break;
      default:
        rest.post('/classical_content_fav', { user_id: applicationStore.appUser.get('id'), classical_content_id: material.b_id, favorite: material.favorite.value }).catch(error => console.log(error));
    }
    database.action(async () => {
      const result = await database.collections.get('material').query(Q.where('uid', uid)).fetch();
      if (result.length > 0) result[0].update((m) => { m.favorite = material.favorite.value; });
    });
  }

  // Sharing

  @observable sharingMaterials = [];
  @observable sharingMaterialsCache = [];
  sharingTiles = [];

  @action addToSharingMaterialsCache = (materialUid) => {
    if (this.sharingMaterialsCache.indexOf(materialUid) === -1) {
      this.sharingMaterialsCache.push(materialUid);
    }
  }

  @action addCheckedTile(tile) {
    this.sharingTiles.push(tile);
  }

  // update material tile UI only if necessary
  @action updateCheckedTiles() {
    this.sharingTiles.forEach((tile) => {
      const index = this.sharingMaterialsCache.indexOf(tile.uid);
      if (index === -1) {
        this.sharingTiles.splice(index, 1);
        tile.forceUpdate();
      }
    });
  }

  @action removeFromSharingMaterialsCache = (materialUid) => {
    const index = this.sharingMaterialsCache.indexOf(materialUid);
    if (index >= 0) {
      this.sharingMaterialsCache.splice(index, 1);
    }
    this.updateCheckedTiles();
  }

  @action removeFromSharingMaterials = (materialUid) => {
    const index = this.sharingMaterials.indexOf(materialUid);
    if (index >= 0) {
      this.sharingMaterials.splice(index, 1);
    }
  }

  sendSharingMail = (data) => {
    data.user_id = applicationStore.appUser.get('id');
    data.classical_content = [];
    data.scientific_literature = [];
    data.presentation = [];
    const classicalTitles = {};
    const scientificTitles = {};
    const presentationTitles = {};
    this.sharingMaterials.forEach((uid) => {
      const material = this.getMaterialByUid(uid);
      switch (material.material_type) {
        case this.materialTypes.presentation:
          data.presentation.push(material.id);
          presentationTitles[material.id] = material.title;
          break;
        case this.materialTypes.scientificLiterature:
          data.scientific_literature.push(material.id);
          scientificTitles[material.id] = material.title;
          break;
        default:
          data.classical_content.push(material.id);
          classicalTitles[material.id] = material.title;
      }
    });
    return new Promise((resolve, reject) => {
      rest.post(sharingRoute, data).then((response) => {
        if (!response.data.success) {
          reject();
        } else {
          let mailtext = data.message;
          const expires = new Date(response.data.eol);
          mailtext += `<br>
          <br>
          Please note that by using these links you agree to our <a href="https://www.thermofisher.com/uk/en/home/global/privacy-policy.html">privacy statement</a> and <a href="https://www.thermofisher.com/uk/en/home/global/terms-and-conditions.html">cookie policy</a>.
          The links to the shared materials are valid until ${expires.getDate()}.${expires.getMonth() + 1}.${expires.getFullYear()}.<br>
          <br></p>`;
          response.data.presentation.forEach((cc) => {
            mailtext += `<p><b><a href="${cc.url}">${presentationTitles[cc.id]}</a></b></p>`;
          });
          response.data.classical_content.forEach((cc) => {
            mailtext += `<p><b><a href="${cc.url}">${classicalTitles[cc.id]}</a></b></p>`;
          });
          response.data.scientific_literature.forEach((cc) => {
            mailtext += `<p><b>${scientificTitles[cc.id]}: </b>`;
            if (typeof cc.url_document !== 'undefined') {
              mailtext += `<a href="${cc.url_document}">Open document</a>`;
            }
            if (typeof cc.url_document !== 'undefined' && typeof cc.url_link !== 'undefined') {
              mailtext += ' or ';
            }
            if (typeof cc.url_link !== 'undefined') {
              mailtext += `<a href="${cc.url_link}">View on publisher site</a>`;
            }
            mailtext += '</p>';
          });

          applicationStore.sendMail(data.mailto, data.subject, mailtext)
            .then(() => { resolve(); })
            .catch((error) => { reject(error); });
        }
      })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  // ----- DASHBOARD -----

  openOnlyMaterials = () => {
    applicationStore.setDashboardFullView(applicationStore.dashboardFullOptions.materials);
  }

  @action backToDashboard = () => {
    applicationStore.setDashboardFullView(null);
  }

  // eslint-disable-next-line class-methods-use-this
  @computed get onlyMaterials() {
    return applicationStore.dashboardFullView === applicationStore.dashboardFullOptions.materials;
  }

  // ----- switch between materials and software -----
  area = 'materials';
  get isInMaterials() {
    return this.area === 'materials';
  }

  get isInSoftware() {
    return this.area === 'software';
  }

  switchToMaterials() {
    this.area = 'materials';
  }

  switchToSoftware() {
    this.area = 'software';
  }
}

export default new MaterialStore();
