import { tableSchema } from '@nozbe/watermelondb';
import { field, date, json } from '@nozbe/watermelondb/decorators';
import Model from './simapp-model';

export const userGroupSchema = tableSchema({
  name: 'userGroup',
  columns: [
    { name: 'b_id', type: 'number', isIndexed: true },
    { name: 'uid', type: 'string', isIndexed: true },
    { name: 'parent_id', type: 'number' },
    { name: 'name', type: 'string' },
    { name: 'module', type: 'string' },
    { name: 'address', type: 'string', isOptional: true },
    { name: 'config', type: 'string', isOptional: true },
    { name: 'permission_list', type: 'string', isOptional: true },
    { name: 'contact_name', type: 'string', isOptional: true },
    { name: 'contact_email', type: 'string', isOptional: true },
    { name: 'maingroup_name', type: 'string', isOptional: true },
    { name: 'is_maingroup', type: 'boolean' },
    { name: 'is_deactivated', type: 'boolean' },
    { name: 'b_created_at', type: 'number' },
    { name: 'b_updated_at', type: 'number' },
    { name: 'created_at', type: 'number' },
    { name: 'updated_at', type: 'number' }
  ]
});

export default class UserGroup extends Model {
  static table = 'userGroup'

  @field('b_id') bId
  @field('uid') uid
  @field('parent_id') parentId
  @field('name') name
  @field('module') module
  @field('adress') address
  @json('config', jsonData => jsonData) config
  @json('permission_list', jsonData => jsonData) permissionList
  @field('contact_name') contactName
  @field('contact_email') contactEmail
  @field('maingroup_name') maingroupName
  @field('is_maingroup') isMaingroup
  @field('is_deactivated') isDeactivated
  @date('b_created_at') bCreatedAt
  @date('b_updated_at') bUpdatedAt
  @date('created_at') createdAt
  @date('updated_at') updatedAt
}
