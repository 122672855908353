import { createMuiTheme } from '@material-ui/core/styles';
import spacing from '@material-ui/core/styles/spacing';
import { light as palette } from '@material-ui/core/styles/createPalette';
import simappColors from 'shared/lib/theme/simapp-colors';

const simappTheme = createMuiTheme({
  palette: {
    primary: {
      main: simappColors.azul
    },
    background: {
      default: simappColors.palerGrey
    },
    text: {
      primary: simappColors.dark,
      secondary: simappColors.dark,
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
    divider: simappColors.dark,
  },
  shadows: [
    'none',
    `2px 2px 3px ${simappColors.darkTransparent}`,
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
  transitions: {
    duration: {
      enteringScreen: 300
    }
  },
  typography: {
    fontFamily: 'helvetica-neue-lt, sans-serif',
    useNextVariants: true,
  },
  overrides: {
    MuiTypography: {
      h1: {
        fontWeight: 200,
      },
      h2: {
        fontWeight: 200,
      },
      h3: {
        fontWeight: 200,
      },
      h4: {
        fontWeight: 200,
      },
      h5: {
        fontWeight: 300
      }
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        fontWeight: 400,
        fontSize: '1rem',
      },
      sizeLarge: {
        padding: `${spacing.unit * 1.5}px 6em`,
        fontSize: '1.2rem',
        mindWidth: 0,
      },
    },
    MuiFormLabel: {
      root: {
        fontWeight: 500,
        '&$focused': {
          color: palette.text.primary
        }
      },
      asterisk: {
        color: simappColors.pumpkin
      }
    },
    MuiSelect: {
      root: {
        color: simappColors.azul,
        '& > div': {
          paddingLeft: 7
        }
      },
    },
    MuiInput: {
      root: {
        '& ::placeholder': {
          color: simappColors.placeholderGrey,
          opacity: 1
        }
      },
      input: {
        padding: '6px 6px 7px'
      },
      inputMultiline: {
        padding: '0 6px 0'
      },
      underline: {
        '&:before': {
          transition: 'none'
        },
        '&:after': {
          borderBottom: 'none'
        },
      },

    },
    MuiExpansionPanel: {
      root: {
        '&:before': {
          backgroundColor: simappColors.paleGrey,

        },
        '&:first-child': {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          '&:before': {
            display: 'block',
            top: 0
          }
        },
        '&:last-child': {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        },
        '&$expanded + &': {
          '&:before': {
            display: 'block'
          }
        }
      },
      expanded: {
        margin: 0,
        '&:before': {
          opacity: 1
        }
      }
    },
    MuiExpansionPanelDetails: {
      root: {
        flexDirection: 'column',
        paddingLeft: '5em',
        paddingRight: '3em',
      }
    },
    MuiDialogTitle: {
      root: {
        textAlign: 'center'
      }
    },
    MuiDialogActions: {
      root: {
        padding: '1rem',
        margin: 0,
        borderTop: `1px solid ${simappColors.lightGrey}`
      }
    },
    MuiDivider: {
      light: {
        backgroundColor: simappColors.paleGrey
      }
    },
    MuiCardContent: {
      root: {
        '&:last-child': {
          paddingBottom: 16
        }
      }
    },
    MuiTableCell: {
      root: {
        padding: '4px 24px 4px 24px'
      },
      body: {
        fontSize: '0.875rem'
      }
    }
  },
});

export default simappTheme;
