import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import { materialStore } from 'shared/stores';
import MaterialTile from 'web/components/materials/material-tile';
import { headlineRow, section } from '../headline';

const styles = {
  headlineRow,
  section,
  recentEmpty: {
    width: '100%',
    textAlign: 'center',
    lineHeight: '276px',
  },
  recentWrapper: {
    overflowX: 'auto',
    overflowY: 'hidden',
    whiteSpace: 'nowrap',
    marginLeft: 6,
    height: 265,
    '& > *': {
      display: 'inline-block',
      whiteSpace: 'normal',
    }
  },
  recentWrapperFull: {
    whiteSpace: 'normal',
    overflowX: 'hidden',
    overflowY: 'auto',
    height: 'calc(100vH - 90px)',
  }
};

@observer
class ActivitiesSection extends Component {
  render() {
    const { classes } = this.props;
    // eslint-disable-next-line no-unused-expressions
    materialStore.materialsUpdated;
    return (
      <Collapse in={materialStore.onlyMaterials} collapsedHeight="322px">
        <div className={classes.section}>
          <div className={classes.headlineRow}>
            <Typography variant="h5">Recent Activity</Typography>
            <Button
              color="primary"
              onClick={materialStore.onlyMaterials ? materialStore.backToDashboard : materialStore.openOnlyMaterials}
            >
              {materialStore.onlyMaterials ? 'Back to Dashboard' : 'View all'}
            </Button>
          </div>
          <div className={`${classes.recentWrapper} ${materialStore.onlyMaterials ? classes.recentWrapperFull : ''}`}>
            {materialStore.getRecent().length > 0 && materialStore.materials.length > 0 ? (
              materialStore.getRecent().map((materialUid) => {
                const material = materialStore.getMaterialByUid(materialUid);
                return typeof material === 'undefined' ? null
                  : (
                    <MaterialTile
                      key={materialUid}
                      material={material}
                      disableSharing
                    />
                  );
              })
            ) : (
              <Typography
                variant="h5"
                className={classes.recentEmpty}
              >
                You have no recent activity yet.
              </Typography>
            )}
          </div>
        </div>
      </Collapse>
    );
  }
}

ActivitiesSection.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ActivitiesSection);
