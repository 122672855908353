import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { simappConstants } from 'shared/lib/theme';
import ServiceTile from './service-tile';

const styles = {
  root: {
    width: '100%',
    height: '100vH',
    display: 'flex',
    flexDirection: 'column',
    padding: simappConstants.contentMarginLarge,
    overflowX: 'hidden',
    fontFamily: 'helvetica-neue-lt, sans-serif',
    fontWeight: 'normal'
  },
  headRow: {
    display: 'flex',
    flexShrink: 0,
    height: 46,
    marginBottom: '24px',
    alignItems: 'left'
  },
  title: {
    margin: '0',
    display: 'flex',
    flexDirection: 'row',
    fontSize: '28px',
    lineHeight: '40px'
  },
  textContainer: {
    marginTop: '20px',
    marginBottom: '20px',
    paddingRight: '10px',
  },
  textBlock: {
    marginBottom: '20px',
    lineHeight: '20px',
    '&.marginBottom': {
      marginBottom: '35px'
    },
    '&.marginTop': {
      marginTop: '35px'
    },
    color: 'black'
  },
  grid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around'
  }
};

const ServiceScreen = (props) => {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <div className={classes.headRow}>
        <Typography
          variant="h1"
          className={classes.title}
        >
          {/* {calculatorStore.getText('title')} */}
        </Typography>
      </div>
      <div className={classes.textContainer}>
        {/* <Typography variant="body1" className={classes.textBlock}>{calculatorStore.getText('introParagraphOne')}</Typography>
          <Typography variant="body1" className={classes.textBlock}>{calculatorStore.getText('introParagraphTwo')}</Typography> */}
      </div>
      <div className={classes.grid}>
        {/* <ServiceTile store={pctStore} routing={routing} path="/he-models/pct-calculator" /> */}
        <ServiceTile title="Software" path="/services/software" />
      </div>
    </div>
  );
};

ServiceScreen.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ServiceScreen);
