import React from 'react';
import PropTypes from 'prop-types';
import { useObserver } from 'mobx-react-lite';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.5em',
  },
  reversedOrder: {
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
    '& label': {
      fontWeight: 400
    }
  },
  labelWrapper: {
    width: '25%',
    display: 'flex',
    position: 'relative',
    height: 32
  },
  label: {
    position: 'static',
    transform: 'none',
    alignSelf: 'center',
    whiteSpace: 'nowrap',
    width: '100%'
  },
  errorMessage: {
    whiteSpace: 'nowrap',
    position: 'absolute',
    bottom: -6
  },
  inputField: {
    flex: 1,
  },
  rootVertical: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'flex-start',
    '& textarea': {
      padding: 0,
      overflowY: 'auto'
    },
    MuiInput: {
      root: { width: '100%' }
    }
  },
  inputVertical: {
    width: '100%',
    '& div': {
      overflowY: 'auto',
      height: '100%'
    },
    '& textarea': {
      minHeight: '100%'
    }
  },
  hide: {
    display: 'none',
    '& + hr': {
      display: 'none',
    }
  },
};

const SwitchField = (props) => {
  const { fields, name, onChange } = props;
  const rootClassVertical = fields[name].vertical ? props.classes.rootVertical : null;
  const inputClassVertical = fields[name].vertical ? props.classes.inputVertical : null;
  const hideClass = (fields[name].hideWhenDisabled && (fields[name].disabled || props.disabled)) ? props.classes.hide : null;

  return useObserver(() => (
    <FormControl
      error={!!fields[name].error}
      disabled={fields[name].disabled || props.disabled}
      required={fields[name].required}
      className={`${props.classes.root} ${rootClassVertical} ${props.reversed ? props.classes.reversedOrder : null} ${hideClass}`}
    >
      { !!fields[name].label
      && (
        <div className={props.classes.labelWrapper}>
          <InputLabel htmlFor={name} disableAnimation shrink={false} className={props.classes.label}>{fields[name].label}</InputLabel>
          <FormHelperText id={`${name}-error`} className={props.classes.errorMessage}>{fields[name].error}</FormHelperText>
        </div>
      )
      }
      <div
        className={`${props.reversed ? null : props.classes.inputField} ${inputClassVertical}`}
      >
        <Checkbox
          id={name}
          checked={fields[name].value}
          onChange={(e) => { onChange({ target: e.target, is_checkbox: true }); }}
          color="primary"
        />
      </div>
    </FormControl>
  ));
};

SwitchField.propTypes = {
  classes: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  reversed: PropTypes.bool,
};

SwitchField.defaultProps = {
  disabled: false,
  reversed: false,
};

export default withStyles(styles)(SwitchField);
