import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { simappColors } from 'shared/lib/theme';
import { routing } from 'shared/stores';
import ThermoFooter from 'web/components/_misc/thermo-footer';
import InputField from 'web/components/_ui/form-elements/input-field';
import SwitchField from 'web/components/_ui/form-elements/switch-field';
import SaveButton from 'web/components/_ui/form-elements/save-button';
import loginForm from 'shared/stores/forms/login-form';
import forgotPasswordForm from 'shared/stores/forms/forgot-password-form';

const styles = {
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  thumbnail: {
    margin: 'auto auto 1em auto',
    width: 120,
    height: 120,
    'background-image': 'url(/icons/mask-placeholder@3x.png)',
    'background-size': 'contain',
    '& > img': {
      width: 120,
      height: 120,
      borderRadius: '50%',
    },
  },
  headline: {
    margin: '0 auto 1em'
  },
  loginButton: {
    marginTop: '0.5em',
    marginBottom: '0.5em',
    width: '100%'
  },
  footer: {
    margin: 'auto 0 1em 0'
  },
  formPaper: {
    padding: '1em',
    width: 600,
    margin: '0 auto'
  },
  form: {
    display: 'flex',
    flexDirection: 'column'
  },
  dividerFullWidth: {
    marginLeft: '-1em',
    marginRight: '-1em',
    backgroundColor: simappColors.steelGrey
  },
  error: {
    textAlign: 'center',
    color: simappColors.strawberry,
    marginTop: '1em'
  },
  forgotPassword: {
    alignSelf: 'flex-end'
  }
};

@observer
class Login extends Component {
  goToForgotPassword = () => {
    if (loginForm.fields.username.value.length > 0) {
      forgotPasswordForm.onFieldChange({ target: { id: 'username', value: loginForm.fields.username.value } });
    }
    routing.push('/forgot-password');
  }

  render() {
    const { fields, onFieldChange } = loginForm;
    return (
      <div className={this.props.classes.root}>
        <div className={this.props.classes.thumbnail} />
        <Typography variant="h4" className={this.props.classes.headline}>Login</Typography>
        <Paper className={this.props.classes.formPaper}>
          { !loginForm.show2FAField && (
            <form className={this.props.classes.form}>
              <InputField autoFocus fields={fields} name="username" onChange={onFieldChange} autoComplete={false} />
              <Divider light />
              <InputField fields={fields} name="password" onChange={onFieldChange} autoComplete={false} />
              <Button color="primary" onClick={this.goToForgotPassword} className={this.props.classes.forgotPassword}>Forgot your password?</Button>
              <Divider className={this.props.classes.dividerFullWidth} />
              <Typography className={this.props.classes.error}>{loginForm.error || '\u00A0'}</Typography>
              <SaveButton form={loginForm} onClick={loginForm.save} label="Login" className={this.props.classes.loginButton} />
            </form>
          )}
          { loginForm.show2FAField && (
            <div className={this.props.classes.form}>
              <Typography>For additional security, we sent a six digit code to your email address. Please check your mail and enter the code below.</Typography>
              <InputField autoFocus fields={fields} name="pin" onChange={onFieldChange} autoComplete={false} />
              <Divider className={this.props.classes.dividerFullWidth} />
              <SwitchField fields={fields} name="remember" onChange={onFieldChange} reversed />
              <Divider className={this.props.classes.dividerFullWidth} />
              <Typography className={this.props.classes.error}>{loginForm.error || '\u00A0'}</Typography>
              <SaveButton form={loginForm} onClick={loginForm.login2FA} label="Login" className={this.props.classes.loginButton} forceDisabled={!fields.pin.value.length} />
            </div>
          )}
        </Paper>
        <ThermoFooter className={this.props.classes.footer} />
      </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Login);
